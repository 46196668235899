import React, { useContext, useState } from 'react'
import '../Styles/home_page.css'

import Home_left_side from './Home_left_side'
import Home_white_side from './Home_white_side'
import Home_bulletin_sec from './Home_bulletin_sec'
import { AppContext } from '../Services/Context'
import Layout from '../../Layout'
import Loader from '../Register/Loader'


export default function Home_page() {
  const {logData, userData, loading} = useContext(AppContext)
  const [isComment, setIsComment] = useState(false)
  const [isShare, setIsShare] = useState(false)
  
  window.addEventListener('scroll', () => {
    document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
  });

  
  const url = window.location.pathname
  // console.log(url);


  if(loading===true){
    return(
        <Loader/>
    )
}else{

  return (
<>
{url != "/"  ? <Layout/>:<div className= "home_main">
    < Home_white_side userData={userData} logData={logData} isComment={isComment} setIsComment={setIsComment} isShare={isShare} setIsShare={setIsShare}/>    
    < Home_left_side />
    {/* <hr className="home-hr-2"/>
    < Home_bulletin_sec /> */}
</div>}


</>
  )
}
}
