import React, { useContext, useEffect, useState } from 'react'
import cover_img from '../Assets/Someone Acc assets/Eren.jpeg'
import profile_img from '../Assets/Someone Acc assets/profile pic.jpg'
import placeholder from '../Assets/Accouonts Page/Placeholder image.png'
import security_icon from '../Assets/Someone Acc assets/security icon.svg'
import { Mutual_view } from './Mutual_view'
import { AppContext } from '../Services/Context'


export default function Someone_Acc_profile({userData,userTwigs, checkLock, uidFromURL}) {
    const [mutual, setMutual] = useState()
    const [array, setArray] = useState()
    const [name, setName] = useState()
    const { allTakes } = useContext(AppContext);

    const takeList = allTakes.filter((item) => {
        // console.log(item.uid);
        if (item.uid === uidFromURL) {
          return item;
        }
        return false;
      });
  return (
<>


{mutual ? 
  <>
  <Mutual_view setMutual={setMutual} array={array} name={name}/>
  </>
  :
  <></>
}

<div className="user-profile-img">
<img className="account-cover-img" src={userData.cover_img ? userData.cover_img : placeholder}/>
    <div>
        <img src={userData.img}/>
        <p>{userData.name}</p>
        <p>@{userData.user_name}</p>

        <div className="followers-box">
            <div>
                <button>Posts</button>
                <p>{userTwigs?.length + takeList.length}</p>
            </div>
            <div>
                <button disabled={checkLock} onClick={() => {setMutual(true); setArray(userData.followers); setName("Followers")}}>Followers</button>
                <p disabled={checkLock} onClick={() => {setMutual(true); setArray(userData.followers); setName("Followers")}}>{userData.followers ? userData.followers.length : 0}</p>
            </div>
            <div>
                <button disabled={checkLock} onClick={() => {setMutual(true); setArray(userData.following); setName("Following")}}>Following</button>
                <p disabled={checkLock} onClick={() => {setMutual(true); setArray(userData.following); setName("Following")}}>{userData.following? userData.following.length:0}</p>
            </div>                  
        </div>
    </div>
    <button><img src={ userData.lock ? security_icon : null}/></button>
</div>

</>
  )
}
