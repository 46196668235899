export let buttons = [
    {
        "id":"1",
        "src":require('../Assets/Search assets/news img.png'),
        "name":"#red"
    },
    {
        "id":"2",
        "src":require('../Assets/Search assets/food img.png'),
        "name":"#black"
    },
    {
        "id":"3",
        "src":require('../Assets/Search assets/Make-up img.png'),
        "name":"#anime"
    },
    {
        "id":"4",
        "src":require('../Assets/Search assets/sports img.png'),
        "name":"#hot"
    }
]

export let feeds = [
    {
        "id":"1",
        "src":require('../Assets/Search assets/nature image.jpg')
    }, 
    {
        "id":"2",
        "src":require('../Assets/Search assets/nature image.jpg')
    }, 
    {
        "id":"3",
        "src":require('../Assets/Search assets/nature image.jpg')
    }, 
    {
        "id":"4",
        "src":require('../Assets/Search assets/nature image.jpg')
    },
    {
        "id":"4",
        "src":require('../Assets/Search assets/nature image.jpg')
    },
    {
        "id":"4",
        "src":require('../Assets/Search assets/nature image.jpg')
    },
    {
        "id":"4",
        "src":require('../Assets/Search assets/nature image.jpg')
    },
    {
        "id":"4",
        "src":require('../Assets/Search assets/nature image.jpg')
    },
]