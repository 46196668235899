// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useParams, useLocation } from 'react-router-dom';
import Story from './Story';
import './story.css';

const Story_main = () => {
  const { userId, storyId } = useLocation();
  const navigate = useNavigate();
  const [currentStoryIndex, setCurrentStoryIndex] = useState(Number(storyId.split('_')[2]) - 1);

  const storyData = [
    { id: 'story_id_1', content: 'Story 1 Content' },
    { id: 'story_id_2', content: 'Story 2 Content' },
    { id: 'story_id_3', content: 'Story 3 Content' },
  ];

  useEffect(() => {
    setCurrentStoryIndex(Number(storyId.split('_')[2]) - 1);
  }, [storyId]);

  const handleNext = () => {
    const nextIndex = currentStoryIndex + 1;
    if (nextIndex < storyData.length) {
      const nextStoryId = `story_id_${nextIndex + 1}`;
      navigate(`/story/${userId}/${nextStoryId}`);
    }
  };

  const handlePrev = () => {
    const prevIndex = currentStoryIndex - 1;
    if (prevIndex >= 0) {
      const prevStoryId = `story_id_${prevIndex + 1}`;
      navigate(`/story/${userId}/${prevStoryId}`);
    }
  };

  return (
    <div className="carousel">
      <button onClick={handlePrev} disabled={currentStoryIndex === 0}>
        Prev
      </button>
      <div className="carousel-content">
        {storyData.map((story, index) => (
          <div
            key={story.id}
            className={`carousel-item ${index === currentStoryIndex ? 'active' : ''}`}
          >
            <Story storyId={story.id} />
          </div>
        ))}
      </div>
      <button onClick={handleNext} disabled={currentStoryIndex === storyData.length - 1}>
        Next
      </button>
    </div>
  );
};


export default Story_main