import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import theme_icon from '../Assets/Settings Page/Theme icon.svg'
import accoount_icon from '../Assets/Settings Page/Account icon.svg'
import notification_icon from '../Assets/Settings Page/Notification icon.svg'
import privacy_icon from '../Assets/Settings Page/Privacy icon.svg'
import security_icon from '../Assets/Settings Page/Security icon.svg'
import more_icon from '../Assets/Settings Page/More icon.svg'
import about_icon from '../Assets/Settings Page/About icon.svg'
import help_icon from '../Assets/Settings Page/Help icon.svg'
import log_out_icon from '../Assets/Settings Page/Log out icon.svg'
import up_arrow from '../Assets/Home page 1/up arrow.svg'
import down_arrow from '../Assets/Home page 1/down arrow.svg'
import lang from '../Assets/Settings Page/language.svg'
import block from '../Assets/Settings Page/block.svg'
import sensitivity from '../Assets/Settings Page/sensitivity.svg'
import del from '../Assets/Settings Page/delet_account.svg'
import { signOut } from 'firebase/auth'
import { auth } from '../Services/firebaseconfig'
import { AppContext } from '../Services/Context'



export default function Settings_options() {
    const [accSelected, setAccSelected] = useState(true)
    const [notifiSelected, setNotifiSelected] = useState(true)
    const [privacySelected, setPrivacySelected] = useState(true)
    const [moreSelected, setMoreSelected] = useState(true)
    const {userData} = useContext(AppContext)

    
    const navigate = useNavigate()
    function logout() {
        signOut(auth).then(() => {
            userData = null
            // console.log("SIGN OUT DONE") 
            
    }).then(
        navigate('/login'), window.location.reload())
        .catch ((error) =>  {
            // console.log(error)
        })
    }
    const {logData, dark, setDark} = useContext(AppContext)
    // console.log(String(dark));

    const Dark_toggle = (e) =>  {
        document.body.classList.add("dark", "body-dark");
        window.localStorage.setItem('THEME', true)
    } 
    const Dark_toggle_off = (e) =>  {
        document.body.classList.remove("dark", "body-dark");
        window.localStorage.setItem('THEME', false)
    }
    function Dark_mode() {
        setDark(!dark)
        {dark ? Dark_toggle_off() : Dark_toggle()}
    }

  return (
<>

<div className="setting-sec">
    <p className='text-black dark:text-white'>Setting</p>
    <div className="setting-options">
        <section className='setting-heading'>
            <div>
                <img src={theme_icon} className='dark:invert'/>
                <button className='text-black dark:text-white'>Theme</button>
                <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" value={dark} checked={dark} className="sr-only peer" onChange={() => Dark_mode()}/>
                    <div className="mr-4 w-14 h-6 bg-gray-500 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-8 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BDFA3C] after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                </label>
            </div>
        </section>

        <hr className='border-black dark:border-[#E5E7EB]'/>
        
        <section className='setting-heading'>
            <div>
                <img src={accoount_icon} className='dark:invert'/>
                <button className='text-black dark:text-white'>Account</button>
                <button onClick={() => setAccSelected(!accSelected)}>{accSelected ? <><img src={up_arrow} className='dark:invert'/></> : <><img src={down_arrow} className='dark:invert'/></>}</button>
            </div>
        </section>
        {accSelected ? <>
            <section className='setting-sub-heading'>
                <Link to="/settings_page/change_pass">
                    <button className='text-black dark:text-white'>Change Password</button>
                </Link>
                <Link to="/settings_page">
                    <button className='text-black dark:text-white'>Edit Profile</button>
                </Link>
            </section>
        </>
         : <></>}
       

        <hr className='border-black dark:border-[#E5E7EB]'/>
        
        <section className='setting-heading'>
            <div>
                <img src={notification_icon}/>
                <button className='text-black dark:text-white'>Notification</button>
                <button onClick={() => setNotifiSelected(!notifiSelected)}>{notifiSelected ? <><img src={up_arrow} className='dark:invert'/></> : <><img src={down_arrow} className='dark:invert'/></>}</button>
            </div>
        </section>
        {notifiSelected ? <>
            <section className='setting-sub-heading'>
            <Link to="/settings_page/push_notifi">
                <button className='text-black dark:text-white'>Push notifications</button>
            </Link>
            <Link to="/settings_page/email_notifi">
                <button className='text-black dark:text-white'>Email notifications</button>

            </Link>
            </section>
        </> : <></> }
        

        <hr className='border-black dark:border-[#E5E7EB]'/>

        <section className='setting-heading'>
            <div>
                <img src={privacy_icon}/>
                <button className='text-black dark:text-white'>Privacy</button>
                <button onClick={() => setPrivacySelected(!privacySelected)}>{privacySelected ? <><img src={up_arrow} className='dark:invert'/></> : <><img src={down_arrow} className='dark:invert'/></>}</button>
            </div>
            {privacySelected ? <>
            
                <section className='setting-option-with-btns'>
                    <div>
                        <div className="Email-switch">
                            <p className='text-black dark:text-white'>Accounts Privacy</p>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" value="" className="sr-only peer"/>
                                <div className="mr-4 w-14 h-6 bg-gray-500 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-8 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BDFA3C] after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                            </label>
                        </div>
                    </div>
                    <p className='text-black dark:text-white'>When your account is public, your profile and posts can be seen by anyone, on or off Twigyy, even if they don’t have an Twigyy account.When your account is private, only the followers you approve can see what you share, including your photos or videos on hashtag and location pages, and your followers and following lists <a href=''>Learn more.</a></p>
                </section>

                <section className='setting-sub-heading'>
                <Link to="/settings_page/frnd_intact">
                    <button className='text-black dark:text-white'>Check who can interact</button>
                </Link>
                </section>

                <div>
                    <img src={security_icon}/>
                    <button className='text-black dark:text-white'>Security</button>
                </div>
                <section className='setting-sub-heading'>
                <Link to="/settings_page/">
                    <button className='text-black dark:text-white'>Two step verification</button>
                </Link>
                </section>
                <section className='setting-option-with-btns'>
                    <div>
                        <div className="Email-switch">
                            <p className='text-black dark:text-white'>Enable Two step Verification</p>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" value="" className="sr-only peer"/>
                                <div className="mr-4 w-14 h-6 bg-gray-500 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-8 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BDFA3C] after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                            </label>
                        </div>
                    </div>
                </section>
            </> : <></> }
        </section>

        <hr className='border-black dark:border-[#E5E7EB]'/>

        <section className='setting-heading'>
            <div>
                <img src={more_icon} className='dark:invert'/>
                <button className='text-black dark:text-white'>More</button>
                <button onClick={() => setMoreSelected(!moreSelected)}>{moreSelected ? <><img src={up_arrow} className='dark:invert'/></> : <><img src={down_arrow} className='dark:invert'/></>}</button>
            </div>
            {moreSelected ? <>
            
                <section className='setting-option-with-btns'>
                    <div>
                    {/* <div className="Email-switch">
                            <p className='text-black dark:text-white'>Switch to professional account</p>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" value="" className="sr-only peer"/>
                                <div className="mr-4 w-14 h-6 bg-gray-500 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-8 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BDFA3C] after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                            </label>
                        </div> */}
                    </div>
                </section>
                <section className='setting-sub-heading'>
                <Link to="/settings_page/lang">
                    <button className='text-black dark:text-white'><img src={lang} className='dark:invert'/>Language</button>
                </Link>
                <Link to="/settings_page/blocked">
                    <button className='text-black dark:text-white'><img src={block} className='dark:invert'/>Blocked</button>
                </Link>
                <Link to="/settings_page/sensitive_content">
                    <button className='text-black dark:text-white'><img src={sensitivity} className='dark:invert'/>Sensitive Content</button>
                </Link>
                <Link to="/settings_page/delete_start">
                    <button className='text-black dark:text-white'><img src={del} className='dark:invert'/>Delete Account</button>
                </Link>
                </section>
            </> : <></> }
        </section>

        <hr className='border-black dark:border-[#E5E7EB]'/>

        <section className='setting-heading'>
            <div>
                <img src={about_icon}/>
                <button className='text-black dark:text-white'>About</button>
            </div>            
        </section>

        <hr className='border-black dark:border-[#E5E7EB]'/>

        <section className='setting-heading'>
            <div>
                <img src={help_icon}/>
                <button className='text-black dark:text-white'>Help</button>
            </div>
        </section>
        
        <div className="log-out-btn">
            
            <button onClick={() => logout()} className='text-black dark:text-white'><img src={log_out_icon} className='dark:invert'/>Logout</button>
        </div>
    </div>
</div>

</>
  )
}
