import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { feeds } from './search_components'
import { AppContext } from '../Services/Context'
import cross_icon from '../Assets/Home page 1/cross icon.svg'
import {arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { database, auth } from "../Services/firebaseconfig";

import three_dot from "../Assets/Home page 1/three dot.svg"
import save_2 from '../Assets/Home page 1/save 2.svg'
import download from '../Assets/Home page 1/download.svg'
import share_icon from '../Assets/Home page 1/share 2.svg'

import report from '../Assets/Home page 1/report.svg'
import why_see from '../Assets/Home page 1/why see.svg'
import not_int from '../Assets/Home page 1/not interested.svg'

import delete_icon from '../Assets/Accouonts Page/Delete post.svg'

import love_like from '../Assets/Home page 1/love like.svg'
import empty_like from "../Assets/Home page 1/empty like.svg"
import comment_btn from "../Assets/Home page 1/comment.svg"
import scroll_left from "../Assets/Home page 1/scroll left.svg";
import scroll_right from "../Assets/Home page 1/scroll right.svg";

import reply_icon from '../Assets/Home page 1/reply icon.svg'
import mic_icon from '../Assets/Messages assets/mic icon.svg'
import emoji_icon from '../Assets/Messages assets/emoji .svg'
import send_icon from '../Assets/Messages assets/send icon.svg'
import comment_profile from '../Assets/Home page 1/story 1.png'
import { Link } from "react-router-dom";
import Search_bar_twigs from './Search_bar_twigs';
import EachComment from '../Comment/EachComment';
import SearchImageCard from './SearchImageCard';

export default function Search_Feeds() {

    const {publicTwigs, width} = useContext(AppContext)
    const [allfeeds, setAllFeeds] = useState(publicTwigs)
    const [searchText, setSearchText] = useState("")
    const [results, setResults] = useState([]);
    const [twig, setTwig] = useState();
    const [count, setCount] = useState(12); //Lazy Loader
    const [frndShow, setFrndShow] = useState(false)

    console.log(publicTwigs);

    useEffect(() => {
        const handleSearch = async () => {
          if (searchText !== "" && searchText.length > 2) {
            const querySnapshot = await getDocs(collection(database, "Twigs"));
            const results = [];
            const searchKeywords = searchText.split(" ");
    
            querySnapshot.docs
              .map((doc) => doc.data())
              .forEach((twig) => {
                // For each user, check if any search keyword is included in the user name
                if (searchKeywords.some(keyword => twig.caption.toLowerCase().includes(keyword.toLowerCase()))) {
                  results.push(twig);
                }
              });
            setResults(results)
            setFrndShow(true)
          }
          if (searchText === "") {
            setResults([])
            setFrndShow(false)
          }
        }
    
        handleSearch();
      }, [searchText]);

      // console.log(results);

      // LAZY LOADER
      useEffect(() => {
        const handleScroll = () => {
          const element = document.getElementById('feeds-box');
          if (element) {
            // console.log("found");
            const { scrollTop, scrollHeight, clientHeight } = element;
            // console.log('total Height :', scrollTop+clientHeight, 'scroll Height', scrollHeight);
            
            if (scrollTop + clientHeight+100 >= scrollHeight) {
              setCount((prevCount) => prevCount + 4);
            }
          }
        };
      
        const element = document.getElementById('feeds-box');
        if (element) {
          element.addEventListener('scroll', handleScroll);
          // console.log('Event listener added');
        }
      
        return () => {
          if (element) {
            element.removeEventListener('scroll', handleScroll);
            // console.log('Event listener removed');
          }
        };
      }, []);

      // console.log(count)
      // console.log(window.scrollY)
      // console.log(document.body.scrollHeight)
      
    
      const elements = [];
  for (let i = 0; i < count && i < publicTwigs.length; i++) {
    if (width <= 570) {
      elements.push(
        <Link to={`/acc_mobile_post/${publicTwigs[i].docId}`} state={{ feed: publicTwigs[i], twigId: publicTwigs[i].docId }} key={i}>
          <SearchImageCard imgUrl={publicTwigs[i].image} />
        </Link>
      );
    } 
    else {
    elements.push(
      <div onClick={() => setTwig(publicTwigs[i])} key={i}>
        <SearchImageCard imgUrl={publicTwigs[i].image} />
      </div>
    );
    }
  }

  return (
<>

<div className="feeds-box">
        {twig ?
            <div className="twig-view-main z-10">
                <PostExpand twig={twig} setTwig={setTwig}/> 
            </div>
        :<></> }
        < Search_bar_twigs setSearchText={setSearchText} frndShow={frndShow} setFrndShow={setFrndShow}/>
        <div className="feeds-header">
            <p className='text-black dark:text-white'>Suggestion</p>
            {/* <button className='text-black dark:text-white'>More...</button> */}
        </div>

        {/* <div  className="feeds-sec">
            <div className="col-1">
            {allfeeds.map((feed, key) => (
            <div>
                <img src={feed.src}/>
            </div>
            ))}
            </div>
            <div className="col-2">
            {allfeeds.map((feed, key) => (
            <div>
            <img src={feed.src}/>
            </div>
            ))}
            </div>            
            <div className="col-1">
            {allfeeds.map((feed, key) => (
                <div>
                <img src={feed.src}/>
                </div>
            ))}
            </div>
            <div className="col-2">
            {allfeeds.map((feed, key) => (
                <div>
                <img src={feed.src}/>
                </div>
            ))}
            </div>            
        </div> */}

            <div  className="grid-box">
            <div className="post-img-center">
              <div className="img-grid someone-acc-grid">

                {results.length !== 0 ? results.map((feed,key)=>(
                    <div onClick={()=>setTwig(feed)} key={key}>
                      <img loading='lazy' src={feed.image}/>
                    </div>
                ))
                :
                  <>
                    {/* {allTwigs.map((feed, key) => (
                    <div onClick={()=>setTwig(feed)} key={key}>
                        <img src={feed.image}/>
                    </div>
                    ))} */}
                    {elements}
                  </>
                }
              </div>
            </div>
          </div>
        
    </div>


</>
  )
}

const PostExpand = ({twig, setTwig}) => {
    const {userData} = useContext(AppContext) 
    // console.log(twig);
    const [threeOpen, setThreeOpen] = useState(false)
    const [imgSrc, setImgSrc] = useState(null)
    const [twigUser, setTwigUser] = useState()
    const [user, setUser] = useState()
    const [liked, setLiked] = useState(false)
    const [comment, setComment] = useState('')
    const [comments, setComments] =  useState([])
    const [index, setIndex] = useState(0);

    const {logData} = useContext(AppContext)
    const containerRef = useRef(null);
  
    const checkKeyPress = useCallback((e) => {
      const { key, keyCode } = e;
      if (keyCode === 27) {
        setTwig(!twig)
      }
    },[twig]);
  
    useEffect((e)=>{
      window.addEventListener("keydown", checkKeyPress);
      return () => {
        window.removeEventListener("keydown", checkKeyPress);
      };
  
    },[checkKeyPress])

    const [likeCount, setLikeCount] = useState(twig?.react?.like.length)



  useEffect(() => {
    if (twig && twig.react?.like) {

      if (twig.react?.like.includes(logData.uid)) {
        setLiked(true)
      }
      else {
        setLiked(false)
      }
    }
  }, [])

  useEffect(() => {
    if (twig) {
      const postRef = doc(database, "Twigs", twig.docId);

      const unsubscribe = onSnapshot(postRef, (snapshot) => {
        if (snapshot.exists) {
          const postData = snapshot.data();
          const newLikeCount = postData.react.like ? postData.react.like.length : 0;
          setLikeCount(newLikeCount);
        }
      });

      return () => unsubscribe(); // Cleanup the listener when the component unmounts
    }
  }, [twig]);

  useEffect(() => {
    if (twig) {
      const postRef = doc(database, "Twigs", twig.docId);

      const unsubscribe = onSnapshot(postRef, (snapshot) => {
        if (snapshot.exists) {
          const postData = snapshot.data();
          setComments(postData.comment)
        }
      });

      return () => unsubscribe(); // Cleanup the listener when the component unmounts
    }
  }, [twig]);

  // console.log(comments)
  
    async function setUserImage(uid) {
        const docRef = doc(database, "User Data", `${uid}`);
        const docSnap = await getDoc(docRef);
        const profile_img = docSnap.data().img;
        setImgSrc(profile_img);
        return profile_img;
    }

    async function setUserName(uid) {
        const docRef = doc(database, "User Data", `${uid}`);
        const docSnap = await getDoc(docRef);
        const profile_img = docSnap.data().name;
        setTwigUser(profile_img);
        return profile_img;
    }
    async function getUser(uid) {
        const docRef = doc(database, "User Data", `${uid}`);
        const docSnap = await getDoc(docRef);
        const profile_img = docSnap.data().user_name;
        setUser(profile_img);
        return profile_img;
    }
  
    setUserImage(twig.uid)
    setUserName(twig.uid)
    getUser(twig.uid)

    async function like_update(id) {
      await setDoc(doc(database, "Twigs", id), {
        react: { like: arrayUnion(logData.uid) }
      }, { merge: true })
      const followNotification = { "user": `${userData.name}`, "action": "has liked your post", "image": `${userData.img}`, "postId": `${id}`, "timestamp":serverTimestamp() }
      await setDoc(doc(database, "notifications", `${twig.uid}`), {
        notifications: arrayUnion(followNotification),
      }, { merge: true });
      setLiked(true)
    }
  
    async function unlike_update(id) {
      try {
        // Get the current document
        const docRef = doc(database, "Twigs", id);
        const docSnapshot = await getDoc(docRef);
  
        // Get the current array
        const currentLikes = docSnapshot.data().react.like;
  
        // Find the index of logData.uid in the array
        const indexToRemove = currentLikes.indexOf(logData.uid);
  
        if (indexToRemove !== -1) {
          // If logData.uid is found, remove it from the array
          currentLikes.splice(indexToRemove, 1);
  
          // Update the document with the modified array
          await updateDoc(docRef, {
            react: { like: currentLikes }
          });
  
          // Set the liked state to false
          setLiked(false);
        } else {
          // logData.uid not found in the array
          // console.log("logData.uid not found in the like array");
        }
      } catch (e) {
        // console.log(e);
      }
    }

    async function postComment() {
      if (comment !== '') {
        // console.log(comment);
        try {
          await updateDoc(doc(database, "Twigs", twig.docId), {
            comment: arrayUnion({
              text: comment,
              uid: logData.uid,
              timeStamp: new Date()
            })
          });
        } catch (error) {
          console.error("Error adding comment:", error);
        }
      } else {
        // console.log("write comment");
      }
    }

    const scrollRight = () => {
      if (containerRef.current) {
        containerRef.current.scrollBy({ left: 550, behavior: "smooth" }); // Adjust the scroll amount as needed
        setIndex(index + 1);
      }
    };
  
    const scrollLeft = () => {
      if (containerRef.current) {
        containerRef.current.scrollBy({ left: -550, behavior: "smooth" }); // Adjust the scroll amount as needed
        setIndex(index - 1);
      }
    };
    const handleScroll = () => {
      // Assuming each slide has the same width
      const slideWidth = containerRef.current.scrollWidth / twig.image.length;
      const newActiveIndex = Math.floor(
        (containerRef.current.scrollLeft + slideWidth / 2) / slideWidth
      );
      setIndex(newActiveIndex);
    };
  

    // console.log(twig)
  
    return (
      <div className="acc-bg-blur">
      {/* <button onClick={()=>setTwig(!twig)}><img src={cross_icon}/></button> */}
      <button className='action-cross-btn hover:bg-[#6c6c6ca5] p-2 rounded-full' onClick={() => setTwig(!twig) }><img src={cross_icon} /></button>
      <section className="twigs-view-section">
        <div className="twig-view">
          <section className="twig-image-size bg-white dark:bg-black">
          {twig.image.length > 1 ? (
                <>
                  {index === 0 ? (
                    <></>
                  ) : (
                    <button onClick={scrollLeft}>
                      <img className="dark:invert" src={scroll_left} />
                    </button>
                  )}
                </>
              ) : (
                <></>
              )}

              <div className="post_view_img_scroll" ref={containerRef} onScroll={handleScroll}>
                {twig.image.map((image, key) => (
                  <img className='h-full w-full' src={image} />
                ))}
              </div>

              {twig.image.length > 1 ? (
                <div className="flex w-[550px] items-center justify-center gap-2 absolute bottom-[80px]">
                  {twig.image.map((content, key) => (
                    <p
                      className={` ${
                        key === index
                          ? "transition carousel_active"
                          : "min-w-[6px] min-h-[6px] rounded-full bg-[#838383] dark:bg-[#838383]"
                      }`}
                      key={key}
                    ></p>
                  ))}
                </div>
              ) : (
                <></>
              )}

              {twig.image.length > 1 ? (
                <>
                  {index === twig.image.length - 1 ? (
                    <></>
                  ) : (
                    <button onClick={scrollRight}>
                      <img className="dark:invert" src={scroll_right} />
                    </button>
                  )}
                </>
              ) : (
                <></>
              )}
          </section>

          <section className="twigs-header p-4 w-full">
            <div className="twig-details">
            <Link to={`/someones_page/${user}`} state={{ uid: twig.uid, user: user }}>
              <img src={imgSrc}/>
            </Link>
            
              <div>
              <Link to={`/someones_page/${user}`} state={{ uid: twig.uid, user: user }}>
                <h2>{twigUser}</h2>
              </Link>
                <p>{twig.time}</p>
              </div>
              <section className="twigs-three-dot ml-auto">
                <button onClick={() => setThreeOpen(!threeOpen)}> <img src={three_dot}/> </button>
                { threeOpen ? <>
                          <div className="settings-three-dot twigs-setting-open">
                          <div>
                                <section>
                                    <div>
                                        <img src={ save_2 } />
                                    </div>
                                    <p>Save</p>
                                </section>
                                <section>
                                    <div>
                                        <img src={share_icon} />
                                    </div>
                                    <p>Share</p>
                                </section>
                                <section>
                                    <div>
                                        <img src={not_int} />
                                    </div>
                                    <p>Not interest</p>
                                </section>
                            </div>
                            <div>
                                <section>
                                    <div>
                                        <img src={download} />
                                    </div>
                                    <p>Download</p>
                                </section>
                                <section>
                                    <div>
                                        <img src={report} />
                                    </div>
                                    <p>Report</p>
                                </section>
                                <section>
                                    <div>
                                        <img src={why_see} />
                                    </div>
                                    <p>Why see this?</p>
                                </section>
                            </div>
                          </div>
                      </> :
                          <>
                          </>
                      }
              </section>
            </div>
            <div className="twigs-caption">
              <p>{twig.caption}</p>
              <section>
                <button onClick={() => liked ? unlike_update(twig.docId) : like_update(twig.docId)}>
                {liked ? <img className="w-[28px] h-[25px]" src={love_like} /> : <img className='dark:invert w-[28px] h-[25px]' src={empty_like} />}
                  {likeCount}
                </button>
                <button><img src={comment_btn}/>100</button>
              </section>
              <hr className="mt-2"/>
            </div>
  
            <section className="twigs-comments">
            <div className="twigs-comment_text">
                {comments.length !== 0? comments.map((thisComment, key) => (
                  <EachComment comment={thisComment} twigId={twig.docid} />
                )):<p className="text-center text-gray-400">Write something to comment</p>}

              </div>

                <div className="twigs-send-msg">
                  <div className='text-black'>
                    <img src={emoji_icon} />
                    <input placeholder="Type Comment" onChange={(e) => setComment(e.target.value)} />
                    <img src={mic_icon} />
                  </div>
                  <section>
                    <button onClick={() => postComment()}><img src={send_icon} width={20} /></button>
                  </section>
                </div>
              </section>
  
          </section>
  
          
        </div>
      </section>
  
      </div>
    );
  };