import React, { useContext, useEffect, useState } from "react";
import "../Styles/message_page.css";
import MiddleSec from "./Msg_Middle_Sec.js";
import Last_sec from "./Msg_Last_sec.js";
import Chat from "./Msg_chat_Sec.js";
import { Outlet } from "react-router-dom";
import { AppContext } from "../Services/Context";
import { doc, onSnapshot } from "firebase/firestore";
import { database } from "../Services/firebaseconfig.js";

export default function Message_page() {
  const { userData, logData } = useContext(AppContext);
  const [allmessages, setAllMessages] = useState([]);


  useEffect(() => {
    const getChats = () => {
      const unsub = onSnapshot(
        doc(database, "userChats", logData.uid),
        (doc) => {
          let messages = doc.data();
  
          if (messages) {
            const sortedMessages = Object.entries(messages).sort(
              ([, a], [, b]) => {
                // Check if both a.date and b.date exist and have the seconds property
                const dateA = a?.date?.seconds ? a.date.seconds : 0;
                const dateB = b?.date?.seconds ? b.date.seconds : 0;
  
                return dateB - dateA;
              }
            );
  
            const sortedMessagesObj = Object.fromEntries(sortedMessages);
            setAllMessages(sortedMessagesObj);
          }
        }
      );
      return () => {
        unsub();
      };
    };
  
    getChats();
  }, []);


  return (
    <>
      {/* <div class="message-main">   */}
      {/* earlier dark bg-[#38424B] */}
      <div className="home-right-side bg-[#8A8C8E] dark:bg-[#14283a]">
        {userData ? <Last_sec userData={userData} logData={logData} allmessages={allmessages} /> : <></>}

        <hr className="msg-display" />
        <div className="msg-display w-[100%]">
          <Outlet userData={userData}/>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
