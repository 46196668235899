import React, { useCallback, useContext, useEffect, useState } from "react";
import "./Styles/Style_1.css";
import twigyy from "./Assets/Images/Login Page/twigyy-logo.png";
import google from "./Assets/Images/Login Page/google-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./Services/firebaseconfig";
import { AppContext } from "./Services/Context";
import Loader from "./Register/Loader";
// import { AppContext } from './Services/Context'

export default function Login_page_1() {
  const { userData, loading } = useContext(AppContext);
  const [seePass, setSeePass] = useState(false);
  const [err, setErr] = useState();
  const navigate = useNavigate();
  // console.log(userData.length);

  // useEffect(() => {
  //   if (userData) {
  //     // navigate("/")
  //   }
  // }, []);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  // console.log(user.email);

  function login() {
    const { email, password } = user;
    signInWithEmailAndPassword(auth, email, password)
      //Exception Handeling (An event after fetching the USER data from Firebase..)
      .then((person) => {
        const res = person.user;
        window.location.reload();
        navigate("/");
      })
      .catch((err) => {
        // console.log(err.message);
        setErr(err.code);
      });
  }

  // const enterToLogin = useCallback(
  //   (e) => {
  //     const { key, keyCode } = e;
  //     if (keyCode === 13) {
  //       login()
  //     }
  //   },
  //   [user]
  // );

  // useEffect(
  //   (e) => {
  //     window.addEventListener("keydown", enterToLogin);
  //     return () => {
  //       window.removeEventListener("keydown", enterToLogin);
  //     };
  //   },
  //   [enterToLogin]
  // );

  // console.log(loading);

  if (loading === true) {
    return <Loader />;
  } else {
    return (
      <>
        <div className="login-header">
          <img className="logo-twigyy" src={twigyy} alt="Twigyy-logo" />

          <h2 className="login dark:text-white">LogIn</h2>

          <div className="user-input">
            {/* <button onClick={() => setSeePass(!seePass)}>See Pass</button> */}
            <input
              onChange={(e) => {
                setUser({ ...user, email: e.target.value });
              }}
              className="email-input dark:text-white"
              placeholder="email/username"
            />

            <div className="new_pass_input">
              <input
                onChange={(e) => {
                  setUser({ ...user, password: e.target.value });
                }}
                type={`${seePass ? "text" : "password"}`}
                className="dark:text-white"
                placeholder="password"
              />
              <button className="dark:text-[#ffffff4f]" onClick={() => setSeePass(!seePass)}>See</button>
            </div>

            {err === "auth/invalid-login-credentials" ? (
              <p className="text-center text-white">
                Email/Password is incorrect
              </p>
            ) : null}
          </div>
          <div className="footer-sec">
            <Link to={"/accType"}>
              <p className="new-in-twigyy dark:text-white">New in TWIGYY?</p>
            </Link>
            <div className="down-login-with">
              <hr className="border-1 border-black dark:border-white" />
              <p className="login-with dark:text-white">or login with</p>
              <hr className="border-1 border-black dark:border-white" />
            </div>
            <div className="logo">
              <button>
                <img src={google} alt="Google-logo" />
              </button>
            </div>
            <div className="continue-button">
              <button onClick={() => login()} className="continue-btn">
                Continue
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
