import React, { useContext, useEffect, useState } from "react";

import twigyy from "../Assets/Images/Login Page/twigyy-logo.png";
import notification_icon from '../Assets/Home page 1/Notification.svg'
import cross_icon from '../Assets/Home page 1/cross icon.svg'
import search_icon from '../Assets/Home page 1/search icon.svg'
import {
  groups,
  onlines,
  suggestions,
  stories,
  contents,
  news,
} from "./Home_pg_Components";
// import { collection, getDocs, query, where } from "firebase/firestore";
import { Link } from "react-router-dom";
import { AppContext } from "../Services/Context";

import { auth, database } from "../Services/firebaseconfig";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { Side_notification } from "./Side_notification";

export default function Home_left_side() {
  const [allgroups, setAllGroups] = useState(groups);
  const [allonlines, setAllOnlines] = useState(onlines);
  const [allsuggestions, setAllSuggestions] = useState(suggestions);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [followChange, setFollowChange] = useState([]);
  const [notifiOn, setNotifiOn] = useState(false);
  const [visibleCount, setVisibleCount] = useState(4);

  const { logData, userData, followers, allUsers, notificationData } = useContext(AppContext)
  // console.log(notificationData);


  const handleLoadMore = () => {
    setVisibleCount(prevCount => prevCount + 4); // Adjust the number to load more as needed
  };

  useEffect(() => {
    const handleSearch = async () => {
      if (search !== "" && search.length > 2) {
        const querySnapshot = await getDocs(collection(database, "User Data"));
        const results = [];
        const searchKeywords = search.trim().split(" ");
        // console.log(searchKeywords)

        querySnapshot.docs
          .map((doc) => doc.data())
          .forEach((user) => {
            // For each user, check if any search keyword is included in the user name
            if (searchKeywords.some(keyword => keyword!== "" && user.name.toLowerCase().startsWith(keyword.toLowerCase()))) {
              results.push(user);
            }
          });
        setResults(results)
      }
      if (search === "") {
        setResults([])
      }
    }

    handleSearch();
  }, [search]);

  async function follow(userName) {
    await getDoc(doc(database, "Uid", userName))
      .then(async (document) => {
        await updateDoc(doc(database, "User Data", `${document.data().uid}`), {
          followers: arrayUnion(logData.uid),
        });
        await updateDoc(doc(database, "User Data", `${logData.uid}`), {
          following: arrayUnion(document.data().uid),
        });
      })
  }

  const uniqueMap = {};

  const filteredList = followers.filter(item => {
    if (!uniqueMap[item.uid] && item.isOnline === true) {
        uniqueMap[item.uid] = true;
        return true;
    }
    return false;
});

  const suggestionMap = {};


  const suggestionList = allUsers.filter(item => 
    // console.log(item);    
    !(userData.following.includes(item.uid)) &&
    !(userData.followers.includes(item.uid)) &&
    !(logData.uid === item.uid)
  );
  
  // console.log(suggestionList);

// console.log(filteredList);

  // useEffect(() => {
  //   if (results) {
  //     const changeFollow = []
  //     results.forEach((userData) => {
  //       if (userData.followers.includes(logData.uid)) {
  //         // userData.push(true);
  //         console.log(userData);
  //         changeFollow.push(userData.user_name)
  //       }
  //       //  else {
  //       //   userData.followChange = false;
  //       // }
  //     })
  //     setFollowChange(changeFollow)
  //   }
  // }, [logData]);

  // console.log(followChange);

  return (
    <>
      <div className="grey-side bg-[#14283a] dark:bg-[#0b1c1c]">
        <div className="h-14 w-14 flex items-center justify-center absolute left-[-56px] top-8 bg-[#14283a] dark:bg-[#0b1c1c] rounded-l-[50%]">
          <button onClick={() => setNotifiOn(!notifiOn)} className="relative">
            <img src={notifiOn ? cross_icon : notification_icon} className="h-6 w-6" />
            {notifiOn ? <></> : <p className="absolute top-0 right-0 h-3 w-3 bg-red-700 rounded-full"></p>}
            
          </button>
        </div>

        <div className="grey-side-scroll">
        {notifiOn ?
          <Side_notification notificationData={notificationData} userData={userData} logData={logData} />
          :
          <>
            <div className="home-head pt-[30px]">
              {/* <img src={twigyy} /> */}
              <img src={search_icon}/>
              <input
                placeholder="Search"
                type="text"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="mt-5">
              {results.length != 0 ? results.map((user) => (

                <Link to={`/someones_page/${user.user_name}`}>
                  <div key={user.uid} className="flex flex-row gap-4 items-center justify-between mb-2 text-white">
                    <div className="flex flex-row gap-4 items-center">
                      <img src={user.img} alt="" className="rounded-full w-[30px] h-[30px]" />
                      <p>{user.name}</p>
                    </div>
                    <button onClick={() => user.followers.includes(logData.uid) ? null : follow(user.user_name)}> {user.followers.includes(logData.uid) ? "Followed" : "Follow"}</button>
                  </div>
                </Link>
              )) : null}
            </div>
            {/* <p className="sub-heading">FOLLOWED GROUPS</p>
        <div className="grp-sec">
          {allgroups.map((community, key) => (
            <div key={key}>
              <img src={community.src} alt={community.id} />
              <p>{community.name}</p>
            </div>
          ))}
        </div> */}

            <p className="sub-heading">ONLINE</p>
            <div className="home-online-sec">
              
              {filteredList.length != 0 ? filteredList.map((online, key) => (

                <div key={key}>
                  {online.isOnline ?
                    <div className="mt-2">
                      <img src={online.img} alt={online.name} />
                      <Link to={`/someones_page/${online.user_name}`}>
                      <p className="dark:text-white">{online.name}</p>
                      </Link>
                      <p className="online-indicator">{ }</p>
                    </div> :
                    
                   null
                    }

                </div>
              ))
            :  <p className="dark:text-white">No one is online.</p>
            }
            </div>

            <p className="sub-heading">Suggestion For You</p>
      <div className="suggestion-sec">
        {suggestionList.slice(0, visibleCount).map((suggestion, key) => (
          <Link to={`/someones_page/${suggestion.user_name}`} key={key}>
            <div>
              <img src={suggestion.img} alt={suggestion.id} />
              <p>{suggestion.name}</p>
              {/* <button className="hover:bg-[#B9F53B]">{suggestion.btn}</button> */}
            </div>
          </Link>
        ))}
      </div>

      {visibleCount < suggestionList.length && (
        <div className="more" onClick={handleLoadMore}>
          <button>+more</button>
        </div>
      )}
          </>
        }
      </div>

      </div>
    </>
  );
}
