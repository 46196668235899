import React, { useRef, useEffect, useState } from 'react';
import { doc, setDoc, onSnapshot, updateDoc, collection } from 'firebase/firestore';
import { database, auth } from '../../Services/firebaseconfig';

function VideoCall({ callStatus, callId }) {
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const peerConnection = useRef();
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [isConnectionActive, setIsConnectionActive] = useState(false);

  useEffect(() => {
    const cleanup = setupWebRTC();
    return () => {
    setupWebRTC();
    if (peerConnection.current) {
    peerConnection.current.close();
    }
    setIsConnectionActive(false);
    };
    }, []);

    console.log(peerConnection.current)

  useEffect(() => {
    if (remoteVideoRef.current) {
      remoteVideoRef.current.onloadedmetadata = () => {
        console.log('Remote video metadata loaded');
      };
      remoteVideoRef.current.oncanplay = () => {
        console.log('Remote video can play');
        remoteVideoRef.current.play().catch(e => console.error('Error playing remote video:', e));
      };
      remoteVideoRef.current.onplay = () => {
        console.log('Remote video started playing');
      };
      remoteVideoRef.current.onerror = (e) => {
        console.error('Remote video error:', e);
      };
    }
  }, []);

  const setupWebRTC = async () => {
    try {
      console.log('Setting up WebRTC');
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      localVideoRef.current.srcObject = stream;
      console.log('Local stream obtained');
  
      const configuration = {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
        ]
      };
      peerConnection.current = new RTCPeerConnection(configuration);
      setIsConnectionActive(true);
      console.log('Peer connection created');
  
      stream.getTracks().forEach((track) => {
        peerConnection.current.addTrack(track, stream);
      });
      console.log('Local tracks added to peer connection');
  
      peerConnection.current.ontrack = (event) => {
        console.log('Received remote track', event.streams[0]);
        console.log('Remote video tracks:', event.streams[0].getVideoTracks()[0]);
        remoteVideoRef.current.srcObject = new MediaStream([event.streams[0].getVideoTracks()[0]]);
      };
  
      peerConnection.current.onicecandidate = (event) => {
        if (event.candidate) {
          // console.log('New ICE candidate', event.candidate);
          sendIceCandidate(event.candidate);
        }
      };
  
      peerConnection.current.oniceconnectionstatechange = () => {
        console.log('ICE connection state changed:', peerConnection.current.iceConnectionState);
      };
  
      peerConnection.current.onicegatheringstatechange = () => {
        console.log('ICE gathering state changed:', peerConnection.current.iceGatheringState);
      };
  
      peerConnection.current.onsignalingstatechange = () => {
        console.log('Signaling state changed:', peerConnection.current.signalingState);
      };
  
      peerConnection.current.onconnectionstatechange = () => {
        console.log('Connection state changed:', peerConnection.current.connectionState);
      };
  
      peerConnection.current.onerror = (error) => {
        console.error('PeerConnection error:', error);
      };
  
      peerConnection.current.onnegotiationneeded = async () => {
        console.log('Negotiation needed');
        try {
          if (callStatus.caller === auth.currentUser.uid) {
            const offer = await peerConnection.current.createOffer();
            await peerConnection.current.setLocalDescription(offer);
            await sendOffer(offer);
          }
        } catch (error) {
          console.error('Error during negotiation:', error);
        }
      };
  
      if (callStatus.caller === auth.currentUser.uid) {
        console.log('We are the caller, creating offer');
        const offer = await peerConnection.current.createOffer();
        console.log('Offer created');
        
        console.log('Setting local description');
        await peerConnection.current.setLocalDescription(offer);
        console.log('Local description set');
        
        await sendOffer(offer);
        console.log('Offer sent');
      } else {
        console.log('We are the callee, waiting for offer');
      }
  
      return listenForSignaling();
    } catch (error) {
      console.error('Error setting up WebRTC:', error);
    }
  };

  const sendOffer = async (offer) => {
    console.log('Sending offer');
    const callRef = doc(database, 'calls', callId);
    try {
      await updateDoc(callRef, { offer: JSON.stringify(offer) });
      console.log('Offer sent successfully');
    } catch (error) {
      console.error('Error sending offer:', error);
    }
  };
  
  const sendAnswer = async (answer) => {
    console.log('Sending answer');
    const callRef = doc(database, 'calls', callId);
    try {
      await updateDoc(callRef, { answer: JSON.stringify(answer) });
      console.log('Answer sent successfully');
    } catch (error) {
      console.error('Error sending answer:', error);
    }
  };
  
  const sendIceCandidate = async (candidate) => {
    console.log('Sending ICE candidate');
    const callRef = doc(database, 'calls', callId);
    const iceCandidateData = {
      candidate: candidate.candidate,
      sdpMid: candidate.sdpMid,
      sdpMLineIndex: candidate.sdpMLineIndex,
      usernameFragment: candidate.usernameFragment
    };
    try {
      await setDoc(doc(collection(callRef, 'ice-candidates'), candidate.sdpMid), iceCandidateData);
      console.log('ICE candidate sent successfully');
    } catch (error) {
      console.error('Error sending ICE candidate:', error);
    }
  };

  const listenForSignaling = () => {
    console.log('Setting up signaling listeners');
    const callRef = doc(database, 'calls', callId);
    const unsubscribeCall = onSnapshot(callRef, async (snapshot) => {
      if (!isConnectionActive) return;
      const data = snapshot.data();
      console.log(peerConnection.current.currentRemoteDescription);
      if (peerConnection.current.signalingState === 'stable') {
        if (data.offer && !peerConnection.current.currentRemoteDescription) {
          console.log('Processing offer');
          const offer = JSON.parse(data.offer);
          await peerConnection.current.setRemoteDescription(new RTCSessionDescription(offer));
          console.log('Remote description set');
          const answer = await peerConnection.current.createAnswer();
          await peerConnection.current.setLocalDescription(answer);
          console.log('Local description (answer) set');
          sendAnswer(answer);
        } else if (data.answer && !peerConnection.current.currentRemoteDescription) {
          console.log('Processing answer');
          const answer = JSON.parse(data.answer);
          await peerConnection.current.setRemoteDescription(new RTCSessionDescription(answer));
          console.log('Remote description set');
        }
      } else {
        console.warn('Cannot set remote description in the current signaling state: ', peerConnection.current.signalingState);
      }
    });

    const iceCandidatesRef = collection(doc(database, 'calls', callId), 'ice-candidates');
    const unsubscribeICE = onSnapshot(iceCandidatesRef, (snapshot) => {
      if (!isConnectionActive) return;
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === 'added') {
          const data = change.doc.data();
          console.log('Received ICE candidate', data);
          if (peerConnection.current.remoteDescription) {
            const candidate = new RTCIceCandidate(data);
            try {
              await peerConnection.current.addIceCandidate(candidate);
              console.log('ICE candidate added successfully');
            } catch (error) {
              console.error('Error adding ICE candidate:', error);
            }
          } else {
            console.log('Skipping ICE candidate, remote description not set');
          }
        }
      });
    });

    return () => {
      unsubscribeCall();
      unsubscribeICE();
    };
  };

  const toggleAudio = () => {
    const audioTrack = localVideoRef.current.srcObject.getAudioTracks()[0];
    audioTrack.enabled = !audioTrack.enabled;
    setIsAudioMuted(!audioTrack.enabled);
  };

  const toggleVideo = () => {
    const videoTrack = localVideoRef.current.srcObject.getVideoTracks()[0];
    videoTrack.enabled = !videoTrack.enabled;
    setIsVideoOff(!videoTrack.enabled);
  };

  const endCall = async () => {
    setIsConnectionActive(false);
    if (peerConnection.current) {
      peerConnection.current.close();
    }
    const callRef = doc(database, 'calls', callId);
    await updateDoc(callRef, { status: 'ended' });
  };

  useEffect(() => {
    console.log('Local video ref:', localVideoRef.current);
    console.log('Remote video ref:', remoteVideoRef.current);
  }, []);

  // remoteVideoRef.current.onerror = (e) => {
  //   console.log('Remote video error:', e);
  // };

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flex: 1 }}>
        <video 
          className='local' 
          ref={localVideoRef} 
          autoPlay 
          playsInline
          muted 
          style={{ width: '50%', height: '100%', minWidth: '320px', minHeight: '240px' }} 
        />
        <video 
          className='remote' 
          ref={remoteVideoRef} 
          autoPlay 
          playsInline
          style={{ width: '50%', height: '100%', minWidth: '320px', minHeight: '240px' }} 
        />
      </div>
      <div style={{ padding: '10px' }}>
        <button onClick={toggleAudio}>{isAudioMuted ? 'Unmute' : 'Mute'}</button>
        <button onClick={toggleVideo}>{isVideoOff ? 'Turn On Video' : 'Turn Off Video'}</button>
        <button onClick={endCall}>End Call</button>
      </div>
    </div>
  );
}

export default VideoCall;