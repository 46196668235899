import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from '../Services/Context';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { database } from '../Services/firebaseconfig';
import EachComment from './EachComment';

import cross_icon from '../Assets/Home page 1/cross icon.svg'
import mic_icon from '../Assets/Messages assets/mic icon.svg'
import emoji_icon from '../Assets/Messages assets/emoji .svg'
import send_icon from '../Assets/Messages assets/send icon.svg'

const Comment = (props) => {
    const ref = useRef(null);
    const { onClickOutside } = props;
    const [comment, setComment] = useState('')
    const { logData, width } = useContext(AppContext)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);


    async function postComment() {
        if (comment !== '') {
            // console.log(comment);
            try {
                await updateDoc(doc(database, "Twigs", props.twigId), {
                    comment: arrayUnion({
                        text: comment,
                        uid: logData.uid,
                        timeStamp: new Date()
                    })
                }).then(setComment(''))
            } catch (error) {
                console.error("Error adding comment:", error);
            }
        } else {
            // console.log("write comment");
        }
    }

    // console.log(props.twigComment);

    return (
        <div className="home_blur">
            <button className='action-cross-btn hover:bg-[#6c6c6ca5] p-2 rounded-full' onClick={() => props.setIsComment(false)}><img src={cross_icon} /></button>
            <div className="comment_box bg-[] dark:bg-[#0b1c1c]">
                <div className="comment_top">
                    <hr />
                    <p>Comments</p>
                    <hr />
                </div>
                <section className='comment-scroll'>
                    {props.twigComment?.map((comment, key) => (
                        <EachComment comment={comment} twigId={props.twigId} />
                    ))}
                </section>

                <div className="msg-text chat_send">
                    <div>
                        <img src={emoji_icon} />
                        <input placeholder="Type Comment" onChange={(e) => setComment(e.target.value)} value={comment}/>
                        <img src={mic_icon} />
                    </div>
                    <section>
                        <button onClick={() => postComment()}><img src={send_icon} width={20} /></button>

                    </section>
                </div>
            </div>
        </div>
    )
}

export default Comment
