import React, { useState } from 'react'
import { posts } from './owner_brand_components'

export default function Owner_brand_posts() {

    const [allposts, setAllPosts] = useState(posts)

  return (
<>

<div className="twigs-posts-btn">
    <div>
        <button className='dark:text-white'>Twigs</button>
        <button className='dark:text-white'>Tags</button>
        <button className='dark:text-white'>Takes</button>
    </div>
</div>


<div  class="feeds-sec">
    <div class="col-1">
    {allposts.map((feed, key) => (
        <img src={feed.src}/>
    ))}
    </div>
    <div class="col-2">
    {allposts.map((feed, key) => (
        <>
        <img src={feed.src}/>
        <img src={feed.src}/>
        </>
    ))}
    </div>            
    <div class="col-1">
    {allposts.map((feed, key) => (
        <img src={feed.src}/>
    ))}
    </div>
    <div class="col-2">
    {allposts.map((feed, key) => (
        <>
        <img src={feed.src}/>
        <img src={feed.src}/>
        </>
    ))}
    </div>            
</div>

</>
  )
}
