import React from 'react'

export default function Settings_Push_Notifi() {
  return (
<>

<div className='settings-edit-profile settings-push-notif'>
    <p className='dark:text-white'>Push Notification</p>
    <div className='settings-push-notif-scroll'>
        <section>
            <p className='dark:text-white'>Like</p>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Anyone</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
            </div>
        </section>

        <section>
            <p className='dark:text-white'>Likes & Comments on my Twigs</p>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Anyone</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
            </div>
        </section>

        <section>
            <p className='dark:text-white'>Comment likes</p>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Anyone</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
            </div>
        </section>

        <section>
            <p className='dark:text-white'>Followers Request</p>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Anyone</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
            </div>
        </section>

        <section>
            <p className='dark:text-white'>Mentions in bio</p>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Anyone</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
            </div>
        </section>

        <section>
            <p className='dark:text-white'>Account suggestions</p>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Anyone</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
            </div>
        </section>

        <section>
            <p className='dark:text-white'>Message requests</p>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Anyone</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
            </div>
        </section>

        <section>
            <p className='dark:text-white'>Message reminders</p>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Anyone</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
            </div>
        </section>
    </div>
</div>

</>
   )
}