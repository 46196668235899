import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../Styles/login_5_build_profile.css'
import profile from '../Assets/Images/Login Page/profile_setup.png'
import { AppContext } from '../Services/Context';
import { database, storage } from '../Services/firebaseconfig';
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

export default function Login_5_build_profile() {

  const {userInfo} = useContext(AppContext)
  const navigate = useNavigate()
  // console.log(userInfo);

  const [selectedFile, setSelectedFile] = useState(null);

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  //   console.log(selectedFile);
  //   // Additional validation logic
  // };

  // const collectionRef = doc(database, "User Data", "doc1");

  function Continue() {
    const file=selectedFile
    if (!file) 
    return; 
    const storageRef = ref(storage, `${userInfo.email}/images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // console.log(snapshot);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          // await setDoc(collectionRef, {
          //   imageurl: downloadURL,
          // });
          userInfo.img = downloadURL
        });
      }
    );
}

const enterToNextPg = useCallback(
  (e) => {
    const { key, keyCode } = e;
    if (keyCode === 13) {
      navigate("/login6");
      Continue()
    }
  },
  []
);

useEffect(
  (e) => {
    window.addEventListener("keydown", enterToNextPg);
    return () => {
      window.removeEventListener("keydown", enterToNextPg);
    };
  },
  [enterToNextPg]
);

  return (
<>

<div className="main-div">
    <div className="three-dots">
    <p className=" circle circle-color"></p>
    <p className="circle"></p>
    <p className="circle"></p>
    </div>

    <img className="profile-setup" src={profile}/>

    <h1 className="dark:text-white">Build Your Profile</h1>
    
    {/* <label className="photo-btn" for="photo-input">Add Your Photo<input className="photo-input" type="file" onChange={handleFileChange}/></label> */}
    <div className="input-dp">
      <button className='relative-edit-btn'>
        Add Profile Image
        <input type="file" className='edit-profil-img-input' onChange={e => {setSelectedFile(e.target.files[0])}}/>
      </button>
      {/* <input type="file" className="custom-file-input" onChange={e => {setSelectedFile(e.target.files[0])}}/> */}
      <img src={selectedFile}/>
    </div>
    <div className='continue-button'>
      <Link to='/login6'>
      <button onClick={Continue()} >Continue</button> 
      </Link>
    </div>
    {/* <button className="skip-btn">Skip</button> */}
    

    
</div>


</>
  )
}
