import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { posts } from "./acc_components";
import cross_icon from "../Assets/Home page 1/cross icon.svg";
import { AppContext } from "../Services/Context";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  arrayUnion,
  onSnapshot,
  getDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { database, auth } from "../Services/firebaseconfig";
import { onAuthStateChanged } from "firebase/auth";
import { Link } from "react-router-dom";

import three_dot from "../Assets/Home page 1/three dot.svg";
import save_2 from "../Assets/Home page 1/save 2.svg";
import download from "../Assets/Home page 1/download.svg";
import report from "../Assets/Home page 1/report.svg";
import share_icon from "../Assets/Home page 1/share 2.svg";
import not_int from "../Assets/Home page 1/not interested.svg";
import why_see from "../Assets/Home page 1/why see.svg";
import delete_icon from "../Assets/Accouonts Page/Delete post.svg";

import mute from "../Assets/Reels assets/mute.svg";
import unmute from "../Assets/Reels assets/unmute.svg";

import empty_like from "../Assets/Home page 1/empty like.svg";
import love_like from "../Assets/Home page 1/love like.svg";
import comment_btn from "../Assets/Home page 1/comment.svg";

import reply_icon from "../Assets/Home page 1/reply icon.svg";
import mic_icon from "../Assets/Messages assets/mic icon.svg";
import emoji_icon from "../Assets/Messages assets/emoji .svg";
import send_icon from "../Assets/Messages assets/send icon.svg";
import comment_profile from "../Assets/Home page 1/story 1.png";
import scroll_left from "../Assets/Home page 1/scroll left.svg";
import scroll_right from "../Assets/Home page 1/scroll right.svg";
import galary_icon from "../Assets/Home page 1/galary icon.svg";
import EachComment from "../Comment/EachComment";

export default function Acc_posts({ setUserTwigs, userTwigs }) {
  // const [userTwigs, setUserTwigs] = useState([]);
  const [allposts, setAllPosts] = useState(posts);
  const [postTwigs, setPostTwigs] = useState(true);
  const [postTags, setPostTags] = useState(false);
  const [postTakes, setPostTakes] = useState(false);
  const { logData, width, allTakes } = useContext(AppContext);


  useEffect(() => {
    const fetchPosts = async () => {
      if (window.performance) {
      }
      if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
        onAuthStateChanged(auth, async (currUser) => {
          try {

            const q = query(
              collection(database, "Twigs"),{orderBy: ["timeStamp", "desc"]},
              where("uid", "==", `${currUser.uid}`)
            );

            const querySnapshot = await getDocs(q);
            const updatedTwigs = [];
            querySnapshot.forEach((doc) => {
              updatedTwigs.push({ ...doc.data(), docid: doc.id });
            });
            updatedTwigs.sort((a, b) => b.timeStamp - a.timeStamp);
            setUserTwigs(updatedTwigs)
          } catch (e) {
          }
        });
      } else {
        try {
          const q = query(
            collection(database, "Twigs"),{orderBy: ["timeStamp", "desc"]},
            where("uid", "==", `${logData.uid}`)
          );
          const querySnapshot = await getDocs(q);
          const updatedTwigs = [];
          querySnapshot.forEach((doc) => {
            updatedTwigs.push({ ...doc.data(), docid: doc.id });
            // setUserTwigs((userTwigs) => [
            //   ...userTwigs,
            //   { ...doc.data(), docid: doc.id },
            // ]);
          });
          updatedTwigs.sort((a, b) => b.timeStamp - a.timeStamp);
            setUserTwigs(updatedTwigs)
        } catch (e) {
        }
      }
    };

    fetchPosts();
    // console.log(userTwigs);
  }, []);

  // Initialize four subarrays
  // let postArrays = Array.from({ length: 4 }, () => []);

  // Iterate through the original array and distribute elements evenly
  // userTwigs.forEach((element, index) => {
  //   let postArraysIndex = index % 4;
  //   postArrays[postArraysIndex].push(element);
  // });

  const [twig, setTwig] = useState();
  // Display the subarrays
  // subArrays.forEach((subArray, index) => {
  // console.log(`Subarray ${index + 1}:`, subArray);
  // });

  // let postArrays= [];
  // let postNumber = userTwigs.length/4
  // while (userTwigs.length > 0) {
  // postArrays.push(userTwigs.splice(0, postNumber));
  // }
  // console.log(postArrays)
  // const testvar = userTwigs.splice(0,1)
  // const testvar2 = userTwigs.splice(0,1)
  // const testvar3 = userTwigs.splice(0,1)
  // const testvar4 = userTwigs.splice(0,1)

  const uniqueMap = {};

  const takeList = allTakes.filter((item) => {
    // console.log(uniqueMap);
    if (item.uid === logData.uid) {
      // uniqueMap[item.uid] = true;
      return item;
    }
    return false;
  });
  // console.log(takeList);

  return (
    <>
      {twig ? (
        <div className="twig-view-main">
          <PostExpand twig={twig} setTwig={setTwig} />
        </div>
      ) : (
        <></>
      )}

      <div className="twigs-posts-btn">
        <div>
          <button
            onClick={() => {
              setPostTwigs(true);
              setPostTags(false);
              setPostTakes(false);
            }}
            className={
              postTwigs ? `option_active dark:text-white` : `dark:text-white`
            }
          >
            Twigs
          </button>
          <button
            onClick={() => {
              setPostTwigs(false);
              setPostTags(false);
              setPostTakes(true);
            }}
            className={
              postTakes ? `option_active dark:text-white` : `dark:text-white`
            }
          >
            Takes
          </button>
          <button
            onClick={() => {
              setPostTwigs(false);
              setPostTags(true);
              setPostTakes(false);
            }}
            className={
              postTags ? `option_active dark:text-white` : `dark:text-white`
            }
          >
            Tags
          </button>
        </div>
      </div>

      <div className="grid-box">
        {/* Twigs */}
        {postTwigs ? (
          <>
            {width <= 500 ? (
              <div className="img-grid someone-acc-grid">
                {userTwigs.map((feed, key) => (
                  <Link
                    to={`/acc_mobile_post/${feed.docid}`}
                    state={{ feed: feed, twigId: feed.docid }}
                    key={key}
                  >
                    <div>
                      <img src={feed.image} key={key} />
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <div className="post-img-center">
                <div className="img-grid someone-acc-grid">
                  {userTwigs.map((feed, key) => (
                    <div onClick={() => setTwig(feed)} key={key}>
                      <img src={feed.image} key={key} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <></>
        )}

        {/* Takes */}
        {postTakes ? (
          <>
            <div className="img-grid takes_acc_view">
              {takeList?.map((feed, key) => (
                <div onClick={() => setTwig(feed)} key={key}>
                  <video
                    onClick={(e) => e.preventDefault()}
                    src={feed.video}
                    key={key}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <></>
        )}

        {/* Tags */}
        {postTags ? (
          <>
            <div className="img-grid takes_acc_view">
              <p className="dark:text-white">No Tages Found</p>
            </div>
          </>
        ) : (
          <></>
        )}
        {/* <div className="col-1">
              {postArrays[1].map((feed, key) => (
                <div  onClick={()=>setTwig(feed)} key={key}>
                  <img src={feed.image} key={key} />
                </div>
              ))}
            </div>
            <div className="col-1">
              {postArrays[2].map((feed, key) => (
                <div  onClick={()=>setTwig(feed)} key={key}>
                  <img src={feed.image} key={key} />
                </div>
              ))}
            </div>
            <div className="col-1">
              {postArrays[3].map((feed, key) => (
                <div  onClick={()=>setTwig(feed)} key={key}>
                  <img src={feed.image} key={key} />
                </div>
              ))}
            </div> */}
      </div>
    </>
  );
}

const PostExpand = ({twig, setTwig}) => {
  const {userData} = useContext(AppContext) 
  // console.log(twig);
  const [threeOpen, setThreeOpen] = useState(false)
  const [imgSrc, setImgSrc] = useState(null)
  const [twigUser, setTwigUser] = useState()
  const [user, setUser] = useState()
  const [liked, setLiked] = useState(false)
  const [comment, setComment] = useState('')
  const [comments, setComments] =  useState([])
  const [index, setIndex] = useState(0);

  const containerRef = useRef(null);

  const {logData} = useContext(AppContext)

  const checkKeyPress = useCallback((e) => {
    const { key, keyCode } = e;
    if (keyCode === 27) {
      setTwig(!twig)
    }
  },[twig]);

  useEffect((e)=>{
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };

  },[checkKeyPress])

  const [likeCount, setLikeCount] = useState(twig?.react?.like.length)



useEffect(() => {
  if (twig && twig.react?.like) {

    if (twig.react?.like.includes(logData.uid)) {
      setLiked(true)
    }
    else {
      setLiked(false)
    }
  }
}, [])

useEffect(() => {
  console.log(twig)
  if (twig && twig.react?.like) {
    const postRef = doc(database, "Twigs", twig.docid);

    const unsubscribe = onSnapshot(postRef, (snapshot) => {
      if (snapshot.exists) {
        const postData = snapshot.data();
        const newLikeCount = postData.react.like ? postData.react.like.length : 0;
        setLikeCount(newLikeCount);
      }
    });

    return () => unsubscribe(); // Cleanup the listener when the component unmounts
  }
}, [twig]);

useEffect(() => {
  if (twig && twig.comment) {
    const postRef = doc(database, "Twigs", twig.docid);

    const unsubscribe = onSnapshot(postRef, (snapshot) => {
      if (snapshot.exists) {
        const postData = snapshot.data();
        setComments(postData.comment)
      }
    });

    return () => unsubscribe(); // Cleanup the listener when the component unmounts
  }
}, [twig]);

// console.log(comments)

  async function setUserImage(uid) {
      const docRef = doc(database, "User Data", `${uid}`);
      const docSnap = await getDoc(docRef);
      const profile_img = docSnap.data().img;
      setImgSrc(profile_img);
      return profile_img;
  }

  async function setUserName(uid) {
      const docRef = doc(database, "User Data", `${uid}`);
      const docSnap = await getDoc(docRef);
      const profile_img = docSnap.data().name;
      setTwigUser(profile_img);
      return profile_img;
  }
  async function getUser(uid) {
      const docRef = doc(database, "User Data", `${uid}`);
      const docSnap = await getDoc(docRef);
      const profile_img = docSnap.data().user_name;
      setUser(profile_img);
      return profile_img;
  }

  setUserImage(twig.uid)
  setUserName(twig.uid)
  getUser(twig.uid)

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 550, behavior: "smooth" }); // Adjust the scroll amount as needed
      setIndex(index + 1);
    }
  };

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -550, behavior: "smooth" }); // Adjust the scroll amount as needed
      setIndex(index - 1);
    }
  };
  const handleScroll = () => {
    // Assuming each slide has the same width
    const slideWidth = containerRef.current.scrollWidth / twig.image.length;
    const newActiveIndex = Math.floor(
      (containerRef.current.scrollLeft + slideWidth / 2) / slideWidth
    );
    setIndex(newActiveIndex);
  };

  async function like_update(id) {
    await setDoc(doc(database, "Twigs", id), {
      react: { like: arrayUnion(logData.uid) }
    }, { merge: true })
    const followNotification = { "user": `${userData.name}`, "action": "has liked your post", "image": `${userData.img}`, "postId": `${id}`, "timestamp":serverTimestamp() }
    await setDoc(doc(database, "notifications", `${twig.uid}`), {
      notifications: arrayUnion(followNotification),
    }, { merge: true });
    setLiked(true)
  }

  async function unlike_update(id) {
    try {
      // Get the current document
      const docRef = doc(database, "Twigs", id);
      const docSnapshot = await getDoc(docRef);

      // Get the current array
      const currentLikes = docSnapshot.data().react.like;

      // Find the index of logData.uid in the array
      const indexToRemove = currentLikes.indexOf(logData.uid);

      if (indexToRemove !== -1) {
        // If logData.uid is found, remove it from the array
        currentLikes.splice(indexToRemove, 1);

        // Update the document with the modified array
        await updateDoc(docRef, {
          react: { like: currentLikes }
        });

        // Set the liked state to false
        setLiked(false);
      } else {
        // logData.uid not found in the array
        // console.log("logData.uid not found in the like array");
      }
    } catch (e) {
      // console.log(e);
    }
  }

  async function Delete_post(id) {
    await deleteDoc(doc(database, "Twigs", id)).then(
      setTimeout(() => {
        window.location.reload();
      }, 500)
    );
  }


  async function postComment() {
    if (comment !== '') {
      // console.log(comment);
      try {
        await updateDoc(doc(database, "Twigs", twig.docid), {
          comment: arrayUnion({
            text: comment,
            uid: logData.uid,
            timeStamp: new Date()
          })
        }).then(setComment(''))
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    } else {
      // console.log("write comment");
    }
  }


  // console.log(twig)

  return (
    <div className="acc-bg-blur">
    {/* <button onClick={()=>setTwig(!twig)}><img src={cross_icon}/></button> */}
    <button className='action-cross-btn hover:bg-[#6c6c6ca5] p-2 rounded-full' onClick={() => setTwig(!twig) }><img src={cross_icon} /></button>
    <section className="twigs-view-section">
      <div className="twig-view">
        <section className="twig-image-size bg-white dark:bg-black">
        {twig.image.length > 1 ? (
                <>
                  {index === 0 ? (
                    <></>
                  ) : (
                    <button onClick={scrollLeft}>
                      <img className="dark:invert" src={scroll_left} />
                    </button>
                  )}
                </>
              ) : (
                <></>
              )}
              <div className="post_view_img_scroll" ref={containerRef} onScroll={handleScroll}>
                {twig.image.map((image, key) => (
                  <img src={image} />
                ))}
              </div>

              {twig.image.length > 1 ? (
                <div className="flex w-[550px] items-center justify-center gap-2 absolute bottom-[80px]">
                  {twig.image.map((content, key) => (
                    <p
                      className={` ${
                        key === index
                          ? "transition carousel_active"
                          : "min-w-[6px] min-h-[6px] rounded-full bg-[#838383] dark:bg-[#838383]"
                      }`}
                      key={key}
                    ></p>
                  ))}
                </div>
              ) : (
                <></>
              )}

              {twig.image.length > 1 ? (
                <>
                  {index === twig.image.length - 1 ? (
                    <></>
                  ) : (
                    <button onClick={scrollRight}>
                      <img className="dark:invert" src={scroll_right} />
                    </button>
                  )}
                </>
              ) : (
                <></>
              )}
        </section>

        <section className="twigs-header p-4 w-full">
          <div className="twig-details">
          <Link to={`/someones_page/${user}`} state={{ uid: twig.uid, user: user }}>
            <img src={imgSrc}/>
          </Link>
          
            <div>
            <Link to={`/someones_page/${user}`} state={{ uid: twig.uid, user: user }}>
              <h2>{twigUser}</h2>
            </Link>
              <p>{twig.time}</p>
            </div>
            <section className="twigs-three-dot ml-auto">
              <button onClick={() => setThreeOpen(!threeOpen)}> <img src={three_dot}/> </button>
              {threeOpen ? (
                  <>
                    <div className="settings-three-dot twigs-setting-open">
                      <div>
                        <section>
                          <div>
                            <img src={save_2} />
                          </div>
                          <p>Save</p>
                        </section>
                        <section>
                          <div>
                            <img src={share_icon} />
                          </div>
                          <p>Share</p>
                        </section>
                        {/* <section>
                                    <div>
                                        <img src={not_int} />
                                    </div>
                                    <p>Not interest</p>
                                </section> */}
                      </div>
                      <div>
                        <section>
                          <div>
                            <img src={download} />
                          </div>
                          <p>Save</p>
                        </section>
                        <section>
                          <div onClick={() => Delete_post(twig.docid)}>
                            <img src={delete_icon} />
                          </div>
                          <p>Delete Post</p>
                        </section>
                        {/* <section>
                                    <div>
                                        <img src={report} />
                                    </div>
                                    <p>Download</p>
                                </section>
                                <section>
                                    <div>
                                        <img src={why_see} />
                                    </div>
                                    <p>Report</p>
                                </section> */}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

            </section>
          </div>
          <div className="twigs-caption">
            <p>{twig.caption}</p>
            <section>
              <button onClick={() => liked ? unlike_update(twig.docid) : like_update(twig.docid)}>
              {liked ? <img className="w-[28px] h-[25px]" src={love_like} /> : <img className='dark:invert w-[28px] h-[25px]' src={empty_like} />}
                {likeCount}
              </button>
              <button><img src={comment_btn}/>{comments? comments.length : 0}</button>
            </section>
            <hr className="mt-2"/>
          </div>

          <section className="twigs-comments">
              <div className="twigs-comment_text">
                {comments.length !== 0? comments.map((thisComment, key) => (
                  <EachComment comment={thisComment} twigId={twig.docid} />
                )):<p className="text-center text-gray-400">Write something to comment</p>}

              </div>

              <div className="twigs-send-msg">
                <div>
                  <img src={emoji_icon} />
                  <input placeholder="Type Comment" onChange={(e) => setComment(e.target.value)} value={comment} />
                  <img src={mic_icon} />
                </div>
                <section>
                  <button onClick={() => postComment()}><img src={send_icon} width={20} /></button>
                </section>
              </div>
            </section>

        </section>

        
      </div>
    </section>

    </div>
  );
};
