import React, { useState, useContext, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Styles/login_4_email_verify.css";
import { AppContext } from "../Services/Context";
import { auth } from "../Services/firebaseconfig";
import { onAuthStateChanged } from "firebase/auth";

export default function Login_4_email_verify() {
  const [err, setErr] = useState("");
  const { userInfo, logData } = useContext(AppContext);
  // console.log(logData);
  const [verified, setVerified] = useState()
  const navigate = useNavigate()


  async function Continue() {
    const currUser = auth.currentUser
    // console.log(currUser);
    currUser.reload().then(()=>{      
      if(auth.currentUser.emailVerified !== true){
        setErr("Email not verified. Check your inbox!");
      }else{
        setVerified(auth.currentUser.emailVerified)
      }
    }
    )
    // console.log( currUser);

      // if (user && user.emailVerified !== true) {
      //   setErr("Email not verified. Check your inbox!");
      // }
    }

    const enterToNextPg = useCallback(
      (e) => {
        const { key, keyCode } = e;
        if (keyCode === 13) {
          if(verified) {
            navigate("/login5");
          }
          else {
            navigate("#");
          }
          Continue()
        }
      },
      [verified]
    );
  
    useEffect(
      (e) => {
        window.addEventListener("keydown", enterToNextPg);
        return () => {
          window.removeEventListener("keydown", enterToNextPg);
        };
      },
      [enterToNextPg]
    );
    
    // onAuthStateChanged(auth, currUser=>{
    //   console.log(currUser);
    //   if (currUser && currUser.emailVerified !== true) {
    //     setErr("Email not verified. Check your inbox!");
    //   }
    // })

  

  return (
    <>
      <div className="login-header">
        <div className="check-msg">
          <p className="p1 dark:text-white">Verify Your Email</p>
          <p className="p2 dark:text-white">
            Check your email for verification
          </p>
        </div>

        <div>
          <p className="dark:text-white">{err}</p>
        </div>

        <div className="continue-button verify-btn continue-button-position">
          <Link to={verified ? "/login5" : "#"}>
            <button
              onClick={() => {
                Continue();
              }}
              className="continue-btn"
            >
              Continue
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
