import React, { useState } from 'react'

import deal_btn from '../Assets/Brand assets/deals_btn.jpg'
import shopping_btn from '../Assets/Brand assets/start_shopping.svg'
import star from '../Assets/Brand assets/Star.svg'
import { highlights } from './brand_components'
import { Link } from 'react-router-dom'

export default function Brand_highlight() {
    const [allhighlights, setAllHighlights] = useState(highlights)

  return (
<>

<div className='brand-test-scroll'>
        <div className="brand-advartisement">
            {allhighlights.map((highlight, key) => (
                <div>
                    <img src={highlight.src}/>
                    <p className='dark:text-white'>{highlight.name}</p>
                </div>
            ))}
        </div>

    <div className="s-brand-shopping">
        <Link to="/brand_page/start_shopping">
            <button><img src={shopping_btn} />Start Shopping</button>
        </Link>
        <Link to="/brand_page/todays_deals">
            <button><img src={deal_btn} />Check Today's Deal</button>
        </Link>
        <Link to="/brand_page/todays_deals">
            <button><img src={star} />Rate Us</button>
        </Link>
    </div>
</div>

</>
    )
}
