import React, { useContext, useEffect, useRef, useState } from 'react'
import { highlights, mutuals, suggestions } from './someone_acc_components'
import plus_btn_icon from '../Assets/Someone Acc assets/plus_icon.svg'
import plus_btn from '../Assets/Someone Acc assets/like_btn.svg'
import { Mutual_view } from './Mutual_view'
import { AppContext } from '../Services/Context'
import { Link } from 'react-router-dom'
import { doc, onSnapshot } from 'firebase/firestore'
import { database } from '../Services/firebaseconfig'
import scroll_left from "../Assets/Home page 1/scroll left.svg";
import scroll_right from "../Assets/Home page 1/scroll right.svg";


export default function Someone_Acc_highlight({accSugest, setAccSugest, someoneData, checkLock}) {

  const [allmutuals, setAllMutuals] = useState(mutuals)
  const [allsuggestions, setAllSuggestions] = useState(suggestions)
  const [allhighlights, setAllHighlights] = useState(highlights)
  const [mutual, setMutual] = useState()
  const [friendsData, setFriendsData] = useState([])
  const [index, setIndex] = useState(0)
  const { logData, userData, allUsers } = useContext(AppContext)
  const containerRef = useRef(null);

  const uniqueMap = {};
  const mutualList = userData.following.filter(item => 
    someoneData.following.includes(item)
        
);

  const suggestionList = allUsers.filter(item => 
    // console.log(item);    
    !(userData.following.includes(item.uid)) &&
    !(userData.followers.includes(item.uid)) &&
    !(logData.uid === item.uid)
  );

  useEffect(() => {
    mutualList.forEach(async (id) => {
      // console.log(follower);
      const q = doc(database, "User Data", id);
      onSnapshot(q, (data) => {
        //   console.log(data.data());
        setFriendsData((friendsData) => [
          ...friendsData,
          { ...data.data(), uid: data.id },
        ]);
      });
    })
  },[])

  const filteredList = friendsData.filter(item => {
    if (!uniqueMap[item.uid]) {
        uniqueMap[item.uid] = true;
        return true;
    }
    return false;
});

const scrollByIndices = (numIndices) => {
  if (containerRef.current) {
    const childWidth = containerRef.current.firstChild.clientWidth;
    containerRef.current.scrollBy({ left: childWidth * numIndices, behavior: 'smooth' });
  }
};

const scrollLeft = () => {
  scrollByIndices(-4); // Scroll left by 4 indices
};

const scrollRight = () => {
  scrollByIndices(4); // Scroll right by 4 indices
};

  // console.log(mutualList);
  // console.log(userData.followers);
  return (
<>
{mutual ? 
  <>
  <Mutual_view setMutual={setMutual} array={mutualList} name="Mutual Friends"/>
  </>
  :
  <></>
}

<div className="someone-middle-sec">
  <div className="someone-acc-friends">
    {filteredList.length != 0 ?
    <>
      <div>
          <p className='dark:text-white'>Mutuals</p>
          <button className='dark:text-white' disabled={checkLock} onClick={() => setMutual(true)}>see all</button>
      </div>
      <div disabled={checkLock} onClick={() => setMutual(true)}>
          {filteredList.slice(index, index+5).map((mutual, key) => (
              <img className={`mutual-img-${key}`} src={mutual.img} key={key} alt={mutual.id}/>
          ))}
            {filteredList.length-5 === 0 || filteredList.length-5 <= 0 ?
            <></> :  
            <>                         
            <button className='dark:text-white'>+{filteredList.length-5}</button>   
            </>
            } 
      </div>
      </>
      :
      <></>
          }
  </div>
{accSugest ? 
  <div className="someone-suggestion w-[65%]">
    <button className='w-8 dark:invert' onClick={scrollLeft}>
        <img src={scroll_left} />
    </button>

    <section ref={containerRef}>
      {suggestionList.map((suggestion, key) => (
        <Link to={`/someones_page/${suggestion.user_name}`}>
        <div key={key}>
          <img src={suggestion.img} alt={suggestion.id}/>
          <button><img src={plus_btn_icon} /></button>
          <div>
            <p className='dark:text-white'>{suggestion.name}</p>
          </div>
      </div>
      </Link>
      ))}
      </section>
    <button className='w-8 dark:invert' onClick={scrollRight}>
        <img src={scroll_right} />
    </button>
  </div>
:
<></>
}
</div>
{/* <div className="acc-size account-highlight">
    {allhighlights.map((highlight, key) => (
        <div key={key}>
            <img src={highlight.src} alt={highlight.id}/>
            <p className='dark:text-white'>{highlight.name}</p>
        </div>
    ))}
</div> */}

</>
  )
}
