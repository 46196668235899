import React from 'react'
import cover_img from '../Assets/Owner brand assets/Eren.jpeg'
import profile_img from '../Assets/Owner brand assets/profile_pic.png'
import smile_icon from '../Assets/Owner brand assets/smile_icon.svg'

export default function Owner_brand_profile() {
  return (
<>

<div className="user-profile-img">
    <img className="account-cover-img" src={cover_img}/>
    <div>
        <img src={profile_img}/>
        <p>Brand Ercel</p>
        <p>@brand123</p>

        <div className="followers-box">
            <div>
                <button>Twigs</button>
                <p>20</p>
            </div>
            <div>
                <button>Followers</button>
                <p>25 K</p>
            </div>
            <div>
                <button>Following</button>
                <p>320</p>
            </div>                  
        </div>
    </div>
    <button><img src={smile_icon}/></button>
</div>


</>
  )
}
