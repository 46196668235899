import React, { useState } from 'react'
import '../Styles/search_page.css'
import Search_bar_twigs from './Search_bar_twigs'
import Search_Feeds from './Search_Feeds'


export default function search_page() {
  return (
<>

{/* <div class="search-main"> */}

<div className="search-right-sec" id='feeds-box'>

    {/* < Search_bar_twigs /> */}
    < Search_Feeds />

</div>

    
{/* </div>  */}

</>
  )
}
