import React, { useContext, useRef, useState } from 'react'
import profile_pic from "../Assets/Accouonts Page/profile pic.png"
import link_icon from "../Assets/Home page 1/link.svg"
import g_mail from "../Assets/Home page 1/Social media/Mail.svg"
import whatsapp from "../Assets/Home page 1/Social media/whatsapp.svg"
import linkdin from "../Assets/Home page 1/Social media/linkdin.svg"
import pinterest from "../Assets/Home page 1/Social media/pinterest.svg"
import facebook from "../Assets/Home page 1/Social media/facebook.svg"
import twiter from "../Assets/Home page 1/Social media/twiter.svg"
import tumblr from "../Assets/Home page 1/Social media/tumblr.svg"
import sms from "../Assets/Home page 1/Social media/sms.svg"
import cross_icon from '../Assets/Home page 1/cross icon.svg'
import {story_profiles} from "./Home_pg_Components"
import { AppContext } from '../Services/Context'
import { doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore'
import { database } from '../Services/firebaseconfig'
import { useNavigate } from 'react-router-dom'
import { ChatContext } from '../Services/ChatContext'

export default function Home_share(props) {
    const [storyProfile, setStoryProfile] = useState(story_profiles)
    const [copied, setCopied] = useState(false)
    const {followings, followers, logData, userData} = useContext(AppContext)
    const {dispatch} = useContext(ChatContext)

    const textRef = useRef(null);

    const navigate = useNavigate()

    const combinedList = followers.concat(followings)
    const uniqueMap = {};
    
    const filteredList = combinedList.filter(item => {
        if (!uniqueMap[item.uid]) {
            uniqueMap[item.uid] = true;
            return true;
        }
        return false;
    });
    
    console.log(filteredList);

    const twigURL='https://'+window.location.host+`/twig/post/${props.twigId}`

  const handleCopyClick = () => {
    if (textRef.current) {
      const textToCopy = textRef.current.innerText;
      navigator.clipboard.writeText(textToCopy)
      setCopied(true)
    }
  };


  const handleSelect = async (someone) => {
    const combinedId = logData.uid > someone.uid ? logData.uid + someone.uid : someone.uid + logData.uid;

    try {
      const res = await getDoc(doc(database, "chats", combinedId));
      if (!res.exists()) {
        await setDoc(doc(database, "chats", combinedId), { messages: [] });

        await updateDoc(doc(database, "userChats", logData.uid), {
          [`${combinedId}.userInfo`]: {
            uid: someone.uid,
            displayName: someone.name,
            photoURL: someone.img,
          },
          [`${combinedId}.date`]: serverTimestamp(),
        });

        await updateDoc(doc(database, "userChats", someone.uid), {
          [`${combinedId}.userInfo`]: {
            uid: logData.uid,
            displayName: userData.name,
            photoURL: userData.img,
          },
          [`${combinedId}.date`]: serverTimestamp(),
        });
      }

      dispatch({ type: "CHANGE_USER", payload: someone });
      // console.log(combinedId)
      navigate(`/message_page/chat/${combinedId}`,{state:{text:twigURL}});
    } catch (err) {
      console.error("Error selecting chat:", err);
    }
  };


  return (

<div className="home_blur">
    <button className='action-cross-btn hover:bg-[#6c6c6ca5] p-2 rounded-full' onClick={() => props.setIsShare(false)}><img src={cross_icon} /></button>
    <div className="share-box bg-[] dark:bg-[#0b1c1c]">
        <section>
            <hr/>
            <div>
                <p>Share To</p>
                <hr/>
                <input placeholder='Search By Name'/>
            </div>
        </section>
        <section>
            <div>
                {filteredList.map((friend, key) => (
                    <div key={key} onClick={()=> handleSelect(friend)} className='cursor-pointer'>
                        <img src={friend.img} alt={friend.id}/>
                        <p>{friend.name}</p>
                    </div>
                ))}
            </div>
            <button>Send</button>
            <div>
                <p ref={textRef}>{twigURL}</p>
                <button onClick={handleCopyClick}><img src={link_icon}/>{copied ? 'Copied' : 'Copy'}</button>
            </div>
            <hr/>
        </section>
        <section>
            <button><img src={g_mail}/></button>
            <button onClick={()=>window.open(`whatsapp://send?text=${twigURL}`)}><img src={whatsapp}/></button>
            <button><img src={linkdin}/></button>
            <button><img src={pinterest}/></button>
            <button><img src={facebook}/></button>
            <button><img src={twiter}/></button>
            <button><img src={tumblr}/></button>
            <button><img src={sms}/></button>
        </section>
    </div>
</div>
  )
}
