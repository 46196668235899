import React, { useState } from 'react'
import '../Styles/brand_page.css'
import Brand_profile from './Brand_profile'
import Brand_abouts from './Brand_abouts'
import Brand_actions from './Brand_actions'
import Brand_posts from './Brand_posts'
import Brand_highlight from './Brand_highlight'
import Brand_broadcast from './Brand_broadcast'

export default function Brand_page() {
  const [accSugest, setAccSugest] = useState(false)
  return (
<>
<div className="brand-sec-all">

    < Brand_profile />
    <div style={{display:'flex', position:"relative", justifyContent:"space-between", alignItems:"center", width:"95%"}}>
    < Brand_abouts />
    < Brand_actions setAccSugest={setAccSugest} accSugest={accSugest} />
    </div>
    
    <div className='brand-hlight-action'>
    < Brand_broadcast setAccSugest={setAccSugest} accSugest={accSugest} />
    < Brand_highlight />
    </div>

    < Brand_posts />
</div>
</>
  )
}
