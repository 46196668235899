import React, { useContext, useState } from 'react'
import { notifications } from './Home_pg_Components'
import up_arrow from '../Assets/Home page 1/up arrow.svg'
import down_arrow from '../Assets/Home page 1/down arrow.svg'
import notification_icon from '../Assets/Home page 1/Notification.svg'
import cross_icon from '../Assets/Home page 1/cross icon.svg'
import { AppContext } from '../Services/Context'
import { Side_notification } from './Side_notification'

export default function Home_notification({setMobileNotifi, mobileNotifi, notificationData}) {
    const [allNotification, setAllNotification] = useState(notifications)
    const [notification, setNotification] = useState(false)
    const [open, setOpen] = useState(false)

    // console.log(notificationData);
    const {logData, userData, width} = useContext(AppContext)
    

    function Notification_on() {
        setOpen(!open)
        setNotification(!notification)
    }
    // console.log(notificationData)
  return (
<>
    {/* earlier background color bg-[#38424B] */}
<div className='notification-sec bg-[#14283a] dark:bg-[#0b1c1c]'>
     <div onClick={ Notification_on }>
        <img src={notification_icon}/>
        <p className='dark:text-white'>Notification</p>

        {width <= 570 ? 
        <button className='notifi-cross-icon' onClick={() => setMobileNotifi(!mobileNotifi)}><img src={cross_icon} /></button>
        :
        <button onClick={ Notification_on }>{open ? <><img src={down_arrow}  className='dark:invert'/></> : <><img src={up_arrow}  className='dark:invert'/></>}</button>
        }
    </div>




    {/*{width <= 570 ? 
        <section>
            <p className='dark:text-white'>Recents</p>
            <section>
            {notificationData[0].notifications.map((notification, key) => (
            <div key={key}>
                <img src={notification.image} alt={key}/>
                <p className='dark:text-white'> {notification.user} &nbsp; {notification.action}</p>
            </div>
            ))}
            </section>
        </section>
    :
    <>
        {notification ? <>
            <section>
                <p className='dark:text-white'>Recents</p>
                <section>
                {notificationData[0].notifications.map((notification, key) => (
            <div key={key}>
                <img src={notification.image} alt={key}/>
                <p className='dark:text-white'> {notification.user} &nbsp; {notification.action}</p>
            </div>
            ))}
                </section>
            </section>
            </> : <></> 
        }
    </>
    } */}
    <Side_notification notificationData={notificationData} userData={userData} logData={logData}/>
</div>

</>
    )
}
