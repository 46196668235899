import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import profile_img from "../Assets/Messages assets/story 1.png";
import mic_icon from "../Assets/Messages assets/mic icon.svg";
import cam_icon from "../Assets/Messages assets/Camera.svg";
import emoji_icon from "../Assets/Messages assets/emoji .svg";
import send_icon from "../Assets/Messages assets/send icon.svg";
import video_icon from "../Assets/Messages assets/Video.svg";
import call_icon from "../Assets/Messages assets/Calling.svg";
import three_dot from "../Assets/Messages assets/three dot.svg";
import { chats } from "./message_components";
import { ChatContext } from "../Services/ChatContext";
import {
  Timestamp,
  arrayUnion,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, database, storage } from "../Services/firebaseconfig";
import { AppContext } from "../Services/Context";
import { v4 as uuid } from "uuid";
import EmojiPicker from "emoji-picker-react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Msg_chat_Sec({userData}) {
  const [messages, setMessages] = useState([]);
  const [dayMessage, setDayMessage] = useState([]);
  const [text, setText] = useState("");
  const [emoji, setEmoji] = useState(false);
  const [allchats, setAllChats] = useState(chats);
  const { data } = useContext(ChatContext);
  const [dataFromDb, setDataFromDb] = useState(); //load chat data from db started, continue later on
  const {logData, width } = useContext(AppContext);
  const [err, setErr] = useState();

  const messageRef = useRef();

  const location = useLocation()


  useEffect(()=>{
    if(location.state){
      setText(location.state.text)
      if(text!==""){
        handleSend()
        location.state.text = ""
      }
    }
  }, [location.state && text])

  // console.log(data);

  useEffect(() => {
    const unsub = onSnapshot(doc(database, "chats", data.chatId), (doc) => {
      doc.exists() && setMessages(doc.data());
    });
    return () => {
      unsub();
    };
  }, [data.chatId]);

  const handleSend = async () => {
    if (text.trim("") != "") {
      await updateDoc(doc(database, "chats", data.chatId), {
        messages: arrayUnion({
          id: uuid(),
          text,
          senderId: logData.uid,
          date: Timestamp.now(),
        }),
      });

      // console.log(
      //   (await getDoc(doc(database, "userChats", logData.uid))).data()
      // );

      await updateDoc(doc(database, "userChats", logData.uid), {
        [data.chatId + ".lastMessage"]: {
          text,
          read: true,
        },
        [data.chatId + ".date"]: serverTimestamp(),
      });

      await updateDoc(doc(database, "userChats", data.user.uid), {
        [data.chatId + ".lastMessage"]: {
          text,
          read: false,
        },
        [data.chatId + ".date"]: serverTimestamp(),
      });

      setText("");
    } else {
      setText("");
      setErr("Write something to send");
    }
  };


  // Enter to send
  const enterToSend = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        handleSend()
      }
    },
    []
  );

  useEffect(
    (e) => {
      window.addEventListener("keydown", enterToSend);
      return () => {
        window.removeEventListener("keydown", enterToSend);
      };
    },
    [enterToSend]
  );

  useEffect(() => {
    if (messages.messages) {
      // Group messages by date
      const groupedMessages = messages.messages.reduce((grouped, message) => {
        // Format date to 'YYYY-MM-DD' for grouping
        const formattedDate = message.date.toDate().toDateString();

        // Create an array for the date if it doesn't exist
        grouped[formattedDate] = grouped[formattedDate] || [];

        // Push the message to the corresponding date
        grouped[formattedDate].push(message);

        return grouped;
      }, {});

      // Convert the grouped messages into an array of objects
      const groupedMessagesArray = Object.entries(groupedMessages).map(
        ([date, messages]) => ({
          date: date,
          messages,
        })
      );

      // Now, groupedMessagesArray contains an array of objects, each representing messages for a specific date
      setDayMessage(groupedMessagesArray);
      // groupedMessagesArray.map(day=>console.log(day.date))
    } else {
      // console.error("The 'messages' array is undefined or null.");
    }
  }, [messages.messages]);

  // console.log(dayMessage);
  // messages.map(day=>console.log(day.date))

  useEffect(() => {
    messageRef.current?.scrollIntoView({ behaviour: "smooth" });
  }, [dayMessage]);

  useEffect(() => {
    const images = document.querySelectorAll('.chat-sec img');
    let loadedImagesCount = 0;

    images.forEach((img) => {
      img.onload = () => {
        loadedImagesCount++;
        if (loadedImagesCount === images.length) {
          messageRef.current?.scrollIntoView({ behavior: "smooth" });
        }
      };
    });

    // If there are no images, just scroll immediately
    if (images.length === 0) {
      messageRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [dayMessage]);


  function linkify(text) {
    const urlPattern =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(
      urlPattern,
      '<u><a className="text-blue-500" href="$1" target="_blank">$1</a></u>'
    );
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `${logData.email}/messages/${data.user.uid}/${file.name}`)
      // const storageRef = ref(storage, `${logData.email}/posts/${randomId}.jpg`)
      // const fileRef = storageRef.child();
      await uploadBytes(storageRef, file);

      // Get the download URL
      const fileURL = await getDownloadURL(storageRef);

      await updateDoc(doc(database, "chats", data.chatId), {
        messages: arrayUnion({
          id: uuid(),
          text: "",
          imageURL: fileURL,
          senderId: logData.uid,
          date: Timestamp.now(),
        }),
      });

      await updateDoc(doc(database, "userChats", logData.uid), {
        [data.chatId + ".lastMessage"]: {
          text: "Image",
          read: true,
        },
        [data.chatId + ".date"]: serverTimestamp(),
      });

      await updateDoc(doc(database, "userChats", data.user.uid), {
        [data.chatId + ".lastMessage"]: {
          text: "Image",
          read: false,
        },
        [data.chatId + ".date"]: serverTimestamp(),
      });
    }
  };

  const navigate = useNavigate();
  const [callStatus, setCallStatus] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [selectedContact, setSelectedContact] = useState(data.user)

  const handleCallStatusChange = (callStatus) => {
    if (callStatus && callStatus.status === 'accepted') {
      navigate(`/videoCall/${callStatus.id}`);
    }
  };

  useEffect(()=>{
      setSelectedContact(data.user)
  }, [data.user])


  useEffect(() => {
    setSelectedContact(data.user)
    const user = auth.currentUser;
    if (user && data.user) {
      const callId = `${selectedContact.uid}_${user.uid}`
      const callRef = doc(database, 'calls', callId);
      const unsubscribe = onSnapshot(callRef, (snapshot) => {
        if (snapshot.exists()) {
          const newCallStatus = snapshot.data();
          setCallStatus(newCallStatus);
          handleCallStatusChange(newCallStatus);
        } else {
          setCallStatus(null);
          handleCallStatusChange(null);
        }
      });

      return () => unsubscribe();
    }
  }, [selectedContact]);

  // console.log(callStatus)

  const initiateCall = async () => {
    setSelectedContact(data.user)
    const user = auth.currentUser;
    if (user && selectedContact && !isBusy) {
      const callId =`${user.uid}_${selectedContact.uid}`;
      navigate(`/videoCall/${callId}`)
      const callRef = doc(database, 'calls', callId);
      await setDoc(callRef, {
        id: callId,
        caller: user.uid,
        callerName: userData.name,
        recipient: selectedContact.uid,
        recipientName: selectedContact.displayName,
        status: 'ringing',
      });
    }
  };

  const acceptCall = async () => {
    const user = auth.currentUser;
    if (user && callStatus) {
      const callId =`${selectedContact.uid}_${user.uid}`
      const callRef = doc(database, 'calls', callId);
      await updateDoc(callRef, { status: 'accepted' });
    }
  };

  const rejectCall = async () => {
    const user = auth.currentUser;
    if (user && callStatus) {
      const callId =`${selectedContact.uid}_${user.uid}`
      const callRef = doc(database, 'calls', callId);
      await updateDoc(callRef, { status: 'rejected' });
    }
  };


  // console.log(callStatus)

  return (
    <>
      <div className="home-middle-sec bg-[#8A8C8E] dark:bg-[#14283a]">
        <div className="profile-head w-[94%]">
          <section>
            <Link to={`/someones_page/${data.user?.uid}`} state={{ uid: data.user.uid }}>
              <img src={data.user?.photoURL || data.user?.img} />
              <p>{data.user.displayName || data.user?.name}</p>
            </Link>
          </section>
          <div>
            {/* {callStatus && callStatus.status === 'ringing' && callStatus.recipient === auth.currentUser.uid ? (
              <p onClick={acceptCall}>Join Call</p>
            ): <img src={video_icon} onClick={initiateCall} />} */}
            
            {/* {selectedContact && !callStatus && (
              // <button onClick={initiateCall}>Call {selectedContact.displayName}</button>
            )} */}
            {/* <img src={video_icon} onClick={initiateCall} /> */}
            {/* {callStatus && <p>Call status: {callStatus.status}</p>} */}

            {/* <img src={call_icon} /> */}
            <img src={three_dot} />
          </div>
        </div>
        <hr />
        <div className="emoji-picker">
          {emoji ? (
            <EmojiPicker
              height={width <= 500 ? 350 : 400}
              width={width <= 500 ? width : 400}
              onEmojiClick={(e) => setText(text+e.emoji)}
            />
          ) : (
            <></>
          )}
        </div>

        <div className="chat-sec overflow-y-scroll no-scrollbar ">
          {dayMessage.length != 0 ? (
            dayMessage.map((day, key) => (
              <>
                <p>{day.date}</p>
                {day.messages?.length ? (
                  day.messages.map((m, key) => (
                    <div
                      ref={messageRef}
                      key={key}
                      className={
                        m.senderId === logData.uid ? "my-msg" : "frnd-msg"
                      }
                    >
                      {m.senderId === logData.uid ? (
                        <></>
                      ) : (
                        <img src={data.user.photoURL || data.user?.img} />
                      )}
                      <div>
                        <div>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: linkify(m.text),
                            }}
                          ></p>
                          <img className={m.senderId === logData.uid ? "frnd_msg_send_img" : "msg_send_img"} src={m.imageURL ? m.imageURL : null} alt="" />
                        </div>
                        <p>{`${m.date.toDate().getHours() % 12}:${m.date.toDate().getMinutes() < 10 ? "0" : ""
                          }${m.date.toDate().getMinutes()} ${m.date.toDate().getHours() < 12 ? "AM" : "PM"
                          }`}</p>
                      </div>
                      {m.senderId === logData.uid ? (
                        <img src={userData.img} />
                      ) : (
                        <></>
                      )}
                    </div>
                  ))
                ) : (
                  <>
                    <p>Start Messaging now</p>
                  </>
                )}
              </>
            ))
          ) : (
            <></>
          )}

          {/* {allchats.map((chat, key) => (
            <div className={chat.class}>
              {chat.friend_src ? <img src={chat.friend_src} /> : <></>}

              <div>
                <div>
                  <p>{chat.msg}</p>
                </div>
                <p>{chat.time}</p>
              </div>
              {chat.src ? <img src={chat.src} /> : <></>}
            </div>
          ))} */}
        </div>
        <div className="msg-text">
          <div>
            <img src={emoji_icon} onClick={() => setEmoji(!emoji)} />

            <input
              onChange={(e) => setText(e.target.value)}
              value={text}
              placeholder={err ? err : "Type Message"}
            />
            <img src={mic_icon} />
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <img src={cam_icon} onClick={() => document.getElementById('fileInput').click()} />
          </div>
          <section>
            <img onClick={handleSend} src={send_icon} />
          </section>
        </div>
      </div>
    </>
  );
}
