import React, { useContext, useState } from "react";
import "../Styles/accounts_page.css";
import Acc_profile from "./Acc_profile";
import Acc_about from "./Acc_about";
import Acc_edit from "./Acc_edit";
import Acc_posts from "./Acc_posts";
import Acc_Post_box from "./Acc_Post_box";
import { AppContext } from "../Services/Context";
import { Acc_takes_post_box } from "./Acc_takes_post_box";

export default function Accounts_page() {
  const [createTwig, setCreateTwig] = useState(false)
  const [createTakes, setCreateTakes] = useState(false)
  const [userTwigs, setUserTwigs] = useState([])
  const [mySuggestion, setMySuggestion] = useState(false)

  const { userData, logData } = useContext(AppContext);

  return (
    <>
      {/* <Acc_Post_box /> */}
      <div className="accounts-right-sec">
    {createTwig ? <Acc_Post_box setCreateTwig={setCreateTwig} /> : <></>}
    {createTakes ? <Acc_takes_post_box setCreateTakes={setCreateTakes} /> : <></>}


        <Acc_profile userData={userData} userTwigs={userTwigs} />
        <div className="acc-midl-flex">
          <Acc_about mySuggestion={mySuggestion} userData={userData} />
          <Acc_edit setMySuggestion={setMySuggestion} mySuggestion={mySuggestion} userData={userData} logData={logData} setCreateTwig={setCreateTwig} setCreateTakes={setCreateTakes} />
        </div>
        <Acc_posts userTwigs={userTwigs} setUserTwigs={setUserTwigs} />
      </div>
    </>
  );
}
