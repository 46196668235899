import React, { useContext, useEffect, useState } from 'react'
import '../Styles/someones_acc.css'
import Someone_Acc_profile from './Someone_Acc_profile'
import Someone_Acc_abouts from './Someone_Acc_abouts'
import Someone_Acc_highlight from './Someone_Acc_highlight'
import Someone_Acc_posts from './Someone_Acc_posts'

import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from 'firebase/firestore'
import { auth, database } from '../Services/firebaseconfig'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppContext } from '../Services/Context'
import Someone_Acc_Lock from './Someone_Acc_Lock'

export default function Someone_account() {

  const [userTwigs, setUserTwigs] = useState([]);
  const [someoneData, setSomeoneData] = useState()
  const [checkLock, setCheckLock] = useState()
  const [accSugest, setAccSugest] = useState(false)
  const [uidFromURL, setUidFromURL] = useState()

  const { state } = useLocation()
  const { userData, logData } = useContext(AppContext)
  const navigate = useNavigate()

  useEffect(() => {

    async function fetchData(uid) {
      onSnapshot(doc(database, "User Data", uid), (data) => {
        // console.log(data.data())

        const someoneData = {
          ...data.data(),
          uid: uid
        };
        // console.log(someoneData)
        setSomeoneData(someoneData)
        // setUserTwigs((userTwigs) => [...userTwigs, {...doc.data(), docid:doc.id}]);
        setUidFromURL(uid)
        if (data.data().followers.includes(auth.currentUser.uid)) {
          // console.log("Hi  there");
          setCheckLock(false)
        }
        else {
          setCheckLock(data.data().lock)
        }
      })
      // .then((data) => {
      // })      
    }
    if (state == null | undefined) {
      const uid = decodeURI((window.location.pathname.split("/").slice(-1)).toString());
      // console.log(uid);
      const data = async () => {
        const docSnap = await getDoc(doc(database, "Uid", uid))
        if (docSnap.exists && auth.currentUser) {
          // console.log(docSnap.data().uid);
          fetchData(docSnap.data().uid)
          setUidFromURL(docSnap.data().uid)
        }
      }
      data()
    } else fetchData(state.uid)
  }, [])

  useEffect(() => {
    async function checkUser(user) {
      if (userData) {
        if (userData.user_name === user) {
          navigate('/accounts_page')
          // window.onpopstate = () => {
          //   navigate('/')
          // }
        }
      }
    }
    if (state == null | undefined) {
      checkUser(decodeURI((window.location.pathname.split("/").slice(-1)).toString()))
    } else checkUser(state.user)
  }, [])

  
  useEffect(() => {
    const handlePopState = () => {
      navigate(-1);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    const fetchPosts = async (uid) => {
      try {
        const q = query(
          collection(database, "Twigs"),
          where("uid", "==", `${uid}`)
        );
        const querySnapshot = await getDocs(q);
        const updatedTwigs = [];
        querySnapshot.forEach((doc) => {
          updatedTwigs.push({ ...doc.data(), docid: doc.id });
          // console.log(doc.id, " => ", doc.data());
          // setUserTwigs((userTwigs) => [...userTwigs, { ...doc.data(), docid: doc.id }]);  
          // setCheckLock(false)
        });
        updatedTwigs.sort((a, b) => b.timeStamp - a.timeStamp);
        setUserTwigs(updatedTwigs)
      } catch (e) {
        // console.log(e);
      }
    };

    if (state == null | undefined) {
      const uid = decodeURI((window.location.pathname.split("/").slice(-1)).toString());
      if (uid !== "accounts_page") {
        const data = async () => {
          const docSnap = await getDoc(doc(database, "Uid", uid))
          if (docSnap.exists) {
            // console.log(docSnap.data());
            fetchPosts(docSnap.data().uid)
          }
        }
        data()
      }
    } else
      fetchPosts(state.uid);
    // console.log(userTwigs);
  }, []);

  // useEffect(() => {
  //   const check_lock_profile = async () => {
  //     onSnapshot(doc(database, "User Data", `${state.uid}`), (data) => {
  //       if(data.data().followers.includes(userData.uid)) {
  //         console.log("Hi  there");
  //         setCheckLock(false)
  //       }
  //     }) 
  //   } 


  //   check_lock_profile()
  // }, [])

  return (
    <>
      <div className="accounts-right-sec">
        {
          someoneData ? <>
            < Someone_Acc_profile checkLock={checkLock} userData={someoneData} userTwigs={userTwigs} uidFromURL={uidFromURL}/>
            < Someone_Acc_abouts userData={someoneData} state={state} accSugest={accSugest} setAccSugest={setAccSugest} uidFromURL={uidFromURL} thisUser={userData} />
            < Someone_Acc_highlight checkLock={checkLock} someoneData={someoneData} accSugest={accSugest} setAccSugest={setAccSugest} />
            {checkLock ? < Someone_Acc_Lock /> : < Someone_Acc_posts uidFromURL={uidFromURL} userData={someoneData} userTwigs={userTwigs} />}
          </>
            :
            <></>
        }

      </div>
    </>
  )
}
