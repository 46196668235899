export let grps = [
    {
        "id":"1",
        "src":require('../Assets/Accouonts Page/friend 1.png'),
        "class":"img-1"
    },
    {
        "id":"2",
        "src":require('../Assets/Accouonts Page/friend 2.png'),
        "class":"img-2"
    },
    {
        "id":"3",
        "src":require('../Assets/Accouonts Page/friend 3.png'),
        "class":"img-3"
    },
    {
        "id":"4",
        "src":require('../Assets/Accouonts Page/friend 1.png'),
        "class":"img-4"
    },
    {
        "id":"5",
        "src":require('../Assets/Accouonts Page/friend 2.png'),
        "class":"img-5"
    },
]

export let highlights = [
    {
        "id":"1",
        "src":require('../Assets/Accouonts Page/friend 1.png'),
        "name":"Travel"
    },
    {
        "id":"2",
        "src":require('../Assets/Accouonts Page/friend 2.png'),
        "name":"Food"
    },
    {
        "id":"3",
        "src":require('../Assets/Accouonts Page/friend 3.png'),
        "name":"Concert"
    },
    {
        "id":"4",
        "src":require('../Assets/Accouonts Page/friend 1.png'),
        "name":"Party"
    },
    {
        "id":"5",
        "src":require('../Assets/Accouonts Page/friend 2.png'),
        "name":"Travel"
    },
    {
        "id":"6",
        "src":require('../Assets/Accouonts Page/friend 3.png'),
        "name":"Food"
    },
]

export let posts = [
    {
        "id":"1",
        "src":require('../Assets/Accouonts Page/friend 4.jpg'),
    },
    {
        "id":"2",
        "src":require('../Assets/Accouonts Page/friend 1.png'),
    },
    {
        "id":"3",
        "src":require('../Assets/Accouonts Page/friend 3.png'),
    },
    {
        "id":"4",
        "src":require('../Assets/Accouonts Page/friend 2.png'),
    },
]