import React, { useContext, useEffect, useState } from "react";
import { highlights } from "./acc_components";
import cross_icon from "../Assets/Home page 1/cross icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../Services/Context";
import { doc, onSnapshot } from "firebase/firestore";
import { database } from "../Services/firebaseconfig";

export default function Acc_Saved_Post() {
  const [saved, setSaved] = useState([]);
  const navigate = useNavigate();
  const {userData, width} = useContext(AppContext)
  return (
    <>
      <div className="saved-post-main w-full h-screen">
        <div className="saved-twigs-top">
          <p className="dark:text-white">Saved Post</p>
          {/* <Link to="/accounts_page"> */}
            <button onClick={() => navigate(-1)}>
              <img className="invert dark:invert-0" src={cross_icon} />
            </button>
          {/* </Link> */}
        </div>
        <div className="twigs-posts-btn">
          <div>
            <button className="dark:text-white">Twigs</button>
            <button className="dark:text-white">Takes</button>
          </div>
        </div>

        <div className="img-grid someone-acc-grid">
          {userData.savedTwigs.map((feed, key) => (
            <Saved_card twigId={feed} width={width}/>
          ))}
        </div>
      </div>
    </>
  );
}

const Saved_card = ({twigId, width}) => {
  const [twigData, setTwigData] = useState()
    useEffect(() => {
      onSnapshot(doc(database, "Twigs", twigId),
            (doc) => {setTwigData(doc.data())} 
        )
    },[twigId]);
  return (
    <Link to={`${width <=570 ? `/acc_mobile_post/${twigId}`: `/twig/post/${twigId}`}`} 
      state={{ feed: twigData, twigId: twigId}}>
      <div>
          {/* <h1>{twigId}</h1> */}
        <img src={twigData?.image[0]} />
      </div>
    </Link>
  );
};
