export let messages = [
    {
        "id":"1",
        "src":require("../Assets/Messages assets/story 1.png"),
        "name":"SomeOne",
        "content":"Who are you?",

    },
    {
        "id":"2",
        "src":require("../Assets/Messages assets/story 1.png"),
        "name":"SomeOne",
        "content":"Who are you?",

        "class":"unseen-msg",
        "unseen_num":"10"
    },
    {
        "id":"3",
        "src":require("../Assets/Messages assets/story 1.png"),
        "name":"SomeOne",
        "content":"Who are you?",

        "class":"unseen-msg",
        "unseen_num":"1"
    },
    {
        "id":"4",
        "src":require("../Assets/Messages assets/story 1.png"),
        "name":"SomeOne",
        "content":"Who are you?",

        "class":"unseen-msg",
        "unseen_num":"5"
    },
    {
        "id":"5",
        "src":require("../Assets/Messages assets/story 1.png"),
        "name":"SomeOne",
        "content":"Who are you?",

        "class":"unseen-msg",
        "unseen_num":"5"
    },
    {
        "id":"6",
        "src":require("../Assets/Messages assets/story 1.png"),
        "name":"SomeOne",
        "content":"Who are you?",

        "class":"unseen-msg",
        "unseen_num":"5"
    },
    {
        "id":"7",
        "src":require("../Assets/Messages assets/story 1.png"),
        "name":"0000",
        "content":"Who are you?",

        "class":"unseen-msg",
        "unseen_num":"5"
    },
    {
        "id":"8",
        "src":require("../Assets/Messages assets/story 1.png"),
        "name":"ooooo",
        "content":"Who are you?",

        "class":"unseen-msg",
        "unseen_num":"5"
    },
    {
        "id":"9",
        "src":require("../Assets/Messages assets/story 1.png"),
        "name":"SomeOne",
        "content":"Who are you?",

        "class":"unseen-msg",
        "unseen_num":"5"
    },
    {
        "id":"10",
        "src":require("../Assets/Messages assets/story 1.png"),
        "name":"SomeOne",
        "content":"Who are you?",

        "class":"unseen-msg",
        "unseen_num":"5"
    },
    {
        "id":"11",
        "src":require("../Assets/Messages assets/story 1.png"),
        "name":"uihfiUWEFI",
        "content":"Who are you?",

        "class":"unseen-msg",
        "unseen_num":"5"
    },
    {
        "id":"12",
        "src":require("../Assets/Messages assets/story 1.png"),
        "name":"uihfiUWEFI",
        "content":"Who are you?",

        "class":"unseen-msg",
        "unseen_num":"5"
    },
    {
        "id":"13",
        "src":require("../Assets/Messages assets/story 1.png"),
        "name":"uihfiUWEFI",
        "content":"Who are you?",

        "class":"unseen-msg",
        "unseen_num":"9"
    },
    {
        "id":"14",
        "src":require("../Assets/Messages assets/story 1.png"),
        "name":"jjjjjjjjjjjjjjjjj",
        "content":"Who are you?",

        "class":"unseen-msg",
        "unseen_num":"10"
    }
]

export let chats = [
    {
        "id":"1",
        "class":"frnd-msg",
        "friend_src":require("../Assets/Messages assets/cover img.png"),
        "src":null,
        "msg":"Hellowwowowo",
        "time":"8:17"
    },
    {
        "id":"2",
        "class":"my-msg",
        "friend_src":null,
        "src":require("../Assets/Messages assets/story 1.png"),
        "msg":"WTF bRO",
        "time":"8:17"
    },
    {
        "id":"3",
        "class":"frnd-msg",
        "friend_src":require("../Assets/Messages assets/cover img.png"),
        "src":null,
        "msg":"Hellowwowowo",
        "time":"8:17"
    },
    {
        "id":"4",
        "class":"my-msg",
        "friend_src":null,
        "src":require("../Assets/Messages assets/story 1.png"),
        "msg":"Hellowwowowo",
        "time":"8:17"
    }
]

export let myfriends = [
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-indicator",
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Tirtha",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
]