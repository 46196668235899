import React, { useEffect, useState } from 'react'
import create_tales from '../Assets/Accouonts Page/create twigs.svg'
import create_twigs from '../Assets/Accouonts Page/create tales.svg'
import save_twigs from '../Assets/Accouonts Page/save twigs.svg'
import down_btn from "../Assets/Someone Acc assets/down btn.svg";
import up_btn from "../Assets/Someone Acc assets/up_btn.svg";

import Acc_Post_box from './Acc_Post_box'
import { doc, updateDoc } from 'firebase/firestore'
import { database } from '../Services/firebaseconfig'
import Acc_Tales_Post from './Acc_Tales_Post'
import { Link } from 'react-router-dom'

export default function Acc_edit({userData, logData, setCreateTwig, mySuggestion, setMySuggestion, setCreateTakes}) {

    // const [createTwig, setCreateTwig] = useState(false)
    const [createTales, setCreateTales] = useState(false)
    const [lock, setLock] = useState()

    useEffect(() => {
        async function checkLock() {
            if(userData.lock === true) {
                setLock(true)
            }
        }
    checkLock()
    }, [])

    const handle_change = (e) => {
        if (e.target.checked) {
            // setLock(true)
            // console.log(lock);
            try {
                updateDoc(doc(database, "User Data", `${logData.uid}`), {
                    lock:true
                })
            }
            catch (e) {
                // console.log(e);
            }
        }
        else {
            // console.log(lock);
            async function lockProfile() {
                await updateDoc(doc(database, "User Data", `${logData.uid}`), {
                    lock:false
                })
            }
            return lockProfile()
        }
        setLock(current => !current)
    } 
  return (
<>

{/* < Acc_Tales_Post /> */}
{/* {createTwig ? <Acc_Post_box setCreateTwig={setCreateTwig} /> : <></>} */}

<div className="acc-edit-sec">
    
    {/* <button>+Add Tales</button> */}
    <div>
        <Link to="/settings_page">
            <button>Edit Profile</button>
        </Link>

        <button onClick={() => setMySuggestion(!mySuggestion)}><img src={mySuggestion ? up_btn : down_btn} /></button>
    </div>

    <div className="create-post">
        <button onClick={() => setCreateTwig(true)}>
            <img src={create_twigs}/>
            <p className='dark:text-white'>Create Twigs</p>
        </button>
        {/* <hr/> */}
        {/* <Link to="/tales_page"> */}
            <button onClick={() => setCreateTakes(true)}>
                <img src={create_tales}/>
                <p className='dark:text-white'>Create Takes</p>
            </button>
        {/* </Link> */}
        {/* <hr/> */}
        <Link to="/accounts_page/save_post">
            <button>
                <img src={save_twigs}/>
                <p className='dark:text-white'>Save Twigs</p>
            </button>
        </Link>
    </div>


    <div className="lock-switch">
        <p className='text-dark dark:text-white'>Lock Your Profile</p>
        {/* <input type="checkbox" id="switch" onChange={(e) => handle_change(e)} value={lock} checked={userData.lock}/><label htmlFor="switch" >Toggle</label> */}
        <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" className="sr-only peer" onChange={(e) => handle_change(e)} value={lock} checked={userData.lock}/>
            <div className="mr-4 w-14 h-6 bg-gray-500 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-8 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BDFA3C] after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
        </label>
    </div>
</div>

</>
  )
}
