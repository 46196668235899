import React, { useContext, useRef, useState } from 'react'

import cross_icon from '../Assets/Home page 1/cross icon.svg'
import crop_icon from '../Assets/Home page 1/crop icon.svg'
import fliter_icon from '../Assets/Home page 1/filter icon.svg'
import galary_icon from '../Assets/Home page 1/galary icon.svg'
import edit_icon from '../Assets/Home page 1/edit twigs.svg'
import photo from '../Assets/Home page 1/story 2.png'
import profile_photo from '../Assets/Home page 1/story 1.png'
import twigyy from '../Assets/Images/Login Page/twigyy-logo.png'
import profile_img from '../Assets/Accouonts Page/profile pic.png'
import pic from "../Assets/Accouonts Page/mashel .png"
import text_tales from '../Assets/Accouonts Page/Aa.svg'
import create_tales from '../Assets/Settings Page/cover change.svg'

import { AppContext } from '../Services/Context'

export default function Acc_Tales_Post(props) {

  const { logData, userData } = useContext(AppContext)
  const uploadInputRef = useRef(null)

  const [edit, setEdit] = useState(false)
  const [talesFile, setTalesFile] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [caption, setCaption] = useState(false)
  const [storyNext, setStoryNext] = useState(false)
  // const [type, setType] = useState()


  const handleChangeInput = (e) => {
    setTalesFile(URL.createObjectURL(e.target.files[0]))
    setSelectedFile(e.target.files[0])
  }
  const Nextpg = () => {
    uploadInputRef.current && uploadInputRef.current.click()
  }


  // function Check_file_type () {
  //   const file = selectedFile
  //   if(!file) return
  //   const type = (file.type);
  //   return type
  // }
  // Check_file_type()
  // console.log(type);
  return (


<div className='tales-main'>

  <section className='your-tales'>
    <div>
      <img className="twigyy-logo-side-bar" src={twigyy}/>
    </div>
    <div>
      <p className='dark:text-white'>Your Tales</p>

      <div>
        <img src={profile_img}/>
        <button className='dark:text-white'>+5</button>
        {/* <section className='tales-views'>
          <p>Views</p>
          <div>
            <img src={profile_img}/>
            <p>Name</p>
            <p>10:50 AM</p>
          </div>
        </section> */}
      </div>
    </div>
  </section>
  <hr className='h-[95vh] my-auto border-[1px] border-black dark:border-white'/>

  <section className='edit-tales'>
    {storyNext ? 
    <div className='edit-story'>
      <p>New Tales</p>
      <div>
        <section>
          <p className='dark:invert'>Preview</p>
          <div>
            <img src={profile_img}/>
          </div>
        </section>

        <section className='edit-story-field'>
          <div>
            <div>
              <img src={text_tales}/>
              <p>Add Text</p>
            </div>
            <textarea rows={3} placeholder='Write Your Text'></textarea>
          </div>

          <hr/>
          
          <div>
            <p>Select Font Style</p>
            <div className='dark:text-white'>
              <button>Aa</button>
              <button>Aa</button>
              <button>Aa</button>
              <button>Aa</button>
              <button>Aa</button>
              <button>Aa</button>
              <button>Aa</button>
              <button>Aa</button>
            </div>
          </div>

          <hr/>

          <div>
            <p>Choose Color For Text</p>
            <div>
              <button></button>
              <button></button>
              <button></button>
              <button></button>
              <button></button>
              <button></button>
              <button></button>
              <button></button>
            </div>
          </div>

          <div>
            <button>Discard</button>
            <button>Share</button>
          </div>
        </section>
      </div>
    </div>
    :
    <>
      <div className='tales-option'>
        <button onClick={() => setStoryNext(true)}>
          <img src={create_tales} className='invert dark:invert-0'/>
          <p className='invert dark:invert-0'>Create Tales</p>
        </button>
        <button>
          <img src={text_tales} className='invert dark:invert-0'/>
          <p className='invert dark:invert-0'>Create Text Tales</p>
        </button>
      </div>
      <div className='bg-color-btn'>
        <button></button>
        <button></button>
        <button></button>
        <button></button>
        <button></button>
      </div>
      </>
    }


    {/* <div className='preview-tales'>
      <section>
        <p>Tale</p>
        <img src={profile_img}/>
      </section>
      <hr/>
      <section className='tales-views'>
        <p>Views</p>
        <div>
          <img src={profile_img}/>
          <p>Name</p>
          <p>10:50 AM</p>
        </div>
      </section>
    </div> */}

  </section>
</div>


  )
}
