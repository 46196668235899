import React, { useEffect, useState } from 'react'
import { buttons } from './search_components'
import pic from '../Assets/Search assets/news img.png'
import search_icon from '../Assets/Home page 1/search icon.svg'
import { Link } from 'react-router-dom'
import { collection, getDocs } from 'firebase/firestore'
import { database } from '../Services/firebaseconfig'


export default function Search_bar_twigs({setSearchText, frndShow, setFrndShow}) {
    
    const [allbuttons, setAllButtons] = useState(buttons)
    const [hash, setHash] = useState(false)
    const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);

    useEffect(() => {
        const handleSearch = async () => {
          if (search !== "" && search.length > 2) {
            const querySnapshot = await getDocs(collection(database, "User Data"));
            const results = [];
            const searchKeywords = search.trim().split(" ");
            console.log(searchKeywords)
    
            querySnapshot.docs
              .map((doc) => doc.data())
              .forEach((user) => {
                // For each user, check if any search keyword is included in the user name
                if (searchKeywords.some(keyword => keyword!== "" && user.name.toLowerCase().startsWith(keyword.toLowerCase()))) {
                  results.push(user);
                }
              });
            setResults(results)
            setFrndShow(true)
          }
          if (search === "") {
            setResults([])
          }
        }
    
        handleSearch();
      }, [search]);

console.log(results)
  return (
<>


<div className="search-bar">
    <img src={search_icon}/>
    <input placeholder="Twigs & Twig" onChange={(e)=>{setSearch(e.target.value); setHash(false)}}/>

    {frndShow && hash === false ? 
    <section className='friend_list_sp'>
        {results.length != 0 ? results.map((user) => (
            <Link>
                <img src={user.img} />
                <div>
                    <p>{user.name}</p>
                    {/* <p>{user.username}</p> */}
                </div>
            </Link>
        )) : null}
    </section>
    :
    <></>
    }
    
</div>

<div className="twigs-type">
{/* {allbuttons.map((button, key) => (
    <div>
        <button className="twigs-btn">{button.name}</button>
        <img src= {button.src} />
    </div>  
))} */}
    {allbuttons.map((button, key) => (
        <div key={key}>
            <button className="twigs-btn-test" onClick={() => {setSearchText(button.name); setHash(true)}}>{button.name}<img src= {button.src} /></button>
        </div>  
    ))}
    
        
    {/* <button className="more-btn text-black dark:text-white">
        +more
    </button> */}
</div>


</>
  )
}
