export let mutuals = [
    {
        "id":"1",
        "src":require('../Assets/Someone Acc assets/friend 3.png'),
        "class":"mutual-img-1"
    },
    {
        "id":"2",
        "src":require('../Assets/Someone Acc assets/friend 2.png'),
        "class":"mutual-img-2"
    },
    {
        "id":"3",
        "src":require('../Assets/Someone Acc assets/friend 1.png'),
        "class":"mutual-img-3"
    },
    {
        "id":"4",
        "src":require('../Assets/Someone Acc assets/friend 3.png'),
        "class":"mutual-img-4"
    },
    {
        "id":"5",
        "src":require('../Assets/Someone Acc assets/friend 2.png'),
        "class":"mutual-img-5"
    }
]

export let suggestions = [
    {
        "id":"1",
        "src":require('../Assets/Someone Acc assets/friend 1.png'),
        "name":"Person 1"
    },
    {
        "id":"2",
        "src":require('../Assets/Someone Acc assets/friend 2.png'),
        "name":"Person 2"
    },
    {
        "id":"3",
        "src":require('../Assets/Someone Acc assets/friend 3.png'),
        "name":"Person 3"
    },
    {
        "id":"4",
        "src":require('../Assets/Someone Acc assets/friend 1.png'),
        "name":"Person 4"
    },
    {
        "id":"5",
        "src":require('../Assets/Someone Acc assets/friend 2.png'),
        "name":"Person 5"
    },
    {
        "id":"6",
        "src":require('../Assets/Someone Acc assets/friend 2.png'),
        "name":"Person 5"
    },
    {
        "id":"7",
        "src":require('../Assets/Someone Acc assets/friend 2.png'),
        "name":"Person 5"
    },
    {
        "id":"8",
        "src":require('../Assets/Someone Acc assets/friend 2.png'),
        "name":"Person 5"
    },
]

export let highlights = [
    {
        "id":"1",
        "src":require('../Assets/Someone Acc assets/friend 1.png'),
        "name":"Food"
    },
    {
        "id":"2",
        "src":require('../Assets/Someone Acc assets/friend 2.png'),
        "name":"Food"
    },
    {
        "id":"3",
        "src":require('../Assets/Someone Acc assets/friend 3.png'),
        "name":"Food"
    },
    {
        "id":"4",
        "src":require('../Assets/Someone Acc assets/friend 1.png'),
        "name":"Food"
    },
    {
        "id":"5",
        "src":require('../Assets/Someone Acc assets/friend 2.png'),
        "name":"Food"
    },
    {
        "id":"6",
        "src":require('../Assets/Someone Acc assets/friend 3.png'),
        "name":"Food"
    },
    {
        "id":"7",
        "src":require('../Assets/Someone Acc assets/friend 1.png'),
        "name":"Food"
    },
    {
        "id":"8",
        "src":require('../Assets/Someone Acc assets/friend 2.png'),
        "name":"Food"
    },
    {
        "id":"9",
        "src":require('../Assets/Someone Acc assets/friend 3.png'),
        "name":"Food"
    },
    {
        "id":"10",
        "src":require('../Assets/Someone Acc assets/friend 1.png'),
        "name":"Food"
    },
]

export let posts = [
    {
        "id":"1",
        "src":require('../Assets/Someone Acc assets/friend 1.png')
    },
    {
        "id":"2",
        "src":require('../Assets/Someone Acc assets/friend 2.png')
    },
    {
        "id":"3",
        "src":require('../Assets/Someone Acc assets/friend 3.png')
    },
    {
        "id":"4",
        "src":require('../Assets/Someone Acc assets/friend 1.png')
    },
    {
        "id":"5",
        "src":require('../Assets/Someone Acc assets/friend 2.png')
    },
    {
        "id":"6",
        "src":require('../Assets/Someone Acc assets/friend 3.png')
    }
]