import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppContext } from "../Services/Context";
import cross_icon from "../Assets/Home page 1/cross icon.svg";
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { database, auth } from "../Services/firebaseconfig";

import three_dot from "../Assets/Home page 1/three dot.svg";
import save_2 from "../Assets/Home page 1/save 2.svg";
import save_btn from "../Assets/Home page 1/save icon.svg";
import download from "../Assets/Home page 1/download.svg";
import share_icon from "../Assets/Home page 1/share 2.svg";

import report from "../Assets/Home page 1/report.svg";
import why_see from "../Assets/Home page 1/why see.svg";
import not_int from "../Assets/Home page 1/not interested.svg";

import delete_icon from "../Assets/Accouonts Page/Delete post.svg";

import love_like from "../Assets/Home page 1/love like.svg";
import empty_like from "../Assets/Home page 1/empty like.svg";
import comment_btn from "../Assets/Home page 1/comment.svg";

import scroll_left from "../Assets/Home page 1/scroll left.svg";
import scroll_right from "../Assets/Home page 1/scroll right.svg";

import reply_icon from "../Assets/Home page 1/reply icon.svg";
import mic_icon from "../Assets/Messages assets/mic icon.svg";
import emoji_icon from "../Assets/Messages assets/emoji .svg";
import send_icon from "../Assets/Messages assets/send icon.svg";
import comment_profile from "../Assets/Home page 1/story 1.png";
import twigyy_logo from "../Assets/Images/Login Page/twigyy-logo_2.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../Register/Loader";
import EachComment from "../Comment/EachComment";
import EmojiPicker from "emoji-picker-react";

export const Post_view = () => {
  const { userData, width } = useContext(AppContext);
  // console.log(state.twig);
  const [threeOpen, setThreeOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [twigUser, setTwigUser] = useState();
  const [user, setUser] = useState();
  const [twig, setTwig] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [index, setIndex] = useState(0);
  const [liked, setLiked] = useState(false);
  const [comment, setComment] = useState("");
  const { logData } = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const containerRef = useRef(null);
  const [emoji, setEmoji] = useState(false);
  const [text, setText] = useState("");

  const twigId = state
    ? state.twig.docId
    : decodeURI(window.location.pathname.split("/").slice(-1)[0]);

  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 27) {
        // setTwig(!twig);
        navigate(-1);
      }
    },
    [twig]
  );

  useEffect(
    (e) => {
      window.addEventListener("keydown", checkKeyPress);
      return () => {
        window.removeEventListener("keydown", checkKeyPress);
      };
    },
    [checkKeyPress]
  );

  const enterToSend = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        postComment()
      }
    },
    [comment]
  );

  useEffect(
    (e) => {
      window.addEventListener("keydown", enterToSend);
      return () => {
        window.removeEventListener("keydown", enterToSend);
      };
    },
    [enterToSend]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(database, "Twigs", twigId);
        const unsubscribe = onSnapshot(docRef, (doc) => {
          setTwig(doc.data());
          setIsLoading(false); // Update loading state
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false); // Update loading state in case of error
      }
    };

    fetchData();
  }, [state]); // Call effect when state changes

  const [likeCount, setLikeCount] = useState(twig?.react?.like.length);

  useEffect(() => {
    if (twig && twig.react?.like) {
      if (twig.react?.like.includes(logData.uid)) {
        setLiked(true);
      } else {
        setLiked(false);
      }
    }
  }, [likeCount]);

  useEffect(() => {
    if (twig) {
      const postRef = doc(database, "Twigs", twigId);

      const unsubscribe = onSnapshot(postRef, (snapshot) => {
        if (snapshot.exists) {
          const postData = snapshot.data();
          const newLikeCount = postData.react.like
            ? postData.react.like.length
            : 0;
          setLikeCount(newLikeCount);
        }
      });

      return () => unsubscribe(); // Cleanup the listener when the component unmounts
    }
  }, [twig]);

  if (isLoading) {
    return <img src={twigyy_logo} alt="twigyy logo" />;
  }

  // console.log(twig.react?.like)

  async function setUserImage(uid) {
    const docRef = doc(database, "User Data", `${uid}`);
    const docSnap = await getDoc(docRef);
    const profile_img = docSnap.data().img;
    setImgSrc(profile_img);
    return profile_img;
  }

  async function setUserName(uid) {
    const docRef = doc(database, "User Data", `${uid}`);
    const docSnap = await getDoc(docRef);
    const profile_img = docSnap.data().name;
    setTwigUser(profile_img);
    return profile_img;
  }
  async function getUser(uid) {
    const docRef = doc(database, "User Data", `${uid}`);
    const docSnap = await getDoc(docRef);
    const profile_img = docSnap.data().user_name;
    setUser(profile_img);
    return profile_img;
  }

  setUserImage(twig.uid);
  setUserName(twig.uid);
  getUser(twig.uid);

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 550, behavior: "smooth" }); // Adjust the scroll amount as needed
      setIndex(index + 1);
    }
  };

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -550, behavior: "smooth" }); // Adjust the scroll amount as needed
      setIndex(index - 1);
    }
  };
  const handleScroll = () => {
    // Assuming each slide has the same width
    const slideWidth = containerRef.current.scrollWidth / twig.image.length;
    const newActiveIndex = Math.floor(
      (containerRef.current.scrollLeft + slideWidth / 2) / slideWidth
    );
    setIndex(newActiveIndex);
  };

  // Like functions
  async function like_update(id) {
    await setDoc(
      doc(database, "Twigs", id),
      {
        react: { like: arrayUnion(logData.uid) },
      },
      { merge: true }
    );
    const followNotification = {
      "user": `${userData.name}`,
      "action": "has liked your post",
      "image": `${userData.img}`,
      "postId": `${id}`,
      "timestamp":serverTimestamp()
    };
    await setDoc(
      doc(database, "notifications", `${twig.uid}`),
      {
        notifications: arrayUnion(followNotification),
      },
      { merge: true }
    );
    setLiked(true);
  }

  async function unlike_update(id) {
    try {
      // Get the current document
      const docRef = doc(database, "Twigs", id);
      const docSnapshot = await getDoc(docRef);

      // Get the current array
      const currentLikes = docSnapshot.data().react.like;

      // Find the index of logData.uid in the array
      const indexToRemove = currentLikes.indexOf(logData.uid);

      if (indexToRemove !== -1) {
        // If logData.uid is found, remove it from the array
        currentLikes.splice(indexToRemove, 1);

        // Update the document with the modified array
        await updateDoc(docRef, {
          react: { like: currentLikes },
        });

        // Set the liked state to false
        setLiked(false);
      } else {
        // logData.uid not found in the array
        // console.log("logData.uid not found in the like array");
      }
    } catch (e) {
      // console.log(e);
    }
  }

  async function postComment() {
    if (comment !== "") {
      // console.log(comment);
      try {
        await updateDoc(doc(database, "Twigs", twigId), {
          comment: arrayUnion({
            text: comment,
            uid: logData.uid,
            timeStamp: new Date(),
          }),
        }).then(setComment(""));
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    } else {
      // console.log("write comment");
    }
  }

  // console.log(twig);

  return (
    <>
      <div className="acc-bg-blur">
        {/* <button onClick={()=>setTwig(!state.twig)}><img src={cross_icon}/></button> */}
        <button
          className="action-cross-btn hover:bg-[#6c6c6ca5] p-2 rounded-full"
          onClick={() => {
            // setTwig(!twig);
            // navigate(-1, {state:{scrollPosition: state.scrollPosition}})
            navigate(-1)
          }}
        >
          <img src={cross_icon} />
        </button>

        <section className="twigs-view-section">
          <div className="twig-view">

            <section
              className="twig-image-size bg-white dark:bg-black"
            >
              {twig.image.length > 1 ? (
                <>
                  {index === 0 ? (
                    <></>
                  ) : (
                    <button onClick={scrollLeft}>
                      <img className="dark:invert" src={scroll_left} />
                    </button>
                  )}
                </>
              ) : (
                <></>
              )}
              <div className="post_view_img_scroll" ref={containerRef} onScroll={handleScroll}>
                {twig.image.map((image, key) => (
                  <img loading="lazy" src={image} />
                ))}
              </div>

              {twig.image.length > 1 ? (
                <div className="flex w-[550px] items-center justify-center gap-2 absolute bottom-[80px]">
                  {twig.image.map((content, key) => (
                    <p
                      className={` ${
                        key === index
                          ? "transition carousel_active"
                          : "min-w-[6px] min-h-[6px] rounded-full bg-[#838383] dark:bg-[#838383]"
                      }`}
                      key={key}
                    ></p>
                  ))}
                </div>
              ) : (
                <></>
              )}

              {twig.image.length > 1 ? (
                <>
                  {index === twig.image.length - 1 ? (
                    <></>
                  ) : (
                    <button onClick={scrollRight}>
                      <img className="dark:invert" src={scroll_right} />
                    </button>
                  )}
                </>
              ) : (
                <></>
              )}
            </section>

            <section className="twigs-header p-4 w-full">
              <div className="twig-details">
                <Link
                  to={`/someones_page/${user}`}
                  state={{ uid: twig.uid, user: user }}
                >
                  <img src={imgSrc} />
                </Link>

                <div>
                  <Link
                    to={`/someones_page/${user}`}
                    state={{ uid: twig.uid, user: user }}
                  >
                    <h2>{twigUser}</h2>
                  </Link>
                  <p>{twig.time}</p>
                </div>
                <section className="twigs-three-dot ml-auto">
                  <button onClick={() => setThreeOpen(!threeOpen)}>
                    {" "}
                    <img src={three_dot} />{" "}
                  </button>
                  {threeOpen ? (
                    <>
                      <div className="settings-three-dot twigs-setting-open">
                        <div>
                          <section>
                            <div>
                              <img src={save_2} />
                            </div>
                            <p>Save</p>
                          </section>
                          <section>
                            <div>
                              <img src={share_icon} />
                            </div>
                            <p>Share</p>
                          </section>
                          <section>
                            <div>
                              <img src={not_int} />
                            </div>
                            <p>Not interest</p>
                          </section>
                        </div>
                        <div>
                          <section>
                            <div>
                              <img src={download} />
                            </div>
                            <p>Download</p>
                          </section>
                          <section>
                            <div>
                              <img src={report} />
                            </div>
                            <p>Report</p>
                          </section>
                          <section>
                            <div>
                              <img src={why_see} />
                            </div>
                            <p>Why see this?</p>
                          </section>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </section>
              </div>
              <div className="twigs-caption">
                <p>{twig.caption}</p>
                <section>
                  <button
                    onClick={() =>
                      liked ? unlike_update(twigId) : like_update(twigId)
                    }
                  >
                    {liked ? (
                      <img className="w-[28px] h-[25px]" src={love_like} />
                    ) : (
                      <img
                        className="dark:invert w-[28px] h-[25px]"
                        src={empty_like}
                      />
                    )}
                    {twig.react.like.length | 0}
                  </button>
                  {/* <button>
                    <img src={comment_btn} />
                    {twig.comment?.length | 0}
                  </button> */}
                </section>
                <hr className="mt-2" />
              </div>

              <section className="twigs-comments">
                <div className="twigs-comment_text">
                  {twig.comment.length !== 0 ? (
                    twig.comment.map((thisComment, key) => (
                      <EachComment comment={thisComment} twigId={twig.docId} />
                    ))
                  ) : (
                    <p className="text-center text-gray-400">
                      Write something to comment
                    </p>
                  )}
                </div>

                <div className="emoji-picker">
                  {emoji ? (
                    <EmojiPicker
                      height={width <= 500 ? 350 : 400}
                      width={width <= 500 ? width : 400}
                      onEmojiClick={(e) => setComment(comment+e.emoji)}
                    />
                  ) : (
                    <></>
                  )}
                </div>

                <div className="twigs-send-msg">
                  <div>
                    <img src={emoji_icon} onClick={() => setEmoji(!emoji)} />
                    <input 
                    className="text-black"
                      placeholder="Type Comment"
                      onChange={(e) => setComment(e.target.value)}
                      value={comment}
                    />
                  </div>
                  <section>
                    <button onClick={() => postComment()}>
                      <img src={send_icon} width={20} />
                    </button>
                  </section>
                </div>
              </section>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};
