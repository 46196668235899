import React, { useContext, useRef, useState } from "react";
import cross_icon from "../Assets/Home page 1/cross icon.svg";
import crop_icon from "../Assets/Home page 1/crop icon.svg";
import fliter_icon from "../Assets/Home page 1/filter icon.svg";
import galary_icon from "../Assets/Home page 1/galary icon.svg";
import edit_icon from "../Assets/Home page 1/edit twigs.svg";
import photo from "../Assets/Home page 1/story 2.png";
import profile_photo from "../Assets/Home page 1/story 1.png";

import scroll_left from "../Assets/Home page 1/scroll left.svg";
import scroll_right from "../Assets/Home page 1/scroll right.svg";

import { AppContext } from "../Services/Context";

import { storage, database } from "../Services/firebaseconfig";
import {
  doc,
  setDoc,
  addDoc,
  collection,
  updateDoc,
  serverTimestamp,
  arrayUnion,
} from "firebase/firestore";

import aspect_1_1 from "../Assets/Accouonts Page/1_1 aspect.svg";
import aspect_5_4 from "../Assets/Accouonts Page/4_5 aspect.svg";
import aspect_16_9 from "../Assets/Accouonts Page/16_9 aspect.svg";

import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { Image_Cropper, StyledDemo } from "./Image_Cropper";


export const Acc_takes_post_box = (props) => {

    let videoTest;

  const [crop, setCrop] = useState(true);
  const [edit, setEdit] = useState(false);
  const [caption, setCaption] = useState(false);
  const uploadInputRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [croppedImage, setCroppedImage] = useState([]);
  const [aspectRatio, setAspectRatio] = useState(4 / 3);
  const d = new Date();
  const { logData, userData } = useContext(AppContext);

  const [postData, setPostData] = useState({
    caption: "",
    cmnt_privacy: false,
    download: false,
    image: null,
    like_privacy: false,
    location: "",
    tags: "",
    time:
      `${d.getDate()}` +
      " " +
      monthNames[d.getMonth()] +
      " " +
      `${d.getFullYear()}`,
    uid: logData.uid,
  });

  const containerRef = useRef(null);

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 100, behavior: "smooth" }); // Adjust the scroll amount as needed
    }
  };

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -100, behavior: "smooth" }); // Adjust the scroll amount as needed
    }
  };

  //state korte hoibo
  const handleChangeInput = (e) => {
    // setImageFile(URL.createObjectURL(e.target.files[0]))
    // setSelectedFile(e.target.files[0])

    const fileList = e.target.files;

    if (fileList) {
      setSelectedFile(fileList);
      const imageList = Array.from(fileList).map((file) =>
        URL.createObjectURL(file)
      );
      setImageFile(imageList);
    }
  };

  const Nextpg = () => {
    uploadInputRef.current && uploadInputRef.current.click();
  };

  const { userInfo } = useContext(AppContext);

  async function Upload() {
    const {
      caption,
      cmnt_privacy,
      download,
      video,
      like_privacy,
      location,
      tags,
      time,
      uid,
    } = postData;
    // console.log(image);
    await addDoc(collection(database, "Takes"), {
      caption: caption,
      cmnt_privacy: cmnt_privacy,
      download: download,
      video: video,
      like_privacy: like_privacy,
      location: location,
      tags: tags,
      time: time,
      timeStamp: serverTimestamp(),
      uid: uid,
      react: {
        like: 0,
        love: 0,
        care: 0,
        haha: 0,
        wow: 0,
        sad: 0,
        angry: 0,
      },
      comment: [],
    }).then(
      props.setCreateTakes(false),
      // console.log(userData.twigs_num),
      await updateDoc(doc(database, "User Data", `${logData.uid}`), {
        twigs_num: Number(userData.twigs_num + 1),
      }),
      setTimeout(() => {
        window.location.reload();
      }, 2000)
    );
  }
  // console.log(selectedFile);
  async function Continue() {
    const file = selectedFile[0];
    const blob = croppedImage[0];
    // console.log(file);
    if (!file) return;

    const storageRef = ref(storage, `${logData.email}/takes/${file.name}`);

    const uploadTask = uploadBytesResumable(storageRef, file, {
      contentType: "video/mp4",
    });
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // console.log(snapshot);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          videoTest = downloadURL;
          postData.video = downloadURL;
          if (postData.video != null) {
            Upload();
          }
        });
      }
    );

    // setTimeout(() => {
    //     window.location.reload()

    // }, 4000);
  }

  const handleOptionClick = (ratio) => {
    setAspectRatio(ratio);
  };



  return (
<>

<div className="acc-bg-blur">
        <div className="acc-post-box">
          <div className="edit-post-button">
            <button onClick={() => props.setCreateTakes(false)}>
              <img src={cross_icon} />
            </button>
            <p>Edit post</p>
          </div>

          {edit ? (
            <>
              {" "}
              {caption ? (
                <>
                  <div className="final-edit">
                    <div>
                      <div className="final-img-show">
                        {/* {croppedImage.map((image, key) => (
                            <>
                            {console.log(image)}
                          <img src={URL.createObjectURL(image)} />
                            </>
                        ))} */}
                        <video src={imageFile[0]} />
                      </div>
                      <button onClick={() => setCaption(false)}>
                        <img src={edit_icon} />
                      </button>
                    </div>
                    <hr />
                    <div>
                      <div>
                        <img src={userData.img} />
                        <p>{userData.name}</p>
                        <button onClick={() => Continue()}>Done</button>
                      </div>
                      <div className="input-photo acc-twig-post">
                        <textarea
                          onChange={(e) =>
                            setPostData({
                              ...postData,
                              caption: e.target.value,
                            })
                          }
                          placeholder="Write Something"
                        >
                        </textarea>
                        <p>Tag Someone</p>
                        <p>Add Location</p>
                        <section>
                          <p>Turn off comment</p>
                          <label className="relative inline-flex items-center cursor-pointer">
                            <input
                              onChange={(e) =>
                                setPostData({
                                  ...postData,
                                  cmnt_privacy: !postData.cmnt_privacy,
                                })
                              }
                              type="checkbox"
                              value=""
                              className="sr-only peer"
                            />
                            <div className="mr-4 w-14 h-6 bg-gray-500 peer-focus:outline-none rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-8 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BDFA3C] after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                          </label>
                          {/* <input onChange={(e) => setPostData({...postData, cmnt_privacy:!postData.cmnt_privacy})} type="checkbox" id="switch1" /><label for="switch1">Toggle</label> */}
                        </section>
                        <section>
                          <p>Hide the likes of this Twig</p>
                          <label className="relative inline-flex items-center cursor-pointer">
                            <input
                              onChange={(e) =>
                                setPostData({
                                  ...postData,
                                  like_privacy: !postData.like_privacy,
                                })
                              }
                              type="checkbox"
                              value=""
                              className="sr-only peer"
                            />
                            <div className="mr-4 w-14 h-6 bg-gray-500 peer-focus:outline-none rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-8 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BDFA3C] after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                          </label>
                          {/* <input onChange={(e) => setPostData({...postData, like_privacy:!postData.like_privacy})} type="checkbox" id="switch2" /><label for="switch2">Toggle</label> */}
                        </section>
                        <section>
                          <p>Enable to download</p>
                          <label className="relative inline-flex items-center cursor-pointer">
                            <input
                              onChange={(e) =>
                                setPostData({
                                  ...postData,
                                  download: !postData.download,
                                })
                              }
                              type="checkbox"
                              value=""
                              className="sr-only peer"
                            />
                            <div className="mr-4 w-14 h-6 bg-gray-500 peer-focus:outline-none rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-8 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BDFA3C] after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                          </label>
                          {/* <input onChange={(e) => setPostData({...postData, download:!postData.download})} type="checkbox" id="switch3" /><label for="switch3">Toggle</label> */}
                        </section>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="add-photo">
                    {/* <div>
                                <button>
                                    <img src={crop_icon} />
                                    Crop
                                </button>
                                <button>
                                    <img src={fliter_icon} />
                                    Filter
                                </button>
                            </div> */}
                    {imageFile ? (
                      <>
                        {/* {crop ? <>
                                {imageFile.map((image, key) => (
                                    <Image_Cropper img={image} setCaption={setCaption} setCroppedImage={setCroppedImage} croppedImage={croppedImage}/>
                                ))}
                                </>
                            :null} */}

                        <section
                          className="crop-post-scroll"
                          ref={containerRef}
                        >
                          {imageFile.length >1?<>
                            <button className="scroll-btn" onClick={scrollLeft}>
                            <img className="dark:invert" src={scroll_left} />
                          </button>
                          </>:null}
                          
                          <button
                            name="aspectRatio"
                            id="aspectRatio"
                            className="aspect-ratio-select"
                          >
                            <div className="aspect-drop">
                              <button onClick={() => handleOptionClick(1 / 1)}>
                                1:1 <img src={aspect_1_1} />
                              </button>
                              <button onClick={() => handleOptionClick(4 / 5)}>
                                4:5 <img src={aspect_5_4} />
                              </button>
                              <button onClick={() => handleOptionClick(16 / 9)}>
                                16:9 <img src={aspect_16_9} />
                              </button>
                            </div>
                            Crop Ratio
                          </button>
                          
                          {imageFile.map((image, key) => (
                            <>
                            <div className="flex flex-col">
                                <video src={image} className="w-[100px] h-[200px]"/>
                                <button onClick={() => setCaption(true)} > Next Page</button>
                            </div>
                            {/* <Image_Cropper
                              img={image}
                              setCaption={setCaption}
                              setCroppedImage={setCroppedImage}
                              croppedImage={croppedImage}
                              setAspectRatio={setAspectRatio}
                              aspectRatio={aspectRatio}
                            /> */}
                            </>
                          ))}
                          {imageFile.length >1?<>
                            <button className="scroll-btn" onClick={scrollRight}>
                            <img className="dark:invert" src={scroll_right} />
                          </button>
                          </>:null}
                          
                        </section>
                        {/* <div>
                                <img src={imageFile} />
                            </div>
                            <div>
                                <img src={imageFile} />
                                <img src={imageFile} />
                                <img src={imageFile} />
                                <img src={imageFile} />
                            </div> */}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <div className="confirm-btn">
                            <button onClick={() => setCaption(true)}>Done</button>
                        </div> */}
                </>
              )}
            </>
          ) : (
            <div className="select-photo">
              <p>Create New Takes</p>

              <section className="post-box-selected-img" ref={containerRef}>
                {imageFile ? (
                  <>
                  {imageFile.length > 1 ?<>
                    <button onClick={scrollLeft}>
                      <img className="dark:invert" src={scroll_left} />
                    </button>
                    {imageFile.map((image, key) => (
                      <video key={key} src={image} alt={`Image ${key}`} />
                    ))}
                    <button onClick={scrollRight}>
                      <img className="dark:invert" src={scroll_right} />
                    </button>
                  </>:
                  <>
                  <video src={imageFile[0]} alt={`Image`} />
                  </>}
                    
                  </>
                ) : (
                  <img src={galary_icon} />
                )}
              </section>

              {/* <img src={imageFile ? imageFile : galary_icon} /> */}
              <button onClick={() => Nextpg()}>
                <input
                  onChange={handleChangeInput}
                  ref={uploadInputRef}
                  type="file"
                  accept="video/*"
                  hidden
                  multiple
                />
                Select From Computer
              </button>
              {imageFile ? (
                <button onClick={() => setEdit(true)}>Next</button>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </div>

</>
  )
}
