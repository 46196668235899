import React, { useContext, useEffect, useState } from 'react'
import '../Styles/settings_page.css'
import Settings_edit_profile from './Settings_edit_profile'
import Settings_options from './Settings_options'
import Settings_change_password from './Settings_change_password'
import Settings_Push_Notifi from './Settings_Push_Notifi'
import Settings_Email_Notifi from './Settings_Email_Notifi'
import Settings_Frnd_Interact from './Settings_Frnd_Interact'
import Settings_Language from './Settings_Language'
import Settings_Blocked from './Settings_Blocked'
import Settings_Sensitive_Content from './Settings_Sensitive_Content'
import Settings_Deactivate_acc from './Settings_Deactivate_acc'
import Settings_Delete_start from './Settings_Delete_start'
import Settings_Why_Delete from './Settings_Why_Delete'
import Settings_Why_Deactivate from './Settings_Why_Deactivate'
import Settings_Delete_acc from './Settings_Delete_acc'
import { Outlet } from 'react-router-dom'
import Settings_page_mobile from './Settings_page_mobile'
import { AppContext } from '../Services/Context'

export default function Settings_page() {

  const {width} = useContext(AppContext);
  

  return (
<>
    {/* < Settings_edit_profile /> */}
    {/* < Settings_change_password /> */}
    {/* < Settings_Push_Notifi /> */}
    {/* < Settings_Email_Notifi /> */}
    {/* < Settings_Frnd_Interact /> */}
    {/* < Settings_Language /> */}
    {/* < Settings_Blocked /> */}
    {/* < Settings_Sensitive_Content /> */}
    {/* < Settings_Delete_start /> */}
    {/* < Settings_Why_Delete /> */}
    {/* < Settings_Why_Deactivate /> */}
    {/* < Settings_Deactivate_acc /> */}
    {/* < Settings_Delete_acc /> */}
    <div className='settings-mobile-div w-[60%]'>
      <Outlet/>
    </div>
    <hr className="settings-hr home-hr-2"/>
    {width <= 500 ? < Settings_page_mobile /> : < Settings_options />}
    

</>
    )
}
