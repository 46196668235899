import React, { useContext, useRef, useState } from 'react'
import profile_pic from "../Assets/Accouonts Page/profile pic.png"
import link_icon from "../Assets/Home page 1/link.svg"
import g_mail from "../Assets/Home page 1/Social media/Mail.svg"
import whatsapp from "../Assets/Home page 1/Social media/whatsapp.svg"
import linkdin from "../Assets/Home page 1/Social media/linkdin.svg"
import pinterest from "../Assets/Home page 1/Social media/pinterest.svg"
import facebook from "../Assets/Home page 1/Social media/facebook.svg"
import twiter from "../Assets/Home page 1/Social media/twiter.svg"
import tumblr from "../Assets/Home page 1/Social media/tumblr.svg"
import sms from "../Assets/Home page 1/Social media/sms.svg"
import cross_icon from '../Assets/Home page 1/cross icon.svg'

import { AppContext } from '../Services/Context'
import { story_profiles } from '../Home Page/Home_pg_Components'

export const Reel_share = (props, setShareOn) => {
    const [storyProfile, setStoryProfile] = useState(story_profiles)
    const [copied, setCopied] = useState(false)
    const {followings, followers} = useContext(AppContext)

    const textRef = useRef(null);

    const combinedList = followers.concat(followings)
    const uniqueMap = {};
    
    const filteredList = combinedList.filter(item => {
        if (!uniqueMap[item.uid]) {
            uniqueMap[item.uid] = true;
            return true;
        }
        return false;
    });
    
    // console.log(filteredList);

    const talesURL='https://'+window.location.host+`/reel_page/${props.reel_id}`

    const handleCopyClick = () => {
        if (textRef.current) {
          const textToCopy = textRef.current.innerText;
          navigator.clipboard.writeText(textToCopy)
          setCopied(true)
        }
      };
  return (
<>

    <div className="reel-share-box bg-[] dark:bg-[#0b1c1c]">
    <button className='action-cross-btn hover:bg-[#6c6c6ca5] p-2 rounded-full' onClick={() => props.setShareOn(false)}><img src={cross_icon} /></button>
        <section>
            <hr/>
            <div>
                <p>Share To</p>
                <hr/>
                <input placeholder='Search By Name'/>
            </div>
        </section>
        <section>
            <div>
                {filteredList.map((story, key) => (
                    <div key={key}>
                        <img src={story.img} alt={story.id}/>
                        <p>{story.name}</p>
                    </div>
                ))}
            </div>
            <button>Send</button>
            <div>
                <p ref={textRef}>{talesURL}</p>
                <button onClick={handleCopyClick}><img src={link_icon}/>{copied ? 'Copied' : 'Copy'}</button>
            </div>
            <hr/>
        </section>
        <section>
            <button><img src={g_mail}/></button>
            <button><img src={whatsapp}/></button>
            <button><img src={linkdin}/></button>
            <button><img src={pinterest}/></button>
            <button><img src={facebook}/></button>
            <button><img src={twiter}/></button>
            <button><img src={tumblr}/></button>
            <button><img src={sms}/></button>
        </section>
    </div>

</>
  )
}
