import React, { useContext, useEffect } from 'react'
import SideBar from './Components/SideBar/SideBar'
import { Outlet, useNavigate } from 'react-router-dom'
import Mobile_navbar from './Components/SideBar/Mobile_navbar'
import Home_page from './Components/Home Page/home_page'
import { AppContext } from './Components/Services/Context'
import Loader from './Components/Register/Loader'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css';

export default function Layout() {

  const {loading} = useContext(AppContext)

  const navigate = useNavigate()

  const url = window.location.pathname
  // console.log(url);

  // useEffect(()=>{
  //   if(url === "/"){
  //     navigate("/Home_Page")
  //   }
  // },[])

  if(loading===true){
    return(
        <Loader/>
    )
}else{

  return (
<>
{/* {url === "/" || url === "/Home_Page" ? <Home_page/>: */}
<ToastContainer newestOnTop/>
<div style={{display:'flex'}}>
      < SideBar />
      <section className='mobile-nav-display'>
          <div className='mobile-nav-outlet'>
            < Mobile_navbar />
          </div>
      </section>
      < Outlet />
    </div>
    {/* } */}
    

</>
  )
}
}
