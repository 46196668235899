import React, { useEffect, useState } from 'react'
import cross_icon from '../Assets/Home page 1/cross icon.svg'
import { database } from '../Services/firebaseconfig';
import { doc, onSnapshot } from 'firebase/firestore';
import { Link } from 'react-router-dom';


export const Mutual_view = ({setMutual, array, name}) => {
  const [friendsData, setFriendsData] = useState([])
  const uniqueMap = {};
  // console.log(array);

  useEffect(() => {
    array.forEach(async (id) => {
      // console.log(follower);
      const q = doc(database, "User Data", id);
      onSnapshot(q, (data) => {
        //   console.log(data.data());
        setFriendsData((friendsData) => [
          ...friendsData,
          { ...data.data(), uid: data.id },
        ]);
      });
    })
  },[])

  const filteredList = friendsData.filter(item => {
    if (!uniqueMap[item.uid]) {
        uniqueMap[item.uid] = true;
        return true;
    }
    return false;
});
console.log(filteredList)
  return (
<>
<div className="acc-bg-blur">
<button className='action-cross-btn hover:bg-[#6c6c6ca5] p-2 rounded-full z-10' onClick={() => setMutual(false)}><img src={cross_icon} /></button>

<div className='chat-friends-main w-[100%] md:w-[50%]'>
  <div className='combine-friends-sec border-2 border-b-transparent bg-[#38424B] dark:bg-[#141414cc] dark:border-[#A7DD35] dark:border-b-transparent'>
    <section className='chat-my-friends'>
      <p>{name}</p>
      <div className='my_friends'>

      {filteredList?.map((online, key) => (
        <a href={`/someones_page/${online.user_name}`}>
            <img src={online.img}/>
            <p className='text-black dark:text-white'>{online.name}</p>
        </a>  
        ) )}

      </div>    
    </section>
  </div>
</div>

</div>
</>
  )
}
