import React, { useContext, useState } from 'react'

import cross_icon from '../Assets/Home page 1/cross icon.svg'
import cover_img from '../Assets/Settings Page/cover img.jpg'
import profile_img from '../Assets/Settings Page/profile pic.png'
import camera_icon from '../Assets/Settings Page/Camera icon.svg'
import change_profile from '../Assets/Settings Page/profile change.svg'
import change_cover from '../Assets/Settings Page/cover change.svg'
import placeholder from '../Assets/Accouonts Page/Placeholder image.png'

import { AppContext } from '../Services/Context'
import { addDoc, deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore'
import { database, storage } from '../Services/firebaseconfig'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'

export default function Settings_edit_profile() {

  const {userData, logData} = useContext(AppContext)

  const [selectedFileCover, setSelectedFileCover] = useState(null);
  const [selectedFileDp, setSelectedFileDp] = useState(null);
  const [updateImgCover, setUpdateImgCover] = useState()
  const [updateImgDp, setUpdateImgDp] = useState()
  const [changeField, setChangeFields] = useState({
    name:"",
    user_name:"",
    bio:"",
    img:null,
    cover_img:null
  })
  const [changeImg, setChangeImg] = useState(false)

  // console.log(changeField);

  // Photo Upload part
  if (changeImg === false) {
    changeField.cover_img = null
  }

  async function change() {
    const {name, user_name, bio} = changeField
    await updateDoc(doc(database, 'User Data', `${logData.uid}`), {
      name : changeField.name === ''? userData.name:changeField.name,
      user_name : changeField.user_name === ''? userData.user_name:changeField.user_name,
      bio : changeField.bio === ''? userData.bio:changeField.bio,
    }
    )
    if(changeField.user_name !== ''){
     setDoc(doc(database, "Uid", changeField.user_name),{
        ["uid"]: logData.uid
      }).then
      (deleteDoc(doc(database, "Uid", userData.user_name)))
    }
  }

  return (
<>

<div className="settings-edit-profile">
  {changeImg ? <SelectProfileImage setChangeImg={setChangeImg} updateImgCover={updateImgCover} updateImgDp={updateImgDp} setUpdateImgCover={setUpdateImgCover} setUpdateImgDp={setUpdateImgDp} setSelectedFileCover={setSelectedFileCover} selectedFileCover={selectedFileCover} setSelectedFileDp={setSelectedFileDp} selectedFileDp={selectedFileDp} userData={userData} changeField={changeField} logData={logData} change={change}/> : <></>}

    <img src={userData.cover_img ? userData.cover_img : placeholder}/>
    {/* <input type="file" onChange={e => {setSelectedFile(e.target.files[0]); setType('cover')} }/> */}
    <p className="title text-white dark:text-black">Edit Profile</p>
    <div className="profile-img">
        <img src={userData.img ? userData.img : placeholder}/>
        <button onClick={() => setChangeImg(true)}><img src={camera_icon}/></button>
        {/* <input type="file" className="edit-profile-cam" onChange={e => {setSelectedFile(e.target.files[0]); setType('dp')}}/> */}
    </div>
    <div className="edit-input">
        <p className='text-black dark:text-white '>Name</p>
        <input className='border-b-2 pb-3  border-transparent focus:border-transparent focus:border-b-black border-b-black dark:border-b-white dark:focus:border-b-white dark:caret-white dark:text-white' onChange={(e) => setChangeFields({...changeField, name:e.target.value})} type="text" placeholder={userData.name}/>

        <p className='text-black dark:text-white'>Username</p>
        <input className='border-b-2 pb-3 border-transparent focus:border-transparent focus:border-b-black border-b-black dark:border-b-white dark:focus:border-b-white dark:caret-white dark:text-white' onChange={(e) => setChangeFields({...changeField, user_name:e.target.value})} type="text" placeholder={userData.user_name}/>

        <p className='text-black dark:text-white'>About</p>
        <input className='border-b-2 pb-3  border-transparent focus:border-transparent focus:border-b-black border-b-black dark:border-b-white dark:focus:border-b-white dark:caret-white dark:text-white' onChange={(e) => setChangeFields({...changeField, bio:e.target.value})} type="text" placeholder={userData.bio}/>
        
        <button className='text-black dark:text-white'>Create Avatar</button>
        <div>
            <button onClick={() => change()}>Done</button>
        </div>
    </div>
</div>

</>
  )
}

const SelectProfileImage = (props) => {

  const [type, setType] = useState()

  function Continue_dp() {
    const file=props.selectedFileDp
    if (!file) 
    return; 
    const storageRef = ref(storage, `${props.userData.email}/images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // console.log(snapshot);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          // await setDoc(collectionRef, {
          //   imageurl: downloadURL,
          // });
          props.changeField.img = downloadURL
        }).then(async () => {
          await updateDoc(doc(database, 'User Data', `${props.logData.uid}`), {
            img : props.changeField.img === null? props.userData.img:props.changeField.img,
          }
          )
        })
      }
    );
}
 
function Continue_cover() {
    const file=props.selectedFileCover
    if (!file) 
    return; 
    const storageRef = ref(storage, `${props.userData.email}/images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // console.log(snapshot);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          // await setDoc(collectionRef, {
          //   imageurl: downloadURL,
          // });
          props.changeField.cover_img = downloadURL
        }).then( async () => {
          await updateDoc(doc(database, 'User Data', `${props.logData.uid}`), {
            cover_img : props.changeField.cover_img === null? props.userData.cover_img:props.changeField.cover_img,
          }
          )
        })

      }
    );
}



async function Update_confirm() {
  // if (props.selectedFile != null && type === 'dp') {
  //   Continue_dp()
  // }
  // else if (props.selectedFile != null && type === 'cover') {
  //   Continue_cover()  
  // }
  Continue_dp()
  Continue_cover()
  
  // await updateDoc(doc(database, 'User Data', `${props.logData.uid}`), {
  //   cover_img : props.changeField.cover_img === null? props.userData.cover_img:props.updateImgCover,
  //   img : props.changeField.img === null? props.userData.img:props.updateImgDp,
  // }
  // )
  props.setChangeImg(false)

}
  return(
    <div className='acc-bg-blur'>

      <div className='acc-post-box acc-edit-post-box'>
        <div className="edit-post-button">
            <button onClick={() => {props.setChangeImg(false); props.setUpdateImgCover(null); props.setUpdateImgDp(null)}}><img src={cross_icon} /></button>
            <p>Edit Profile Image</p>
        </div>
        
          <div className='edit-select-image'>
            <section>
              <img src={ props.updateImgCover != null ? props.updateImgCover : props.userData.cover_img != null ? props.userData.cover_img : change_cover } />
              <button className='relative-edit-btn'>
                Add Cover Image
                <input type="file" className='edit-cover-img-input' onChange={e => {props.setSelectedFileCover(e.target.files[0]); setType('cover'); props.setUpdateImgCover(URL.createObjectURL(e.target.files[0]))} }/>
              </button>
            </section>
            <section>
              <img src={props.updateImgDp != null ? props.updateImgDp : props.userData.img != null ? props.userData.img : change_profile }/>
              <button className='relative-edit-btn'>
              Add Profile Image
                <input type="file" className='edit-profil-img-input' onChange={e => {props.setSelectedFileDp(e.target.files[0]); setType('dp'); props.setUpdateImgDp(URL.createObjectURL(e.target.files[0]))} }/>
              </button>
            </section>


            {/* <section>
              <img src={ props.updateImgCover != null ? props.updateImgCover : props.userData.cover_img != null ? props.userData.cover_img : change_cover } />
              <input type="file" className='edit-cover-img-input' onChange={e => {props.setSelectedFileCover(e.target.files[0]); setType('cover'); props.setUpdateImgCover(URL.createObjectURL(e.target.files[0]))} }/>
            </section>
            <section>
              <img src={props.updateImgDp != null ? props.updateImgDp : props.userData.img != null ? props.userData.img : change_profile }/>
              <label for="avatar" className='edit-profil-img-input'>Choose a profile picture:</label>
              <input type="file" id="avatar" name="avatar" accept="image/png, image/jpeg" />
            </section> */}
          </div>

        <section className='select-img-done'>
          <button onClick={() => Update_confirm()}>Done</button>
        </section>

      </div>

    </div>
  );
};