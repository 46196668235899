import React, { useContext, useState } from 'react'
import profile_img from '../Assets/Accouonts Page/profile pic.png'
import three_dot from "../Assets/Home page 1/three dot.svg"
import empty_like from "../Assets/Home page 1/empty like.svg"
import love_like from '../Assets/Home page 1/love like.svg'
import comment_btn from "../Assets/Home page 1/comment.svg"
import share_btn from '../Assets/Home page 1/share 2.svg'

import cross_icon from '../Assets/Home page 1/cross icon.svg'
import comment_profile from '../Assets/Home page 1/story 1.png'
import reply_icon from '../Assets/Home page 1/reply icon.svg'
import mic_icon from '../Assets/Messages assets/mic icon.svg'
import emoji_icon from '../Assets/Messages assets/emoji .svg'
import send_icon from '../Assets/Messages assets/send icon.svg'
import { AppContext } from '../Services/Context'
import { useLocation } from 'react-router-dom'
import { doc, getDoc } from 'firebase/firestore'
import { database } from '../Services/firebaseconfig'

export default function Acc_Mobile_Post() {
    const [liked, setLiked] = useState(false)
    const [cmnton, setCmnton] = useState(false)
    const [imgSrc, setImgSrc] = useState(null)
    const [twigUser, setTwigUser] = useState()

    const state = useLocation()
    console.log(state.state.feed);

    async function setUserImage(uid) {
        const docRef = doc(database, "User Data", `${uid}`);
        const docSnap = await getDoc(docRef);
        const profile_img = docSnap.data().img;
        setImgSrc(profile_img);
        return profile_img;
    }

    async function setUserName(uid) {
        const docRef = doc(database, "User Data", `${uid}`);
        const docSnap = await getDoc(docRef);
        const profile_img = docSnap.data().name;
        setTwigUser(profile_img);
        return profile_img;
    }

    setUserImage(state.state.feed.uid)
    setUserName(state.state.feed.uid)

    const {userData} = useContext(AppContext)
  return (
<>

<div className='acc-mob-scroll h-[100vh] w-full'>
    <div className='acc-mob-name'>
      <img src={imgSrc} />
      <div>
        <p className='dark:text-white'>{twigUser}</p>
        <p className='dark:text-white'>{state.state.feed.time}</p>
      </div>
       <button><img className='dark:invert' src={three_dot} /></button>
    </div>
    <div className='mob-post-img'>
        {state.state.feed.image?.map((img, key) => (
            <img src={img} key={key}/>
        ))}
    </div>
    <div className='mob-caption dark:text-white'>
        <p>{state.state.feed.caption}</p>
    </div>
    <div className="content-footer mob-action-btn">
        <button onClick={() => setLiked(!liked)}> {liked ? <img src={love_like} /> : <img className='dark:invert' src={empty_like}/>}</button>
        <p className='text-black dark:text-white'>{state.state.feed.react.like.length}</p>
        <button onClick={() => setCmnton(!cmnton)}><img src={comment_btn} /></button>
        <p className='text-black dark:text-white'>{state.state.feed.comment.length}</p>
        <button><img src={share_btn} /></button>
        {/* <p className='text-black dark:text-white'>2</p> */}
    </div>

    {cmnton ? 
    <div className="comment_box mob-cmnt">
            <button className='action-cross-btn reel-cmnt-close hover:bg-[#6c6c6ca5] p-2 rounded-full' onClick={() => setCmnton(!cmnton)}><img src={cross_icon} /></button>
            <div className="comment_top pt-2">
                <hr />
                <p>Comments</p>
                <hr />
            </div>
            <div className="comment_text">
                <div>
                    <div>
                        <div>
                            <img src={comment_profile} />
                            <p>Clara Albert</p>
                            <p>1 min ago</p>
                        </div>
                        <p>Babu ne thana thaya 🙉 👺</p>
                        <div>
                            <img src={love_like} />
                            <p>1.2k</p>
                            <hr />
                            <img src={reply_icon} />
                            <p>Reply</p>
                        </div>
                    </div>
                </div>

                <div className="reply_comment">
                    <em></em>
                    <div className="reply-comment-text">
                        <div>
                            <div>
                                <div>
                                    <img src={comment_profile} />
                                    <p>Clara Albert</p>
                                    <p>1 min ago</p>
                                </div>
                                <p>Babu ne thana thaya 🙉🙉🙉</p>
                                <div>
                                    <img src={love_like} />
                                    <p>1.2k</p>
                                    <hr />
                                    <img src={reply_icon} />
                                    <p>Reply</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="msg-text chat_send">
                <div>
                    <img src={emoji_icon} />
                    <input placeholder="Type Message" />
                    <img src={mic_icon} />
                </div>
                <section>
                    <img src={send_icon} />
                </section>
            </div>
    </div>
    : <></>}

</div>

</>
  )
}
