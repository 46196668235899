import React, { useContext, useEffect, useState } from "react";
import like_btn from "../Assets/Someone Acc assets/like_btn.svg";
import plus_btn from "../Assets/Someone Acc assets/plus_btn.svg";
import tick_btn from "../Assets/Someone Acc assets/icon _circle tick_.svg";
import msg_btn from "../Assets/Someone Acc assets/message_btn.svg";
import up_btn from "../Assets/Someone Acc assets/up_btn.svg";
import down_btn from "../Assets/Someone Acc assets/down btn.svg";
import { auth, database } from "../Services/firebaseconfig";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { AppContext } from "../Services/Context";
import { ChatContext } from "../Services/ChatContext";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";

export default function Someone_Acc_abouts({
  userData,
  state,
  accSugest,
  setAccSugest,
  uidFromURL,
  thisUser
}) {
  const [followChange, setFollowChange] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);

  const { logData } = useContext(AppContext)
  const { dispatch } = useContext(ChatContext)

  const navigate = useNavigate()

  // console.log(logData.uid)
  // console.log(userData.followers.length);

  async function follow() {
    const currUser = auth.currentUser;
    if ((state == null) | undefined) {

      if (userData.lock === true) {
        await updateDoc(doc(database, "User Data", `${uidFromURL}`), {
          follow_requests: arrayUnion(currUser.uid),
        });
        const followNotification = { "user": `${thisUser.name}`, "action": "requested to follow you", "image": `${thisUser.img}`, "uid": `${logData.uid}`, "timestamp":Date.now()  }
        await setDoc(doc(database, "notifications", `${uidFromURL}`), {
          notifications: arrayUnion(followNotification),
        }, { merge: true });
      } else {
        await updateDoc(doc(database, "User Data", `${uidFromURL}`), {
          followers: arrayUnion(currUser.uid),
        });
        const followNotification = { "user": `${thisUser.name}`, "action": "started following you", "image": `${thisUser.img}`,"uid": `${logData.uid}`, "timestamp":Date.now() }
        await setDoc(doc(database, "notifications", `${uidFromURL}`), {
          notifications: arrayUnion(followNotification),
        }, { merge: true });
        await updateDoc(doc(database, "User Data", `${currUser.uid}`), {
          following: arrayUnion(uidFromURL),
        });
        setFollowChange(true);
      }
    } else {
      if(userData.lock === true){
        await updateDoc(doc(database, "User Data", `${state.uid}`), {
          follow_requests: arrayUnion(currUser.uid),
        });
        const followNotification = { "user": `${thisUser.name}`, "action": "requested to follow you", "image": `${thisUser.img}`,"uid": `${logData.uid}`, "timestamp":Date.now() }
        await setDoc(doc(database, "notifications", `${state.uid}`), {
          notifications: arrayUnion(followNotification),
        }, { merge: true });
      }else{
        await updateDoc(doc(database, "User Data", `${state.uid}`), {
          followers: arrayUnion(currUser.uid),
        });
        const followNotification = { "user": `${thisUser.name}`, "action": "started following you", "image": `${thisUser.img}`,"uid": `${logData.uid}`, "timestamp":Date.now()}
        await setDoc(doc(database, "notifications", `${state.uid}`), {
          notifications: arrayUnion(followNotification),
        }, { merge: true });
        await updateDoc(doc(database, "User Data", `${currUser.uid}`), {
          following: arrayUnion(state.uid),
        });
        setFollowChange(true);
      }
    }
  }
  async function unfollow() {
    const currUser = auth.currentUser;
    if ((state == null) | undefined) {
      await updateDoc(doc(database, "User Data", `${uidFromURL}`), {
        followers: arrayRemove(currUser.uid),
      });
      await updateDoc(doc(database, "User Data", `${currUser.uid}`), {
        following: arrayRemove(uidFromURL),
      });
      setFollowChange(false);
    } else {
      await updateDoc(doc(database, "User Data", `${state.uid}`), {
        followers: arrayRemove(currUser.uid),
      });
      await updateDoc(doc(database, "User Data", `${currUser.uid}`), {
        following: arrayRemove(state.uid),
      });
      setFollowChange(false);
    }
  }

  useEffect(() => {
    const currUser = auth.currentUser;
    if (userData.followers) {
      if (userData.followers.includes(currUser.uid)) {
        setFollowChange(true);
      } else {
        setFollowChange(false);
      }
    }
  }, []);

  // console.log(userData.lock && userData.following.includes(logData.uid))

  const handleSelect = async (someone) => {

    if(!someone.lock || (someone.lock && someone.following.includes(logData.uid))){

      const combinedId = logData.uid > uidFromURL ? logData.uid + uidFromURL : uidFromURL + logData.uid;
      // console.log(combinedId)
      try {
        const res = await getDoc(doc(database, "chats", combinedId));
        if (!res.exists()) {
          await setDoc(doc(database, "chats", combinedId), { messages: [] });
  
          await updateDoc(doc(database, "userChats", logData.uid), {
            [`${combinedId}.userInfo`]: {
              uid: uidFromURL,
              displayName: someone.name,
              photoURL: someone.img,
            },
            [`${combinedId}.date`]: serverTimestamp(),
          });
  
          await updateDoc(doc(database, "userChats", uidFromURL), {
            [`${combinedId}.userInfo`]: {
              uid: logData.uid,
              displayName: thisUser.name,
              photoURL: thisUser.img,
            },
            [`${combinedId}.date`]: serverTimestamp(),
          });
        }
  
        dispatch({ type: "CHANGE_USER", payload: someone });
        // console.log(combinedId)
        navigate(`/message_page/chat/${combinedId}`);
      } catch (err) {
        console.error("Error selecting chat:", err);
      }
    }else{
      toast('Can not send message to lock profiles unless they follow you!', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    })
    }
    
  };

  return (
    <>
      <div className="someone-acc-about-sec">
        <div className="someone-about-description">
          <div>
            <h2 className="dark:text-white">Abouts</h2>
            <p className="dark:text-white">{userData.bio}</p>
          </div>
          {/* <button><img src={like_btn}/>50</button> */}

          <div className="action-btn">
            <button onClick={() => (followChange ? unfollow() : follow())}>
              <img src={followChange ? tick_btn : plus_btn} />
            </button>
            <button onClick={() => handleSelect(userData)}>
              <img src={msg_btn} />
            </button>
            <button onClick={() => setAccSugest(!accSugest)}>
              {accSugest ? <img src={up_btn} /> : <img src={down_btn} />}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
