import React, { useContext, useState } from "react";
import profile_img from "../Assets/Messages assets/story 1.png";
import msg_logo from "../Assets/Messages assets/msg icon.svg";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { database } from "../Services/firebaseconfig";
import { AppContext } from "../Services/Context";
import { Link, useNavigate } from "react-router-dom";

export default function MiddleSec({ userData }) {
  const [list, setList] = useState(false);
  const { followers, logData } = useContext(AppContext);

  const navigate = useNavigate();

  // console.log(followers);

  const handleSelect = async (follower) => {
    const combinedId =
      logData.uid > follower.uid
        ? logData.uid + follower.uid
        : follower.uid + logData.uid;
    // console.log(combinedId);
    try {
      const res = await getDoc(doc(database, "chats", combinedId));
      // console.log(res.exists());
      // console.log(combinedId);
      if (!res.exists()) {
        await setDoc(
          doc(database, "chats", combinedId),
          { messages: [] },
          { merge: true }
        );

        await updateDoc(doc(database, "userChats", logData.uid), {
          [combinedId + ".userInfo"]: {
            uid: follower.uid,
            displayName: follower.name,
            photoURL: follower.img,
          },
          [combinedId + ".date"]: serverTimestamp(),
        })
        .then(
          // console.log("success")
          );

        await updateDoc(doc(database, "userChats", follower.uid), {
          [combinedId + ".userInfo"]: {
            uid: logData.uid,
            displayName: userData.name,
            photoURL: userData.img,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        navigate(`/message_page/chat/${combinedId}`);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <>
      <div className="home-middle-sec">
        <div className="profile-head">
          <section>
            <img src={userData ? userData.img : null} />
            <p>{userData ? userData.user_name : ""}</p>
          </section>
        </div>
        <hr />

        <div className="middle-second-sec">
          <div>
            <img src={msg_logo} />
          </div>
          <p>
            Connect to new people and share your views{" "}
            {/* {followers.length != 0 ? followers[1].name : null} */}
          </p>
          <section>
            <Link to="/message_page/friends">
              <button>Send Message</button>
            </Link>
            {/* <button onClick={() => setList(true)}>Send messages</button> */}
            {/* <button>Send yourself</button> */}
          </section>

          {/* <section>
            {followers.length != 0 && list ? (
              followers.map((follower, key) => (
                <ul>
                  <li>
                    <div onClick={() => handleSelect(follower)}>
                      <h1>{follower.name}</h1>
                      <img src={follower.img} alt="" />
                    </div>
                  </li>
                </ul>
              ))
            ) : (
              <></>
            )}
          </section> */}
          
        </div>
      </div>
    </>
  );
}
