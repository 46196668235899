export let groups = [
    {
        "id":"1",
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Food Community"
    },
    {
        "id":"2",
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Food lover"
    }    
]

export let onlines = [
    {
        "id":"1",
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"SomeOne",
        "indicator":"online-indicator"
    },
    {
        "id":"2",
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"SomeOne",
        "indicator":"online-indicator"
    },
    {
        "id":"3",
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"I Don't Know",
        "indicator":"online-time",
        "time":"1 hr ago"
    },
    {
        "id":"4",
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Food Community",
        "indicator":"online-time",
        "time":"5 min ago"
    }
]

export let suggestions = [
    {
        "id":"1",
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"SomeOne",
        "btn":"Follow"
    },
    {
        "id":"2",
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"NoOne",
        "btn":"Followed"
    },
    {
        "id":"3",
        "src":require("../Assets/Home page 1/story 1.png"),
        "name":"Nucckle Head",
        "btn":"Follow"
    }
]

export let stories = [
    {
        "id":"1",
        "src":require("../Assets/Home page 1/story 1.png"),
    },
    {
        "id":"2",
        "src":require("../Assets/Home page 1/story 1.png"),    
    },
    {
        "id":"3",
        "src":require("../Assets/Home page 1/story 1.png"),    
    },
    {
        "id":"4",
        "src":require("../Assets/Home page 1/story 1.png"),    
    },
    {
        "id":"5",
        "src":require("../Assets/Home page 1/story 1.png"),
    },
    {
        "id":"6",
        "src":require("../Assets/Home page 1/story 1.png"),
    },
    {
        "id":"7",
        "src":require("../Assets/Home page 1/story 1.png"),
    },
    {
        "id":"7",
        "src":require("../Assets/Home page 1/story 1.png"),
    },
    {
        "id":"7",
        "src":require("../Assets/Home page 1/story 1.png"),
    },
    {
        "id":"7",
        "src":require("../Assets/Home page 1/story 1.png"),
    },
    {
        "id":"7",
        "src":require("../Assets/Home page 1/story 1.png"),
    },
]

// Content Box

export let contents = [
    {
        "id":"1",
        "three_dot":require("../Assets/Home page 1/three dot.png"),
        "save_btn":require("../Assets/Home page 1/save icon.png"),
        "like_btn":require("../Assets/Home page 1/like.png"),
        "comment_btn":require("../Assets/Home page 1/comment.png"),
        "share_btn":require("../Assets/Home page 1/share.png"),

        "profile_img":require("../Assets/Home page 1/story 1.png"),
        "profile_name":"SomeOne",
        "post_time":"15 min ago",
        "post_img":require("../Assets/Home page 1/post img.png"),
        "caption":"Beautiful",
        "likes":"122",
        "comments":"20"
    },
    {
        "id":"2",
        "three_dot":require("../Assets/Home page 1/three dot.png"),
        "save_btn":require("../Assets/Home page 1/save icon.png"),
        "like_btn":require("../Assets/Home page 1/like.png"),
        "comment_btn":require("../Assets/Home page 1/comment.png"),
        "share_btn":require("../Assets/Home page 1/share.png"),

        "profile_img":require("../Assets/Home page 1/story 1.png"),
        "profile_name":"SomeOne",
        "post_time":"15 min ago",
        "post_img":require("../Assets/Home page 1/post img.png"),
        "caption":"Beautiful",
        "likes":"122",
        "comments":"20"
    },
    {
        "id":"3",
        "three_dot":require("../Assets/Home page 1/three dot.png"),
        "save_btn":require("../Assets/Home page 1/save icon.png"),
        "like_btn":require("../Assets/Home page 1/like.png"),
        "comment_btn":require("../Assets/Home page 1/comment.png"),
        "share_btn":require("../Assets/Home page 1/share.png"),

        "profile_img":require("../Assets/Home page 1/story 1.png"),
        "profile_name":"SomeOne",
        "post_time":"15 min ago",
        "post_img":require("../Assets/Home page 1/post img.png"),
        "caption":"Beautiful",
        "likes":"122",
        "comments":"20"
    },
]

export let news = [
    {
        "id":"1",
        "news_source":"india.com",
        "news_time":"8 hr ago",

        "thumbs_up":require("../Assets/Home page 1/thumbs up btn.png"),
        "up_count":"20k",        
        "thumbs_down":require("../Assets/Home page 1/thumbs down btn.png"),
        "down_count":"20k",

        "post_time":"Now",
        "news_caption":"Something cheasy",
        "news_img":require("../Assets/Home page 1/news 1.png")
    },
    {
        "id":"2",
        "news_source":"india.com",
        "news_time":"8 hr ago",

        "thumbs_up":require("../Assets/Home page 1/thumbs up btn.png"),
        "up_count":"20k",        
        "thumbs_down":require("../Assets/Home page 1/thumbs down btn.png"),
        "down_count":"20k",

        "post_time":"Now",
        "news_caption":"Pathan Box Office Collection .......",
        "news_img":require("../Assets/Home page 1/news 1.png")
    },
    {
        "id":"3",
        "news_source":"india.com",
        "news_time":"1 min ago",

        "thumbs_up":require("../Assets/Home page 1/thumbs up btn.png"),
        "up_count":"20k",        
        "thumbs_down":require("../Assets/Home page 1/thumbs down btn.png"),
        "down_count":"20k",

        "post_time":"9:30 PM",
        "news_caption":"OHHHH NOOOOO",
        "news_img":require("../Assets/Home page 1/news 1.png")
    }
]
export let comment = [
    {
        "uid":"111",
        "text":"SOMETHING JUTSU"
    },
    {
        "uid":"112",
        "text":"SOMETHING JUTSU 2"
    },
    {
        "uid":"113",
        "text":"SOMETHING JUTSU 3"
    },
    {
        "uid":"114",
        "text":"SOMETHING JUTSU 4"
    },
    {
        "uid":"115",
        "text":"SOMETHING JUTSU 5"
    }
]

export let story_profiles = [
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha Bhattacharjee"
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Yash Raj Ghosh"
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha"
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha"
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha"
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha"
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha"
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha"
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha"
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha"
    },
]

export let notifications = [
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
    {
        "src":require("../Assets/Accouonts Page/profile pic.png"),
        "name":"Tirtha_Bhatta",
        "action":"Started Following You",
        "time":"2years ago",
    },
]