import React, { useContext, useEffect, useState } from "react";
import cover_img from "../Assets/Messages assets/eren wallpaper.jpeg";
import plus_btn from "../Assets/Messages assets/plus btn.svg";

import { messages } from "./message_components.js";
import { Link } from "react-router-dom";
import { AppContext } from "../Services/Context.js";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { database } from "../Services/firebaseconfig.js";
import { ChatContext } from "../Services/ChatContext.js";

import { format } from "date-fns";

export default function Last_sec({ userData, logData, allmessages }) {
  const { dispatch } = useContext(ChatContext);
  const { width } = useContext(AppContext);

  // useEffect(() => {
  //   const getChats = () => {
  //     const unsub = onSnapshot(
  //       doc(database, "userChats", logData.uid),
  //       (doc) => {
  //         setAllMessages(doc.data());
  //       }
  //     );
  //     return () => {
  //       unsub();
  //     };
  //   };
  //   logData.uid && getChats();
  // }, [logData.uid]);





  const setRead = async (chatId, text) => {
    await updateDoc(doc(database, "userChats", logData.uid), {
      [chatId + ".lastMessage"]: {
        read: true,
        text: text
      },
    });
  };

  const handleSelect = (u, chatId, text) => {
    dispatch({ type: "CHANGE_USER", payload: u });
    setRead(chatId, text);
  };

  const formatTimestamp = (timestamp) => {

    if (!timestamp || !timestamp.seconds) {
      // Return a default value or empty string for null or invalid timestamp
      return "";
    }

    const messageDate = new Date(timestamp?.seconds * 1000);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    // Check if it's today
    const isToday = now.toDateString() === messageDate.toDateString();
    // Check if it's yesterday
    const isYesterday = yesterday.toDateString() === messageDate.toDateString();

    if (isToday) {
      // Format time as HH:MM AM/PM
      return format(messageDate, "p"); // p => 10:00 AM/PM format
    } else if (isYesterday) {
      return "Yesterday";
    } else {
      // Format as DD/MM/YYYY for older messages
      return format(messageDate, "dd/MM/yy");
    }
  };

  // console.log(allmessages);


  return (
    <>
      <div className="home-last-sec">
        <img
          className="home-cover-img"
          src={userData ? userData.cover_img : null}
        />
        <div className="msg-head">
          <p>Messages</p>
          <Link to="/message_page/friends">
            <img src={plus_btn} />
          </Link>
        </div>

        <div className="messages-sec">
          {width <= 500 ? (
            <>
              {Object.entries(allmessages)?.map((chat) => (
                <div
                  className="incomming-msg"
                  key={chat[0]}
                  onClick={() => handleSelect(chat[1].userInfo)}
                >
                  <Link to={`/msg/chat/${chat[0]}`} key={chat[0]}>
                    <img src={chat[1].userInfo?.photoURL} />
                    <div>
                      <h4>{chat[1].userInfo?.displayName}</h4>
                      <p
                        className={`${chat[1].lastMessage?.read
                            ? "font-normal"
                            : "font-bold"
                          }`}
                      >
                        {chat[1].lastMessage?.text.length <= 10 ? chat[1].lastMessage?.text : chat[1].lastMessage?.text.slice(0, 10) + '...'}
                      </p>
                    </div>
                    <div>
                      <p>{formatTimestamp(chat[1]?.date)}</p>
                    </div>
                    {chat[1].lastMessage?.read === false ? <p className="msg-indicator"></p> : null}
                    {/* <p className={message.class}>{message.unseen_num}</p> */}
                  </Link>
                </div>
              ))}
            </>
          ) : (
            <>
              {Object.entries(allmessages)?.map((chat) => (
                <div className="incomming-msg" key={chat[0]}>

                  <Link to={`/message_page/chat/${chat[0]}`} key={chat[0]}
                    onClick={() => handleSelect(chat[1].userInfo, chat[0], chat[1].lastMessage?.text)}
                  >
                    <img src={chat[1].userInfo?.photoURL} />
                    <div>
                      <h4>{chat[1].userInfo?.displayName}</h4>
                      <p
                        className={`${chat[1].lastMessage?.read
                            ? "font-normal"
                            : "font-bold"
                          }`}
                      >
                        {chat[1].lastMessage?.text.length <= 30 ? chat[1].lastMessage?.text : chat[1].lastMessage?.text.slice(0, 30) + '...'}
                      </p>
                    </div>
                    <div>
                      <p>{formatTimestamp(chat[1]?.date)}</p>
                    </div>
                    {/* <p className={message.class}>{message.unseen_num}</p> */}
                    {chat[1].lastMessage?.read === false ? <p className="msg-indicator"></p> : null}
                  </Link>
                </div>
              ))}
            </>
          )}

          {/* {messages.map((message, key) => (
        <Link to={`/message_page/chat/${message.id}`}>
        <div className="incomming-msg" key={message.id}>
            <img src={message.src}/>
            <div>
                <h4>{message.name}</h4>
                <p>{message.content}</p>
            </div>
            <p className={message.class}>{message.unseen_num}</p>
        </div>
    </Link>
    ) )} */}
        </div>
      </div>
    </>
  );
}
