import React, { useContext, useState } from 'react'
import cover_img from '../Assets/Accouonts Page/Eren.jpeg'
import profile_img from '../Assets/Accouonts Page/profile pic.png'
import placeholder from '../Assets/Accouonts Page/Placeholder image.png'
import security_icon from '../Assets/Accouonts Page/security icon.svg'
import ham_menu from '../Assets/Accouonts Page/mobile ham.svg'
import ham_menu_close from '../Assets/Accouonts Page/mobile ham close.svg'
import create_tales from '../Assets/Accouonts Page/create twigs.svg'
import create_twigs from '../Assets/Accouonts Page/create tales.svg'
import save_twigs from '../Assets/Accouonts Page/save twigs.svg'
import { AppContext } from '../Services/Context'
import Acc_Post_box from './Acc_Post_box'
import { Link } from 'react-router-dom'
import { Mutual_view } from '../Someones Account/Mutual_view'

export default function Acc_profile({userData, userTwigs}) {
    const [hamOpen, setHamOpen] = useState(false)
    const [createTwig, setCreateTwig] = useState(false)
    const [mutual, setMutual] = useState()
    const [array, setArray] = useState()
    const [name, setName] = useState()
    const { logData, allTakes } = useContext(AppContext);
    // console.log(userData.name);

    const [rotation, setRotation] = useState(0);

    const takeList = allTakes.filter((item) => {
        // console.log(uniqueMap);
        if (item.uid === logData.uid) {
          // uniqueMap[item.uid] = true;
          return item;
        }
        return false;
      });

  const rotateImage = () => {
    setRotation((prevRotation) => (prevRotation + 45) % 90);
    setHamOpen(!hamOpen);
  };

  return (
<>
{createTwig ? <Acc_Post_box setCreateTwig={setCreateTwig} /> : <></>}
{mutual ? 
  <>
  <Mutual_view setMutual={setMutual} array={array} name={name}/>
  </>
  :
  <></>
}

<div className="user-profile-img">
    <section className='mobile-ham-display'>
        <button className='mobile-ham' onClick={rotateImage}>
            {/* {hamOpen ? <img src={ham_menu_close} /> : <img src={ham_menu} />} */}
             {/* <img src={hamOpen ? ham_menu_close : ham_menu} />  */}
             <img src={ham_menu} style={{ transform: `rotate(${rotation}deg)` }}/> 
        </button>
        {hamOpen ? 
        <div className='mobile-ham-options '>
            <button onClick={() => setCreateTwig(true)}><img src={create_twigs} /></button>
            <button><img src={create_tales} /></button>
            <Link to="/accounts_page/save_post">
                <button><img src={save_twigs} /></button>
            </Link>
            {/* <button>Create Tales</button> */}
            {/* <button>Save Twigs</button> */}
        </div>
        : <></> }
    </section>
    <img className="account-cover-img" src={userData.cover_img ? userData.cover_img : placeholder}/>
    <div>
        <img src={userData.img}/>
        <p>{userData.name}</p>
        <p>@{userData.user_name}</p>

        <div className="followers-box">
            <div>
                <button>Posts</button>
                <p>{userTwigs?.length+takeList.length}</p>
            </div>
            <div>
                <button onClick={() => {setMutual(true); setArray(userData.followers); setName("Followers")}}>Followers</button>
                <p onClick={() => {setMutual(true); setArray(userData.followers); setName("Followers")}}>{userData.followers? userData.followers.length : 0}</p>
            </div>
            <div>
                <button onClick={() => {setMutual(true); setArray(userData.following); setName("Following")}}>Following</button>
                <p onClick={() => {setMutual(true); setArray(userData.following); setName("Following")}}>{userData.following? userData.following.length:0}</p>
            </div>                  
        </div>
    </div>
    <button><img src={ userData.lock ? security_icon : null}/></button>
</div>

</>
  )
}
