import React from 'react'

export default function Settings_Delete_acc() {
  return (
<>

<div className='settings-edit-profile settings-deactivate-acc'>
    <p className='dark:text-white'>Delete Account</p>
    <p>Are you sure you want to delete your current account?</p>
    <section>
        <p className='dark:text-white'>Enter your current password</p>
        <input type='password' placeholder='Enter Your Password' className='password-input dark:caret-white dark:text-white' />
        <div className="flex flex-col items-center mt-5 justify-center">
            <div>
                <input id="inline-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label htmlFor="inline-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">I confirm on deletion of my account</label>
            </div>
            <button className='bg-red-500 px-14 py-1 mt-10 rounded-full text-base font-medium'>Delete Account</button>
        </div>
    </section>
    <p>Once you delete your account it can not be recovered</p>
</div>

</>
  )
}
