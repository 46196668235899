import React, { useCallback, useContext, useEffect, useState } from "react";
import "../Styles/login_7_sub_identification.css";
import { AppContext } from "../Services/Context";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { auth, database } from "../Services/firebaseconfig";
import { collection, doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export default function Login_7_sub_identification() {
  const userInfo = useContext(AppContext);
  const [user, setUser] = useState({ name: "", user_name: "" });
  const [err, setErr] = useState("");
  const navigate = useNavigate();

  // console.log(userInfo.userNames)

  async function SignUp() {
    if (user.user_name === "") {
      setErr("User Name required");
    } else {
      const lowerCaseUserNames = userInfo.userNames.map((name) => name.toLowerCase());

      if (lowerCaseUserNames.includes(user.user_name.trim().toLowerCase())) {
        setErr("Username already exists. Please choose a different one.");
      } else {

        userInfo.userInfo.name = user.name;
        userInfo.userInfo.user_name = user.user_name;
        try {

          if (!userInfo.userInfo.email || userInfo.userInfo.email.trim() === "") {
            setErr("Email is required");
            return;
          }

          const user_id = userInfo.logData.uid;

          // console.log(user_id);

          setDoc(doc(database, "User Data", user_id), {
            name: userInfo.userInfo.name,
            email: userInfo.userInfo.email,
            img: userInfo.userInfo.img,
            gender: userInfo.userInfo.gender,
            DOB: userInfo.userInfo.DOB ? userInfo.userInfo.DOB : null,
            bio: userInfo.userInfo.bio ? userInfo.userInfo.bio : null,
            user_name: userInfo.userInfo.user_name
              ? userInfo.userInfo.user_name
              : null,
            followers: [],
            following: [],
            twigs_num: 0,
            deactivated: false,
            lock:false,
            settings: {
              notifications: {
                push:[],
                email:[]
              },
              privacy:{
                interact:{
                  message:"anyone",
                  comment:"anyone",
                  like:"anyone",
                  tag:"anyone",
                  share:"anyone",
                }
              },
              sensitive_content: "less",
            },
            blocked:[],
            language_set:"en"
          });

          setDoc(doc(database, "userChats", user_id), {});
          setDoc(doc(database, "Uid", userInfo.userInfo.user_name), {
            ["uid"]: user_id,
          })
            .then(navigate("/"));
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  const enterToNextPg = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        SignUp()
      }
    },
    [user]
  );

  useEffect(
    (e) => {
      window.addEventListener("keydown", enterToNextPg);
      return () => {
        window.removeEventListener("keydown", enterToNextPg);
      };
    },
    [enterToNextPg]
  );

  // console.log(user);

  return (
    <>
      <div className="sub-idf-div">
        <div>
          <div className="three-dots three-middle-dot">
            <p className="circle"></p>
            <p className="circle"></p>
            <p className="circle circle-color"></p>
          </div>

          <div className="mt-[100px]">
            <p className="name dark:text-white">Name*</p>
            <input
              onChange={(e) => {
                setUser({ ...user, name: e.target.value });
              }}
              className="name-input dark:text-white"
              placeholder="enter your name"
            />
            <p className="username dark:text-white">Username</p>
            <input
              onChange={(e) => {
                setUser({ ...user, user_name: e.target.value.toLowerCase() });
              }}
              value={user.user_name}
              className="username-input dark:text-white"
              placeholder="enter username"
            />
          </div>
          {err !== "" ? (
            <p className="username text-red-500 mt-5">{err}</p>
          ) : null}
          <div className="continue-button continue-button-position">
            <button onClick={SignUp}>Done</button>
          </div>
          {/* <div class="skip-button">
                <button>Skip</button>
            </div> */}
        </div>
      </div>
    </>
  );
}
