import React, { useContext, useState } from 'react'

import group from '../Assets/Messages assets/group msg.svg'
import cross_icon from '../Assets/Home page 1/cross icon.svg'
import search_msg from '../Assets/Messages assets/search msg.svg'
import { myfriends } from './message_components'
import { AppContext } from '../Services/Context'
import { Link, useNavigate } from 'react-router-dom'
import { doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore'
import { database } from '../Services/firebaseconfig'
import { ChatContext } from '../Services/ChatContext'

export default function Msg_Friends() {
  const [friends, setAllFriends] = useState(myfriends)
  const {followers, logData, userData} = useContext(AppContext)
  const { dispatch } = useContext(ChatContext);

  // console.log(followers);
  const navigate = useNavigate();

  // console.log(followers);

  const handleSelect = async (follower) => {
    const combinedId = logData.uid > follower.uid ? logData.uid + follower.uid : follower.uid + logData.uid;
    try {
      const res = await getDoc(doc(database, "chats", combinedId));
      if (!res.exists()) {
        await setDoc(doc(database, "chats", combinedId), { messages: [] });

        await updateDoc(doc(database, "userChats", logData.uid), {
          [`${combinedId}.userInfo`]: {
            uid: follower.uid,
            displayName: follower.name,
            photoURL: follower.img,
          },
          [`${combinedId}.date`]: serverTimestamp(),
        });

        await updateDoc(doc(database, "userChats", follower.uid), {
          [`${combinedId}.userInfo`]: {
            uid: logData.uid,
            displayName: userData.name,
            photoURL: userData.img,
          },
          [`${combinedId}.date`]: serverTimestamp(),
        });
      }

      dispatch({ type: "CHANGE_USER", payload: follower });
      navigate(`/message_page/chat/${combinedId}`);
    } catch (err) {
      console.error("Error selecting chat:", err);
    }
  };

  const uniqueMap = {};

  const filteredList = followers.filter(item => {
    if (!uniqueMap[item.uid]) {
        uniqueMap[item.uid] = true;
        return true;
    }
    return false;
});


  return (
<>
<div className='chat-friends-main w-full'>
  <div className='chat-friends-sec border-2 border-b-transparent bg-[#38424B] dark:bg-[#141414cc] dark:border-[#A7DD35] dark:border-b-transparent'>
    <section>
      <div>
        <button><img src={group} /></button>
        <p>Add New Group</p>
      </div>
      <div>
        <button><img src={search_msg} /></button>
        <p>Find New Friends</p>
      </div>
    </section>
    <button onClick={() => navigate(-1)} className='absolute top-4 right-8 w-6 h-6'>
      <img className='w-6 h-6' src={cross_icon} />
    </button>
    <hr/>
    <section className='chat-my-friends'>
      <p>Your Friends</p>
      <div className='online-sec'>
      {filteredList.map((online, key) => (
            <div onClick={() => handleSelect(online)}>
                <img src={online.img}/>
                <p>{online.name}</p>
                {/* <p className={online.indicator}>{online.time}</p> */}
            </div>               
        ) )}
      </div>    
    </section>
  </div>
</div>
</>
    )
}
