export let highlights = [
    {
        "id":"1",
        "src":require('../Assets/Brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"2",
        "src":require('../Assets/Brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"3",
        "src":require('../Assets/Brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"4",
        "src":require('../Assets/Brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"5",
        "src":require('../Assets/Brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"6",
        "src":require('../Assets/Brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"7",
        "src":require('../Assets/Brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"8",
        "src":require('../Assets/Brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"9",
        "src":require('../Assets/Brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"9",
        "src":require('../Assets/Brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"9",
        "src":require('../Assets/Brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"9",
        "src":require('../Assets/Brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"9",
        "src":require('../Assets/Brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"9",
        "src":require('../Assets/Brand assets/food.jpg'),
        "name":"Food"
    },
]

export let posts = [
    {
        "id":"1",
        "src":require('../Assets/Brand assets/profile_pic.png')
    },
    {
        "id":"2",
        "src":require('../Assets/Brand assets/profile_pic.png')
    },
    {
        "id":"3",
        "src":require('../Assets/Brand assets/profile_pic.png')
    },
    {
        "id":"4",
        "src":require('../Assets/Brand assets/profile_pic.png')
    },
    {
        "id":"5",
        "src":require('../Assets/Brand assets/profile_pic.png')
    },
    {
        "id":"6",
        "src":require('../Assets/Brand assets/profile_pic.png')
    }
]