import { doc, setDoc } from 'firebase/firestore'
import React, { useContext } from 'react'
import { database } from '../Services/firebaseconfig'
import { AppContext } from '../Services/Context'

export default function Setting_Frnd_Interact() {

    const {logData, userData} = useContext(AppContext)

    async function changeInteractAnyone(name){
        await setDoc(doc(database, "User Data", logData.uid),{
            settings:{privacy:{interact:{[name]:"anyone"}}}
        },{merge:true})
    }

    async function changeInteractFollowing(name){
        await setDoc(doc(database, "User Data", logData.uid),{
            settings:{privacy:{interact:{[name]:"following"}}}
        },{merge:true})
    }

  return (
<>

<div className='settings-edit-profile settings-push-notif'>
    <p className='dark:text-white'>Check Who Can Interact</p>
    <div className='settings-push-notif-scroll'>
    <section>
        <p className='dark:text-white'>See Who Can Message You</p>
        <div className="flex items-center mb-4">
            <input id="default-radio-1" type="radio" value="" checked={userData.settings.privacy.interact.message === "anyone"} name="message" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            onChange={(e)=>{changeInteractAnyone(e.target.name)}}
            />
            <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Anyone</label>
        </div>
        <div className="flex items-center mb-4">
            <input id="default-radio-1" type="radio" value="" checked={userData.settings.privacy.interact.message === "following"} name="message" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            onChange={(e)=>{changeInteractFollowing(e.target.name)}}/>
            <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
        </div>
    </section>

    <section>
        <p className='dark:text-white'>See Who Can Comment</p>
        <div className="flex items-center mb-4">
            <input id="default-radio-1" type="radio" value="" checked={userData.settings.privacy.interact.comment === "anyone"} name="comment" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            onChange={(e)=>{changeInteractAnyone(e.target.name)}}/>
            <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Anyone</label>
        </div>
        <div className="flex items-center mb-4">
            <input id="default-radio-1" type="radio" value="" checked={userData.settings.privacy.interact.comment === "following"} name="comment" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            onChange={(e)=>{changeInteractFollowing(e.target.name)}}/>
            <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
        </div>
    </section>

    <section>
        <p className='dark:text-white'>See Who Can Like</p>
        <div className="flex items-center mb-4">
            <input id="default-radio-1" type="radio" value="" checked={userData.settings.privacy.interact.like === "anyone"} name="like" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            onChange={(e)=>{changeInteractAnyone(e.target.name)}}/>
            <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Anyone</label>
        </div>
        <div className="flex items-center mb-4">
            <input id="default-radio-1" type="radio" value="" checked={userData.settings.privacy.interact.like === "following"} name="like" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            onChange={(e)=>{changeInteractFollowing(e.target.name)}}/>
            <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
        </div>
    </section>

    <section>
        <p className='dark:text-white'>See Who Can Tag You</p>
        <div className="flex items-center mb-4">
            <input id="default-radio-1" type="radio" value="" checked={userData.settings.privacy.interact.tag === "anyone"} name="tag" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            onChange={(e)=>{changeInteractAnyone(e.target.name)}}/>
            <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Anyone</label>
        </div>
        <div className="flex items-center mb-4">
            <input id="default-radio-1" type="radio" value="" checked={userData.settings.privacy.interact.tag === "following"} name="tag" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            onChange={(e)=>{changeInteractFollowing(e.target.name)}}/>
            <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
        </div>
    </section>

    <section>
        <p className='dark:text-white'>See Who Can Share</p>
        <div className="flex items-center mb-4">
            <input id="default-radio-1" type="radio" value="" checked={userData.settings.privacy.interact.share === "anyone"} name="share" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            onChange={(e)=>{changeInteractAnyone(e.target.name)}}/>
            <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Anyone</label>
        </div>
        <div className="flex items-center mb-4">
            <input id="default-radio-1" type="radio" value="" checked={userData.settings.privacy.interact.share === "following"} name="share" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            onChange={(e)=>{changeInteractFollowing(e.target.name)}}/>
            <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Only Following</label>
        </div>
    </section>      
    </div>
</div>

</>
  )
}
