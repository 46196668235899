import React, { useState, useEffect, useRef } from "react";
import Loader from "../Register/Loader";
import { auth } from "./firebaseconfig";
import { doc, getDoc, onSnapshot, orderBy, serverTimestamp, updateDoc } from "firebase/firestore";
import { database } from "./firebaseconfig";
import { onAuthStateChanged } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";

const AppContext = React.createContext();

const AppProvider = (props) => {
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
    img: null,
    cover_img: null,
    gender: "",
    DOB: null,
    bio: null,
    name: "",
    user_name: "",
    twigs: 0,
    followers: [],
    following: [],
  });
  const [userData, setUserData] = useState([]);
  const [logData, setLogData] = useState([]);
  const [allTwigs, setAllTwigs] = useState([]);
  const [publicTwigs, setPublicTwigs] = useState([]);
  const [allTakes, setAllTakes] = useState([]);
  const [allStories, setAllStories] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [userNames,setUserNames] = useState([])
  const [followers, setFollowers] = useState([]);
  const [followings, setFollowings] = useState([]);
  const [loading, isLoading] = useState(true)
  const [dark, setDark] = useState(
    JSON.parse(window.localStorage.getItem("THEME"))
  );
  const [width, setWidth] = useState(window.innerWidth);


  // let newUserObject = {...user};
  // let newUserDataArray = [...newUserObject.userData];

  useEffect(() => {
    const fetchData = async () => {
      try {
        onAuthStateChanged(auth, async (currUser) => {
          if (currUser) {
            //console.log(currUser);
            setLogData(currUser);
            const userId = currUser.uid;
            const docRef = doc(database, "User Data", `${userId}`);
            onSnapshot(docRef, (docSnap) => {
              setUserData(docSnap.data());
            })

          } else {
            //console.log("Bhau Error ache");
          }
        });
      } catch (error) {
        //console.log(error);
      }
    };
    fetchData();
    setTimeout(() => { isLoading(false) }, 2500)
  }, [auth.currentUser]);


  useEffect(() => {
    const fetchFollowers = async () => {
      const currUser = auth.currentUser;
      if (currUser) {
        const docRef = doc(database, "User Data", currUser.uid);
        const docSnap = await getDoc(docRef);
        docSnap.data()
          ? docSnap.data().followers.forEach(async (follower) => {
            // console.log(follower);
            const q = doc(database, "User Data", follower);
            onSnapshot(q, (data) => {
              //   console.log(data.data());
              setFollowers((followers) => [
                ...followers,
                { ...data.data(), uid: data.id },
              ]);
            });
          })
          : setFollowers([]);
      }
    };
    fetchFollowers();
    setTimeout(() => { isLoading(false) }, 2500)
  }, [auth.currentUser]);

  useEffect(() => {
    const fetchFollowings = async () => {
      const currUser = auth.currentUser;
      if (currUser) {
        const docRef = doc(database, "User Data", currUser.uid);
        const docSnap = await getDoc(docRef);
        docSnap.data()
          ? docSnap.data().following.forEach(async (following) => {
            // console.log(follower);
            const q = doc(database, "User Data", following);
            onSnapshot(q, (data) => {
              //   console.log(data.data());
              setFollowings((followings) => [
                ...followings,
                { ...data.data(), uid: data.id },
              ]);
            });
          })
          : setFollowings([]);
      }
    };
    fetchFollowings();
    setTimeout(() => { isLoading(false) }, 2500)
  }, [auth.currentUser]);

  const [notificationData, setNotificatioData] = useState([])

  useEffect(() => {
    const fetchNotifications = async () => {
      const currUser = auth.currentUser;
      if (currUser) {
        const docRef = doc(database, "notifications", currUser.uid);
            // console.log(docRef);
            onSnapshot(docRef, (data) => {
                // console.log(data.data());
              setNotificatioData((notification) => [
                ...notification,
                { ...data.data(), uid: data.id },
              ]);
            });
      }
    };
    fetchNotifications();
    setTimeout(() => { isLoading(false) }, 2500)
    },[auth.currentUser]);

    // console.log(notificationData);


  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(database, "Twigs"),
      { orderBy: ["timeStamp", "desc"] },
      async (querySnapshot) => {
        const updatedTwigs = [];
        querySnapshot.forEach((doc) => {
          updatedTwigs.push({ ...doc.data(), docId: doc.id });
        });
        // Sort the updatedTwigs array by timestamp
        updatedTwigs.sort((a, b) => b.timeStamp - a.timeStamp);
        setAllTwigs(updatedTwigs);
      }
    );

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchPublicTwigs = async () => {
      const currUser = auth.currentUser;
      if (currUser) {
        // console.log(currUser.uid)
        // Listen to the Twigs collection
        onSnapshot(collection(database, "Twigs"), async (querySnapshot) => {
          const publicTwigsArray = [];
  
          for (const twigDoc of querySnapshot.docs) {
            const twigData = twigDoc.data();
            const twigUserId = twigData.uid;
  
            // Fetch the associated user document for each twig
            const userRef = doc(database, "User Data", twigUserId);
            const userDoc = await getDoc(userRef);
  
            if (userDoc.exists()) {
              const userData = userDoc.data();
              // console.log(userData.followers)
              // Check if the user is unlocked and current user is in followers array
              if (
                userData.lock === false || 
                userData.followers.includes(currUser.uid)
              ) {
                publicTwigsArray.push({ ...twigData, docId: twigDoc.id });
              }
            }
          }
  
          // Sort the public twigs by timestamp
          publicTwigsArray.sort((a, b) => b.timeStamp - a.timeStamp);
  
          // Update the state with the public twigs
          setPublicTwigs(publicTwigsArray);
        });
      }
    };
  
    fetchPublicTwigs();
    setTimeout(() => { isLoading(false); }, 2500);
  }, [auth.currentUser]);
  

  useEffect(() => {
    const fetchAllTales = async () => {
      try {
        const q = query(
          collection(database, "Takes"),
          orderBy("timeStamp", "asc")
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((document) => {
          const currentDoc = { ...document.data(), docId: document.id };
          setAllTakes((allTakes) => [...allTakes, currentDoc]);
        });
      } catch (e) {
        //console.log(e);
      }
    };

    fetchAllTales();
    setTimeout(() => { isLoading(false) }, 2500)
  }, []);

  useEffect(() => {
    const fetchAllStories = async () => {
      try {
        const q = query(
          collection(database, "stories")
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((document) => {
          const currentDoc = { ...document.data(), uid: document.id };
          setAllStories((allStories) => [...allStories, currentDoc]);
        });
      } catch (e) {
        //console.log(e);
      }
    };

    fetchAllStories();
    setTimeout(() => { isLoading(false) }, 2500)
  }, []);


  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const q = query(collection(database, "User Data"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          setAllUsers((allUsers) => [...allUsers,{...doc.data(), uid:doc.id}]);
          return doc.data();
        });
      } catch (e) {
        //console.log(e);
      }

      // return userTwigs
    };

    fetchAllUsers();
    setTimeout(() => { isLoading(false) }, 2500)
  }, []);

  useEffect(() => {
    const fetchUserNames = async () => {
      try {
        const q = query(collection(database, "Uid"));
        const querySnapshot = await getDocs(q);
        const userNames = [];
        querySnapshot.forEach((document) => {
          if (!userNames.includes(document.id)) {
            userNames.push(document.id); // Store the document ID (user name)
          }
        });
        setUserNames(userNames); // Store the unique document IDs in state
      } catch (e) {
        //console.log(e);
      }
    };
  
    fetchUserNames();
    setTimeout(() => { isLoading(false) }, 2500);
  }, []);
  

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to send notification (placeholder)
  function sendNotification(message) {
    // Implement logic to display notification within your application UI
    // console.log("Notification:", message);
  }

  useEffect(() => {
    const currentUser = auth.currentUser;
    const userDocRef = doc(
      database,
      "User Data",
      currentUser ? currentUser.uid : "test"
    );

    onSnapshot(
      userDocRef,
      (snapshot) => {
        if (snapshot.exists) {
          const newData = snapshot.data();
          const previousData = snapshot.metadata.hasPendingWrites
            ? null
            : snapshot.data();

          if (previousData) {
            const changedFields = [];
            const changes = {};

            // Compare current data with previous data
            Object.keys(newData).forEach((field) => {
              if (newData[field] !== previousData[field]) {
                // Field has changed
                changedFields.push(field);
                changes[field] = {
                  oldValue: previousData[field],
                  newValue: newData[field],
                };
              }
            });

            if (changedFields.length > 0) {
              // console.log("Changed fields:", changedFields);
              // console.log("Changes:", changes);
              // Trigger notification logic based on changed fields
              sendNotification("Changes:", changes);
            }
          }
        } else {
          console.log("User data document does not exist.");
        }
      },
      (error) => {
        console.error("Error listening for user data changes:", error);
      }
    );
  });


  return (
    <AppContext.Provider
      value={{
        userInfo: userInfo,
        userData: userData,
        logData: logData,
        allTwigs: allTwigs,
        publicTwigs: publicTwigs,
        allTakes: allTakes,
        allStories: allStories,
        allUsers: allUsers,
        followers: followers,
        userNames:userNames,
        followings:followings,
        dark: dark,
        setDark: setDark,
        width: width,
        loading: loading,
        notificationData:notificationData
      }}
    >
      {props.children}
    </AppContext.Provider>
  );



};

export { AppContext, AppProvider };
