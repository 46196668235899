import React, { useCallback, useContext,useEffect,useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../Styles/login_6_identification.css'
import { AppContext } from '../Services/Context';

export default function Login_6_identification() {
    const navigate = useNavigate()
    const [gender, setGender] = useState({
        male: false,
        female: false,
        other: false,
    }) 
    const {userInfo} = useContext(AppContext)
    const [user, setUser] = useState({gender:""},{DOB:""},{bio:""})
    // console.log(userInfo);
    function Continue() {
        userInfo.gender = user.gender;
        userInfo.DOB = user.DOB;
        userInfo.bio = user.bio;
    }

    function Gender_select(e) {
        const {value, name} = e.target;
        setUser({...user,gender:value})
        setGender(({
            male: false,
            female: false,
            other: false, 
            [name]: true
        }))
    }

    const enterToNextPg = useCallback(
        (e) => {
          const { key, keyCode } = e;
          if (keyCode === 13) {
            navigate("/login7");
            Continue()
          }
        },
        [gender]
      );
    
      useEffect(
        (e) => {
          window.addEventListener("keydown", enterToNextPg);
          return () => {
            window.removeEventListener("keydown", enterToNextPg);
          };
        },
        [enterToNextPg]
      );

  return (
<>

<div className="main-div">
    
        <div className="three-dots">
        <p className="circle"></p>
        <p className="circle circle-color"></p>
        <p className="circle"></p>
        </div>
    <div>
        <p className="idf-heading dark:text-white">Identify Yourself</p>
        <p className="idf-heading-base dark:text-white">Introduce yourself to the people.</p>
        <p className="idf-sub-heading dark:text-white">Gender*</p>
    
        <div className="gender-button">
            <button value="Male" name='male' onClick={(e) => Gender_select(e)} className={` dark:text-white ${gender.male && 'gender-button-focus'}`}>Male</button>
            <hr/>
            <button value="Female" name='female' onClick={(e) => Gender_select(e)} className={` dark:text-white ${gender.female && 'gender-button-focus'}`}>Female</button>
            <hr/>
            <button value="Others" name='other' onClick={(e) => Gender_select(e)} className={` dark:text-white ${gender.other && 'gender-button-focus'}`}>Others</button>
        </div>

        <p className="idf-sub-heading dark:text-white">Birthday</p>
    
        <div className="age-button dark:text-white">
            {/* <input placeholder="DD" type="date" />
            <input placeholder="MM" type="month"/>
            <input placeholder="YYYY" type=""/> */}
            <input onChange={(e) => {setUser({...user,DOB:e.target.value})}} type="date" name="begin" placeholder="dd-mm-yyyy" value={user.DOB} min="1990-01-01" max="2050-12-31"/>
        </div>

        <p className="idf-sub-heading dark:text-white">Describe Yourself</p>
        <input onChange={(e) => {setUser({...user,bio:e.target.value})}} className="describe-yourself dark:text-white border-b-2 border-black dark:border-white"/>

        <div className="continue-button continue-button-position">
        <Link to="/login7">
            <button onClick={Continue()}>Continue</button>
        </Link>
        </div>
    </div>
</div>

</>
  )
}
