import React, { useContext, useEffect, useRef, useState } from "react";
import Plus_btn from "../Assets/Reels assets/plus btn.svg";
import mute from "../Assets/Reels assets/mute.svg";
import unmute from "../Assets/Reels assets/unmute.svg";
import { reels } from "./reels_components";

import video from "../Assets/Reels assets/video.mp4";
import Reel_Action_Sec from "./Reel_Action_Sec";
import { AppContext } from "../Services/Context";

export default function Reel_Middle_Sec({take, reel_id, setCmnton, cmnton, setShareOn}) {
  const [allreels, setAllReel] = useState(reels);
  const { allTakes, logData } = useContext(AppContext);

  const [progress, setProgress] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  
  // console.log(take);
  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  
  const handleMuteUnmute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

  const handleProgress = () => {
    const currentProgress =
      (videoRef.current.currentTime / videoRef.current.duration) * 100;
    setProgress(currentProgress);
  };

  // Add event listener for timeupdate to update the progress state
  useEffect(() => {
    const video = videoRef.current;
    video.addEventListener("timeupdate", handleProgress);

    // Cleanup the event listener on component unmount
    return () => {
      video.removeEventListener("timeupdate", handleProgress);
    };
  }, []);

  return (
    <>
      <div className="reel-middle-sec">
        {/* {allTales.map((reel, key) => ( */}
        <div className="reel-scroll-div" key={take?.docId}>
          <div className="bg-black">
            <div className="reel-header">
              {/* <p>Takes</p> */}
              <button onClick={handleMuteUnmute}>
                <img src={isMuted ? unmute : mute} />
              </button>
            </div>
            {/* <img src={reel.src}/> */}
            <video
              ref={videoRef}
              src={take?.video}
              key={take?.docId}
              autoPlay
              muted
              loop
            ></video>

            <button className="play_pause_btn" onClick={handlePlayPause}>
              {/* {isPlaying ? 'Pause' : 'Play'} */}
            </button>

            <div className="action-mobile">
              <Reel_Action_Sec
                setCmnton={setCmnton}
                setShareOn={setShareOn}
                cmnton={cmnton}
                uid={take?.uid}
                logData={logData}
                reel={take}
                reel_id={reel_id}
              />
            </div>

            {/*   NAME TO BE DONE
            <div className="reel_name">
              <div>
                <section>
                  <img />
                  <p></p>
                  <button></button>
                </section>
              </div>
            </div> */}


            <div className="video_progresbar">
              <input
                type="range"
                min="0"
                max="100"
                value={progress}
                onChange={(e) =>
                  (videoRef.current.currentTime =
                    (videoRef.current.duration / 100) * e.target.value)
                }
              />
            </div>
          </div>
          <div className="action-display">
            <Reel_Action_Sec
              setCmnton={setCmnton}
              setShareOn={setShareOn}
              cmnton={cmnton}
              uid={take?.uid}
              logData={logData}
              reel={take}
              reel_id={reel_id}
            /> 
          </div>
        </div>
        {/* ))} */}
      </div>
    </>
  );
}
