import React from 'react'
import offers_icon from '../Assets/Owner brand assets/food.jpg'
export default function Owner_brand_action() {
  return (
<>

<div className="acc-edit-sec">

    <div className="brand-shopping own-brand-shopping">
        <section>
            <button>Professional Dashboard 
            <span>50 accounts reached in 7 days</span>
            </button>
        </section>
        <button>Edit Profile</button>
        <button>Promote Yourself</button>
        <button><img src={offers_icon} /> Add Offers</button>
    </div>

</div>

</>
  )
}
