export let reels = [
    {
        "id":"1",
        "src":require('../Assets/Reels assets/video.mp4')
    },
    {
        "id":"2",
        "src":require('../Assets/Reels assets/video.mp4')
    },
    {
        "id":"3",
        "src":require('../Assets/Reels assets/video.mp4')
    },
    {
        "id":"4",
        "src":require('../Assets/Reels assets/video.mp4')
    },
    {
        "id":"5",
        "src":require('../Assets/Reels assets/video.mp4')
    },
]

export let abouts = [
    {
        "id":"1",
        "comment_name":"jiya1234_",
        "comment":"nice nice"
    },
    {
        "id":"2",
        "comment_name":"jiya1234_",
        "comment":"nice nice"
    },
    {
        "id":"2",
        "comment_name":"jiya1234_",
        "comment":"nice nice"
    },
    {
        "id":"2",
        "comment_name":"jiya1234_",
        "comment":"nice nice"
    },
]