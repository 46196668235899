import React, { useContext } from 'react'
import { AppContext } from '../Services/Context'
import { arrayRemove, arrayUnion, doc, setDoc } from 'firebase/firestore'
import { database } from '../Services/firebaseconfig'

export default function Settings_Email_Notifi() {

    const {logData, userData} = useContext(AppContext)

    console.log(userData.settings.notifications.email.includes("product"))

    async function setEmailNotification (type) {
        await setDoc(doc(database, "User Data", logData.uid),{
            settings: {notifications:{email: arrayUnion(type)}},
        }, {merge:true})
    }

    async function unsetEmailNotification (type) {
        await setDoc(doc(database, "User Data", logData.uid),{
            settings: {notifications:{email: arrayRemove(type)}},
        }, {merge:true})
    }

    const toggleNotification = (type,e) => {
        console.log(type, e.target.checked)
        if(e.target.checked){
            setEmailNotification(type)
        }
        else{
            unsetEmailNotification(type)
        }
    }

  return (
<>

<div className="settings-edit-profile settings-email-notifi">
    <p className='dark:text-white'>Email Notification</p>
    <section>
        <div className="Email-switch">
            <p className='dark:text-white'>Feedback emails</p>
            <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" checked={userData.settings.notifications.email.includes("feedback")} className="sr-only peer" onChange={(e)=>{toggleNotification("feedback",e)}}/>
                <div className="w-14 h-6 bg-gray-500 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-8 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BDFA3C] after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
            </label>
        </div>
    </section>
    <section>
        <div className="Email-switch">
            <p className='dark:text-white'>Reminder emails</p>
            <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" checked={userData.settings.notifications.email.includes("reminder")} className="sr-only peer" onChange={(e)=>{toggleNotification("reminder",e)}}/>
                <div className="w-14 h-6 bg-gray-500 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-8 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BDFA3C] after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
            </label>
        </div>
    </section>
    <section>
        <div className="Email-switch">
            <p className='dark:text-white'>Product emails</p>
            <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" checked={userData.settings.notifications.email.includes("product")} className="sr-only peer" onChange={(e)=>{toggleNotification("product",e)}}/>
                <div className="w-14 h-6 bg-gray-500 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-8 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BDFA3C] after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
            </label>
        </div>
    </section>
    <section>
        <div className="Email-switch">
            <p className='dark:text-white'>News emails</p>
            <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" checked={userData.settings.notifications.email.includes("news")} className="sr-only peer" onChange={(e)=>{toggleNotification("news",e)}}/>
                <div className="w-14 h-6 bg-gray-500 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-8 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BDFA3C] after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
            </label>
        </div>
    </section>
    <section>
        <div className="Email-switch">
            <p className='dark:text-white'>Support emails</p>
            <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" checked={userData.settings.notifications.email.includes("support")} className="sr-only peer" onChange={(e)=>{toggleNotification("support",e)}}/>
                <div className="w-14 h-6 bg-gray-500 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-8 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BDFA3C] after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
            </label>
        </div>
    </section>
</div>

</>
  )
}
