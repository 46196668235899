import React, { useContext, useState } from "react";
import { AppContext } from "../Services/Context";
import { auth } from "../Services/firebaseconfig";
import { signInWithEmailAndPassword, updatePassword } from "firebase/auth";

export default function Settings_change_password() {
  const { userData } = useContext(AppContext);
  const [err, setErr] = useState("");
  const [password, setPassword] = useState({
    curr: "",
    new: "",
    confirm: "",
  });

  // console.log(password);

  const ChangePass = () => {
    if (password.curr != "") {
        if(password.new == password.confirm){
            if(password.new.length > 7 ) {
                signInWithEmailAndPassword(auth, userData.email, password.curr)
                  .then(() => {
                    alert("success");
                    const user = auth.currentUser
                   updatePassword(user,password.confirm)
                  })
                  .catch((e) => {
                    console.log(e.message);
                    setErr("Your current password is incorrect");
                  });
            }else{
                setErr("New password must be greater than 7 characters")
            }
        }
        else{
            setErr("New password and confirm password doesn't match")
        }
    } else {
      alert("put your current password");
    }
  };

  return (
    <>
      <div className="settings-edit-profile setting-change-pass">
        <p className="text-black dark:text-white">Change Password</p>
        <div>
          <p className="text-black dark:text-white">Current Password</p>
          <input
            onChange={(e) => setPassword({ ...password, curr: e.target.value })}
            type="password"
            placeholder="Current Password"
            className="email-input dark:caret-white dark:text-white"
          ></input>
        </div>
        <div>
          <p className="text-black dark:text-white">Enter New Password</p>
          <input
            type="password"
            placeholder="Enter New Password"
            className="email-input dark:caret-white dark:text-white"
            onChange={(e) => setPassword({ ...password, new: e.target.value })}
          ></input>
        </div>
        <div>
          <p className="text-black dark:text-white">Confirm New Password</p>
          <input
            type="password"
            placeholder="Confirm New Password"
            className="email-input dark:caret-white dark:text-white"
            onChange={(e) =>
              setPassword({ ...password, confirm: e.target.value })
            }
          ></input>
        </div>
        <div>
          {err ? <p className="text-white">{err}</p> : null}
          <a href="https://www.instagram.com/">Forgot Password ?</a>
        </div>
        <div>
          <button
            onClick={() => ChangePass()}
            className="general_theme_btn absolute bottom-20 left-[38%] bg-[#B9F53B] p-2"
          >
            Change
          </button>
        </div>
      </div>
    </>
  );
}
