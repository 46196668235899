import React, { useState } from 'react'
import { notifications } from './Home_pg_Components'
import up_btn from "../Assets/Someone Acc assets/up_btn.svg";
import down_btn from "../Assets/Someone Acc assets/down btn.svg";
import { arrayRemove, arrayUnion, doc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { database } from '../Services/firebaseconfig';

export const Side_notification = ({notificationData, userData, logData}) => {
  
    const [allNotification, setAllNotification] = useState(notifications)
    const [followReq, setFollowReq] = useState(false)

    const updateNotifications = async (updatedNotifications) => {

        const docRef = doc(database, "notifications", logData.uid);
        await updateDoc(docRef, { notifications: updatedNotifications });
      
    };

    const acceptReq = async (follower) =>{
      // console.log(follower)
      await updateDoc(doc(database, "User Data", `${logData.uid}`), {
        followers: arrayUnion(follower),
      });
      await updateDoc(doc(database, "User Data", `${follower}`), {
        following: arrayUnion(logData.uid),
      });
      await updateDoc(doc(database, "User Data", `${logData.uid}`), {
        follow_requests: arrayRemove(follower),
      });
      const followerfollowNotification = { "user": `${userData.name}`, "action": "accepted your follow request", "image": `${userData.img}`,"uid": `${logData.uid}`, "timestamp":Date.now() }
      await setDoc(doc(database, "notifications", `${follower}`), {
        notifications: arrayUnion(followerfollowNotification),
      }, { merge: true });

      const updatedNotifications = notificationData[0].notifications.filter(
        (notification) => notification.uid !== follower
      );

      await updateNotifications(updatedNotifications);
    }

    // console.log(notificationData)

  return (
    <>
    <div className='side-notification-sec'>
      <section>
        <div onClick={() => setFollowReq(!followReq)}>
          <p className="text-white">Follow Requests</p>
          <button ><img className='invert' src={followReq ? up_btn : down_btn}/></button>
        </div>
        {followReq ? 
          <section>
            {notificationData[0].notifications?.map((notification, key) => (
              <>
              {notification.action == "requested to follow you"? 
              <div key={key}>
              <div>
                <img src={notification.image} alt={notification.id} />
                {/* <p className='dark:text-white'> <h2>{notification.name}</h2> {notification.action}</p> */}
                <p className="dark:text-white">
                  {" "}
                  <strong>{notification.user}</strong> &nbsp; {notification.action}
                </p>
              </div>
              <div className='request_res'>
                <button onClick={()=>acceptReq(notification.uid)}>Accept</button>
                <button>Delete</button>
              </div>

              {/* <p className="dark:text-white">{notification.time}</p> */}
            </div>
              : 
              null
              }
              </>
            ))}
          </section>
          :
          <></>
        }
      </section>


      <section>
        <p className="text-white">Recents</p>
        <section>
          {notificationData[0].notifications?.map((notification, key) => (
            <div key={key}>
              <div>
                <img src={notification.image} alt={notification.id} />
                {/* <p className='dark:text-white'> <h2>{notification.name}</h2> {notification.action}</p> */}
                <p className="dark:text-white">
                  {" "}
                  <strong>{notification.user}</strong> &nbsp; {notification.action}
                </p>
              </div>
              {/* <div className='request_res'>
                <button>Accept</button>
                <button>Delete</button>
              </div> */}

              {/* <p className="dark:text-white">{notification.time}</p> */}
            </div>
          ))}
        </section>
      </section>
      </div>
    </>
  );
};
