import React, { useContext, useRef, useState } from 'react'
import like_btn from '../Assets/Accouonts Page/like_btn.svg'
import plus_btn_icon from '../Assets/Someone Acc assets/plus_icon.svg'

import { grps, highlights } from './acc_components'
import { AppContext } from '../Services/Context'
import { suggestions } from '../Someones Account/someone_acc_components'
import { Link } from 'react-router-dom'
import scroll_left from "../Assets/Home page 1/scroll left.svg";
import scroll_right from "../Assets/Home page 1/scroll right.svg";

export default function Acc_about({userData, mySuggestion}) {
    
    const [allgrps, setAllGrps] = useState(grps)
    const [allhighlights, setAllHighlights] = useState(highlights)
    const [allsuggestions, setAllSuggestions] = useState(suggestions)
    const [index, setIndex] = useState(0);
    const { logData, allUsers } = useContext(AppContext)

    const containerRef = useRef(null);

    const suggestionList = allUsers.filter(item => 
        // console.log(item);    
        !(userData.following.includes(item.uid)) &&
        !(userData.followers.includes(item.uid)) &&
        !(logData.uid === item.uid)
      );

      const scrollRight = () => {
        if (containerRef.current) {
          containerRef.current.scrollBy({ left: 550, behavior: "smooth" }); // Adjust the scroll amount as needed
          setIndex(index + 1);
        }
      };
    
      const scrollLeft = () => {
        if (containerRef.current) {
          containerRef.current.scrollBy({ left: -550, behavior: "smooth" }); // Adjust the scroll amount as needed
          setIndex(index - 1);
        }
      };

  return (
<>

<div className="account-about-sec">
        <div className="acc-about-description">
            <div>            
                <h2 className='dark:text-white'>Abouts</h2>
                <p className='dark:text-white'>{userData.bio}</p>
            </div>
            {/* <button><img src={like_btn}/> 50</button> */}
        </div>

        {/* <div className="accounts-friends">
            <div>
                <p className='dark:text-white'>Followed groups</p>
                <button className='dark:text-white'>see all</button>
            </div>
            <div>
                {allgrps.map((grp, key) => (
                    <img className={grp.class} src={grp.src} key={key}/>
                ))}                           
                <button className='dark:text-white'>+69</button>   
            </div>
        </div> */}

        {/* <div className="account-highlight">
        {allhighlights.map((highlight, key) => (
            <div key={key}>
                <img src={highlight.src}/>
                <p className='dark:text-white'>{highlight.name}</p>
            </div>
        ))}
        </div> */}

{mySuggestion ? 

  <div className="someone-suggestion my-6 w-[100%]">
    <button className='w-8 dark:invert' onClick={scrollLeft}>
        <img src={scroll_left} />
    </button>

    <section ref={containerRef}>
      {suggestionList.map((suggestion, key) => (
        <Link to={`/someones_page/${suggestion.user_name}`}>
        <div key={key}>
          <img src={suggestion.img} alt={suggestion.id}/>
          <button><img src={plus_btn_icon} /></button>
          <div>
            <p className='dark:text-white'>{suggestion.name}</p>
          </div>
      </div>
      </Link>
      ))}
      </section>
    <button className='w-8 dark:invert' onClick={scrollRight}>
        <img src={scroll_right} />
    </button>
  </div>
:
<></>
}

</div>


</>
   )
}
