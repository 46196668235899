import React, { useContext } from 'react'
import up_arrow from '../Assets/Home page 1/up arrow.svg'
import down_arrow from '../Assets/Home page 1/down arrow.svg'
import { Link } from 'react-router-dom'
import { AppContext } from '../Services/Context'

export default function Settings_Delete_start() {
    const {width} = useContext(AppContext)
  return (
<>

<div className='settings-edit-profile settings-delete-start'>
    <p className='dark:text-white'>Delete Account</p>
    <p>Are you sure you want to delete your current account?</p>
    <section>
        <p className='dark:text-white'>When you delete your account, your profile, photos, videos, comments, likes and followers will be permanently removed. You can also choose to take a break and temporarily deactivate your account instead.</p>
        <div>
            <section>
                <p className='dark:text-white'>Deactivate Account Temporarily</p>
                <button><img src={down_arrow} /></button>
            </section>
            <section>
                <p className='text-[#626262]'>If you temporarily deactivate your account, your profile, photos, comments and likes will be hidden until you reactivate it by logging back in. You can only deactivate your Twigyy account from a computer, a mobile browser or the Twigyy app for iPhone.<br/>Bear in mind that you can only deactivate your account once a week.</p>
                {width <= 500 ? <Link to="/why_deactivate">
                    <button className='hover:bg-gray-300 bg-[#BDFA3C] px-14 py-1 mt-10 rounded-full text-base font-medium w-80'>Deactivate Account</button>
                </Link> :
                <Link to="/settings_page/why_deactivate">
                    <button className='hover:bg-gray-300 bg-[#BDFA3C] px-14 py-1 mt-10 rounded-full text-base font-medium w-80'>Deactivate Account</button>
                </Link>
                }
                

            </section>
        </div>
        <div>
            <section>
                <p className='dark:text-white'>Deactivate Account Temporarily</p>
                <button><img src={down_arrow} /></button>
            </section>
            <section>
                <p className='text-[#626262] bg-[#D9D9D9] p-5 rounded-[20px] mt-4'>When you delete your account, your Profile, photos, videos, comments, likes and followers will be permanently removed. If you'd just like to take a break, you can temporarily deactivate your account instead.</p>

                <p className='text-[#626262] rounded-[20px] mt-4'>For security reasons, we can't delete an account for you. You'll need to be able to log in to your account to request deletion. If you can't remember your password or username, take a look at some tips for logging in.
                Before deleting your account, you may want to log in and download a copy of your information (such as your photos and posts) from TWIGYY. After your account has been deleted, you will not have access to TWIGYY's Data Download tool.</p>
                {width <= 500 ?
                <Link to="/why_delete">
                    <button className='hover:bg-gray-300 bg-red-500 px-14 py-1 mt-10 rounded-full text-base font-medium w-80'>Delete Account</button>
                </Link>
                : 
                <Link to="/settings_page/why_delete">
                    <button className='hover:bg-gray-300 bg-red-500 px-14 py-1 mt-10 rounded-full text-base font-medium w-80'>Delete Account</button>
                </Link>
                }
            </section>
        </div>
    </section>
</div>

</>
  )
}
