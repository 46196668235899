import React, { useEffect, useState } from 'react'
import imageCompression from 'browser-image-compression';

const getImageAsBlob = async (imgUrl)=>{
    // console.log(imgUrl)
    const res = await fetch(imgUrl);
    const blob = await res.blob();
    return blob
}

const compressBlobImage = async (blob)=>{

    const options = {
        maxSize:1,
        maxWidthOrHeight:1920,
        useWebWorker:true
    };

    const compressedFile = await imageCompression(blob, options);
    return compressedFile;
};

const SearchImageCard = ({imgUrl}) => { 

    const [compressedImage, setCompressedImage] = useState(null);
    
    useEffect(()=>{
        let blobUrl = null;
        const processImage = async () =>{
            try{
                // console.log(imgUrl[0])
                const blob = await getImageAsBlob(imgUrl[0]);
                const compressedFile = await compressBlobImage(blob);
                blobUrl = URL.createObjectURL(compressedFile);
                setCompressedImage(blobUrl);
            }
            catch(e){
                console.log("Image processing failed:", e);
            }
        };

        processImage();
        return () =>{
            if(blobUrl){
                URL.revokeObjectURL(blobUrl)
            }
        }
    },[])

    return (
        <img src={compressedImage} />
    )
}

export default SearchImageCard
