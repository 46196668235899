import React, { useState, useContext, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Styles/login_3_confirm_pass.css";
import twigyy from "../Assets/Images/Login Page/twigyy-logo.png";
import google from "../Assets/Images/Login Page/google-logo.png";
import { users } from "./user";
import { AppContext } from "../Services/Context";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "../Services/firebaseconfig";

export default function Login_3_confirm_pass() {
  const [seePass, setSeePass] = useState(false);
  const [seePass1, setSeePass1] = useState(false);
  const { userInfo } = useContext(AppContext);
  const [user, setUser] = useState({ password: "", confirm: "" });
  const [err, setErr] = useState("");

  const navigate = useNavigate();

  function Continue(event) {
    if (user.password == "") {
      setErr("Password can't be blank");
      setUser({ ...user, password:"", confirm:""});
    } else {
      if (user.password.length > 7) {
        if (user.password != user.confirm) {
          setErr("Password and confirm password doesn't match");
          setUser({ ...user, password:"", confirm:""});
        } else {
          userInfo.password = user.password;
          // console.log(userInfo);
          createUserWithEmailAndPassword(
            auth,
            userInfo.email,
            userInfo.password
          )
            .then((user) => {
              sendEmailVerification(user.user)
            })
            .catch((error) => {
              switch (error.code) {
                case "auth/email-already-in-use":
                  console.log(`Email address already in use.`);
                  setErr("Email address already in use.");
                  setUser({ ...user, password:"", confirm:""});
                  // event.preventDefault();
                  break;
                case "auth/invalid-email":
                  console.log(`Email address is invalid.`);
                  break;
                case "auth/operation-not-allowed":
                  console.log(`Error during sign up.`);
                  break;
                case "auth/weak-password":
                  console.log(
                    "Password is not strong enough. Add additional characters including special characters and numbers."
                  );
                  break;
                default:
                  console.log(error.message);
                  break;
              }
            });
        }
      } else {
        setErr("Password must be more than 7 characters");
        setUser({ ...user, password:"", confirm:""});
      }
    }
  }

  const enterToNextPg = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        if(user.password.length > 7 && user.password === user.confirm && err === "") {
          navigate("/login4");
        }
        else {
          navigate("#");
        }
        Continue()
      }
    },
    [user, userInfo]
  );

  useEffect(
    (e) => {
      window.addEventListener("keydown", enterToNextPg);
      return () => {
        window.removeEventListener("keydown", enterToNextPg);
      };
    },
    [enterToNextPg]
  );
  // console.log(err);

  return (
    <>
      <div className="login-header">
        <div className="logo">
          <img className="logo-twigyy" src={twigyy} />
        </div>
        <p className="dark:text-white">Secure Yourself</p>
        <div className="pg3-user-input user-input">
          <div className="new_pass_input">
            <input
              type={`${seePass ? 'text' : 'password'}`}
              onChange={(e) => {
                setUser({ ...user, password: e.target.value });
              }}
              className=" dark:text-white"
              placeholder="enter your password"
              value={user.password !== "" ? user.password : ""}
            />
            <button className="dark:text-[#ffffff4f]" onClick={() => setSeePass(!seePass)}>See</button>
          </div>
          <div className="new_pass_input mt-10">
            <input
              type={`${seePass1 ? 'text' : 'password'}`}
              onChange={(e) => {
                setUser({ ...user, confirm: e.target.value });
              }}
              className=" dark:text-white"
              placeholder="confirm password"
              value={user.confirm}
            />
            <button className="dark:text-[#ffffff4f]" onClick={() => setSeePass1(!seePass1)}>See</button>
          </div>

          {err != "" ? <p className="text-white text-center">{err}</p> : null}
        </div>

        <div className="login-with-ht">
          <div className="down-login-with">
            <hr className="border-1 border-black dark:border-white" />
            <p className="login-with dark:text-white">or login with</p>
            <hr className="border-1 border-black dark:border-white" />
          </div>
          <div className="logo">
            <button>
              <img src={google} alt="Google-logo" />
            </button>
          </div>
          <div className="continue-button continue-btn-rltive">
            <Link
              to={
                user.password.length > 7 &&
                user.password === user.confirm &&
                err === ""
                  ? "/login4"
                  : "#"
              }
            >
              <button onClick={(e) => Continue(e)} className="continue-btn">
                Continue
              </button>
            </Link>
            {/* <p>Take a demo</p> */}
          </div>
        </div>
      </div>
    </>
  );
}
