import React, { useContext, useState } from "react";
import { AppContext } from "../Services/Context";
import { doc, updateDoc } from "firebase/firestore";
import { auth, database } from "../Services/firebaseconfig";
import { signInWithEmailAndPassword } from "firebase/auth";

export default function Settings_Deactivate_acc() {
  const { logData, userData } = useContext(AppContext);
  const [password, setPassword] = useState('');
  const [err, setErr] = useState();
  const [confirm, setConfirm] = useState();

  // console.log(password);

  const Deactivate = () => {
    if(password != ''){
      if (confirm === true) {
        try {
          signInWithEmailAndPassword(auth, userData.email, password)
          .then(async ()=>{
            await updateDoc(doc(database, "User Data", `${logData.uid}`), {
              deactivated: true,
            })
            alert("success")
          }
            )
            .catch((e)=>setErr("wrong password"))
        } catch (e) {
          setErr(e.message);
        }
      } else {
        setErr("Check the confirmation to proceed");
      }
    }else{
      setErr("Password cannot be empty")
    }
    
  };

  return (
    <>
      <div className="settings-edit-profile settings-deactivate-acc">
        <p className="dark:text-white">Deactivate Account</p>
        <p>Are you sure you want to delete your current account?</p>
        <section>
          <p className="dark:text-white">Enter your current password</p>
          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Enter Your Password"
            className="password-input dark:caret-white dark:text-white"
          />
          <div className="flex flex-col items-center mt-5 justify-center">
            <div>
              <input
                onChange={(e) => setConfirm(e.target.checked)}
                id="inline-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="inline-checkbox"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                I confirm deactivation of my account
              </label>
            </div>
            {err ? <p className="text-white mt-2">{err}</p> : null}
            <button
              onClick={() => Deactivate()}
              className="bg-[#BDFA3C] px-14 py-1 mt-10 rounded-full text-base font-medium"
            >
              Deactivate Account
            </button>
          </div>
        </section>
        <p>You can activate your account any time by logging-in</p>
      </div>
    </>
  );
}
