import { initializeApp, } from "firebase/app";
import { getAuth, browserLocalPersistence  } from "firebase/auth";
// import { getDatabase } from "firebase/database"
import {getFirestore} from "firebase/firestore"
import { getStorage } from "firebase/storage";



// Your web app's Firebase configuration
const app = initializeApp({
  apiKey:  process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  persistence: browserLocalPersistence
});

const auth = getAuth(app);
const storage = getStorage(app)
// const database = getDatabase(app)
const database = getFirestore(app)


export { auth, database, storage }

