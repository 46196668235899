import React from 'react'
import lock_icon from "../Assets/Someone Acc assets/Lock icon.svg"
import sad_emoji from "../Assets/Someone Acc assets/Sad emoji.svg"
import side_face from "../Assets/Someone Acc assets/Side face emoji.svg"

export default function Someone_Acc_Lock() {
  return (
    <>
    <div className='lock-someone-profile'>
        <section>
            <h4 className='dark:text-white'>This Profile Is Locked. &nbsp;<img src={lock_icon}/></h4>
        </section>
        <section>
            <h4 className='dark:text-white'>Sorry, Nothing To Show. &nbsp;<img src={sad_emoji}/></h4>
            <img src={side_face}/>
        </section>
    </div>
    </>
  )
}
