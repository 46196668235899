import React from 'react'
import plus_btn from '../Assets/Brand assets/plus_btn.svg'
import msg_btn from '../Assets/Brand assets/message_btn.svg'
import up_btn from '../Assets/Someone Acc assets/up_btn.svg'
import down_btn from '../Assets/Someone Acc assets/down btn.svg'

import shopping_btn from '../Assets/Brand assets/start_shopping.svg'
import deal_btn from '../Assets/Brand assets/deals_btn.jpg'


export default function Brand_actions({accSugest, setAccSugest}) {
  return (
<>
{/* <div className=""> */}
    <div className="action-btn brand-action-btn">
        <button><img src={plus_btn}/></button>
        <button><img src={msg_btn}/></button>
        <button onClick={() => setAccSugest(!accSugest)}>{accSugest ? <img src={up_btn}/> : <img src={down_btn}/>}</button>
    </div>

    {/* <div className="brand-shopping">
        <button><img src={shopping_btn} />Start Shopping</button>
        <button><img src={deal_btn} />Check Today's Deal</button>
    </div> */}
{/* </div> */}

</>
  )
}
