import React from 'react'

import cross_icon from '../Assets/Home page 1/cross icon.svg'
import comment_profile from '../Assets/Home page 1/story 1.png'
import reply_icon from '../Assets/Home page 1/reply icon.svg'
import mic_icon from '../Assets/Messages assets/mic icon.svg'
import emoji_icon from '../Assets/Messages assets/emoji .svg'
import send_icon from '../Assets/Messages assets/send icon.svg'
import empty_like from "../Assets/Home page 1/empty like.svg"
import love_like from '../Assets/Home page 1/love like.svg'

export const Reel_comment = ({setCmnton, cmnton}) => {
  return (
<>

<div className="comment_box reel-cmnt bg-[#919191] dark:bg-[#0b1c1c]">
            <button className='action-cross-btn reel-cmnt-close hover:bg-[#6c6c6ca5] p-2 rounded-full' onClick={() => setCmnton(!cmnton)}><img src={cross_icon} /></button>
            <div className="comment_top">
                <hr />
                <p>Comments</p>
                <hr />
            </div>
            <div>
                <div className="comment_text">
                    <div>
                        <div>
                            <div>
                                <img src={comment_profile} />
                                <p>Clara Albert</p>
                                <p>1 min ago</p>
                            </div>
                            <p>Babu ne thana thaya 🙉 👺</p>
                            <div>
                                <img src={love_like} />
                                <p>1.2k</p>
                                <hr />
                                <img src={reply_icon} />
                                <p>Reply</p>
                            </div>
                        </div>
                    </div>

                    <div className="reply_comment">
                        <em></em>
                        <div className="reply-comment-text">
                            <div>
                                <div>
                                    <div>
                                        <img src={comment_profile} />
                                        <p>Clara Albert</p>
                                        <p>1 min ago</p>
                                    </div>
                                    <p>Babu ne thana thaya 🙉🙉🙉</p>
                                    <div>
                                        <img src={love_like} />
                                        <p>1.2k</p>
                                        <hr />
                                        <img src={reply_icon} />
                                        <p>Reply</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="comment_text">
                    <div>
                        <div>
                            <div>
                                <img src={comment_profile} />
                                <p>Clara Albert</p>
                                <p>1 min ago</p>
                            </div>
                            <p>Babu ne thana thaya 🙉 👺</p>
                            <div>
                                <img src={love_like} />
                                <p>1.2k</p>
                                <hr />
                                <img src={reply_icon} />
                                <p>Reply</p>
                            </div>
                        </div>
                    </div>

                    <div className="reply_comment">
                        <em></em>
                        <div className="reply-comment-text">
                            <div>
                                <div>
                                    <div>
                                        <img src={comment_profile} />
                                        <p>Clara Albert</p>
                                        <p>1 min ago</p>
                                    </div>
                                    <p>Babu ne thana thaya 🙉🙉🙉</p>
                                    <div>
                                        <img src={love_like} />
                                        <p>1.2k</p>
                                        <hr />
                                        <img src={reply_icon} />
                                        <p>Reply</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="comment_text">
                    <div>
                        <div>
                            <div>
                                <img src={comment_profile} />
                                <p>Clara Albert</p>
                                <p>1 min ago</p>
                            </div>
                            <p>Babu ne thana thaya 🙉 👺</p>
                            <div>
                                <img src={love_like} />
                                <p>1.2k</p>
                                <hr />
                                <img src={reply_icon} />
                                <p>Reply</p>
                            </div>
                        </div>
                    </div>

                    <div className="reply_comment">
                        <em></em>
                        <div className="reply-comment-text">
                            <div>
                                <div>
                                    <div>
                                        <img src={comment_profile} />
                                        <p>Clara Albert</p>
                                        <p>1 min ago</p>
                                    </div>
                                    <p>Babu ne thana thaya 🙉🙉🙉</p>
                                    <div>
                                        <img src={love_like} />
                                        <p>1.2k</p>
                                        <hr />
                                        <img src={reply_icon} />
                                        <p>Reply</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="comment_text">
                    <div>
                        <div>
                            <div>
                                <img src={comment_profile} />
                                <p>Clara Albert</p>
                                <p>1 min ago</p>
                            </div>
                            <p>Babu ne thana thaya 🙉 👺</p>
                            <div>
                                <img src={love_like} />
                                <p>1.2k</p>
                                <hr />
                                <img src={reply_icon} />
                                <p>Reply</p>
                            </div>
                        </div>
                    </div>

                    <div className="reply_comment">
                        <em></em>
                        <div className="reply-comment-text">
                            <div>
                                <div>
                                    <div>
                                        <img src={comment_profile} />
                                        <p>Clara Albert</p>
                                        <p>1 min ago</p>
                                    </div>
                                    <p>Babu ne thana thaya 🙉🙉🙉</p>
                                    <div>
                                        <img src={love_like} />
                                        <p>1.2k</p>
                                        <hr />
                                        <img src={reply_icon} />
                                        <p>Reply</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="msg-text chat_send">
                <div>
                    <img src={emoji_icon} />
                    <input placeholder="Type Message" />
                    <img src={mic_icon} />
                </div>
                <section>
                    <img src={send_icon} />
                </section>
            </div>
    </div>

</>
)
}
