export let highlights = [
    {
        "id":"1",
        "src":require('../Assets/Owner brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"2",
        "src":require('../Assets/Owner brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"3",
        "src":require('../Assets/Owner brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"4",
        "src":require('../Assets/Owner brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"5",
        "src":require('../Assets/Owner brand assets/food.jpg'),
        "name":"Food"
    },
    {
        "id":"6",
        "src":require('../Assets/Owner brand assets/food.jpg'),
        "name":"Food"
    },
]

export let posts = [
    {
        "id":"1",
        "src":require('../Assets/Owner brand assets/Post img 1.jpg')
    },
    {
        "id":"2",
        "src":require('../Assets/Owner brand assets/Post img 1.jpg')
    },
    {
        "id":"3",
        "src":require('../Assets/Owner brand assets/Post img 1.jpg')
    },
    {
        "id":"4",
        "src":require('../Assets/Owner brand assets/Post img 1.jpg')
    },
    {
        "id":"5",
        "src":require('../Assets/Owner brand assets/Post img 1.jpg')
    },
    {
        "id":"6",
        "src":require('../Assets/Owner brand assets/Post img 1.jpg')
    }
]