import React, { useState } from 'react'
import like_btn from '../Assets/Brand assets/like_btn.svg'
import { highlights } from './brand_components'

export default function Brand_abouts() {

    const [allhighlights, setAllHighlights] = useState(highlights)

  return (
<>

<div className="s-brand-about-sec">
        <div className="acc-about-description">
            <div>            
                <h2 className='dark:text-white'>Abouts</h2>
                <p className='dark:text-white'>Hi ! Micheal here.My job is to provide job seekers with expert advice on career-related topics. I read a lot and consult recruiting professionals  to.</p>
            </div>
            {/* <button><img src={like_btn}/> 50</button> */}
        </div>

            {/* <h2>Advartisement</h2>
        <div className="brand-advartisement">
            {allhighlights.map((highlight, key) => (
                <div>
                    <img src={highlight.src}/>
                    <p className='dark:text-white'>{highlight.name}</p>
                </div>
            ))}
        </div> */}
</div>


</>
  )
}
