import React, { useContext, useEffect, useState } from 'react'
// import Bookmark_btn from '../Assets/Reels assets/bookmark_btn.svg'
// import Like_btn from '../Assets/Reels assets/like_btn.svg'
// import Share_btn from '../Assets/Reels assets/share_btn.svg'
// import Comment_btn from '../Assets/Reels assets/comment_btn.svg'
import option from '../Assets/Reels assets/three dot.svg'
import empty_like from "../Assets/Home page 1/empty like.svg"
import love_like from '../Assets/Home page 1/love like.svg'
import Music_btn from '../Assets/Reels assets/music_btn.svg'
import comment_btn from "../Assets/Home page 1/comment.svg"
import share_btn from '../Assets/Home page 1/share 2.svg'
import save_btn from "../Assets/Home page 1/save icon.svg"


import save_2 from '../Assets/Home page 1/save 2.svg'
import download from '../Assets/Home page 1/download.svg'
import report from '../Assets/Home page 1/report.svg'
import share_icon from '../Assets/Home page 1/share 2.svg'
import why_see from '../Assets/Home page 1/why see.svg'
import not_int from '../Assets/Home page 1/not interested.svg'

import Music_bg from '../Assets/Reels assets/music bg 2.jpeg'
import { arrayUnion, doc, getDoc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore'
import { database } from '../Services/firebaseconfig'
import { AppContext } from '../Services/Context'
import { Reel_comment } from './Reel_comment'
import { Reel_share } from './Reel_share'
import { Link } from 'react-router-dom'

export default function Reel_Action_Sec({uid, logData, reel_id, reel, setCmnton, cmnton, setShareOn}) {

    const [threedot, setThreedot] = useState(false)
    // const [cmnton, setCmnton] = useState(false)
    // const [shareOn, setShareOn] = useState(false)
    const [imgSrc, setImgSrc] = useState(null)
    const [twigUser, setTwigUser] = useState()
    const [user, setUser] = useState()
    const [liked, setLiked] = useState(false)
    const [likeCount, setLikeCount] = useState()

    // console.log(reel);
    async function setUserImage(uid) {
        const docRef = doc(database, "User Data", `${uid}`);
        const docSnap = await getDoc(docRef);
        const profile_img = docSnap.data().img;
        setImgSrc(profile_img);
        return profile_img;
    }
  
    async function setUserName(uid) {
        const docRef = doc(database, "User Data", `${uid}`);
        const docSnap = await getDoc(docRef);
        const profile_img = docSnap.data().name;
        setTwigUser(profile_img);
        return profile_img;
    }
  
    async function getUser(uid) {
        const docRef = doc(database, "User Data", `${uid}`);
        const docSnap = await getDoc(docRef);
        const profile_img = docSnap.data().user_name;
        setUser(profile_img);
        return profile_img;
    }
  
    // setTwigComment(content.comment)
    setUserImage(reel.uid)
    setUserName(reel.uid)
    getUser(reel.uid)


    useEffect(() => {
        if (reel?.react.like) {

            if (reel.react.like.includes(logData.uid)) {
                setLiked(true)
            }
            else {
                setLiked(false)
            }
        }
    }, [])

    useEffect(() => {
        const postRef = doc(database, "Takes", reel_id);
        const unsubscribe = onSnapshot(postRef, (snapshot) => {
            if (snapshot.exists) {
                const postData = snapshot.data();
                const newLikeCount = postData.react?.like ? postData.react.like.length : 0;
                setLikeCount(newLikeCount);
            }
        });

        return () => unsubscribe(); // Cleanup the listener when the component unmounts
    }, [reel.docId]);


    async function like_update(id) {
        await setDoc(doc(database, "Takes", id), {
            react: { like: arrayUnion(logData.uid) }
        }, { merge: true })
        setLiked(true)
    }

    async function unlike_update(id) {
        try {
            // Get the current document
            const docRef = doc(database, "Takes", id);
            const docSnapshot = await getDoc(docRef);

            // Get the current array
            const currentLikes = docSnapshot.data().react.like;

            // Find the index of logData.uid in the array
            const indexToRemove = currentLikes.indexOf(logData.uid);

            if (indexToRemove !== -1) {
                // If logData.uid is found, remove it from the array
                currentLikes.splice(indexToRemove, 1);

                // Update the document with the modified array
                await updateDoc(docRef, {
                    react: { like: currentLikes }
                });

                // Set the liked state to false
                setLiked(false);
            } else {
                // logData.uid not found in the array
                // console.log("logData.uid not found in the like array");
            }
        } catch (e) {
            // console.log(e);
        }
    }
  

  return (
<>

<div className="action-sec">
    <button onClick={() => liked ? unlike_update(reel_id) : like_update(reel_id)}>
    {liked ? <img src={love_like} /> : <img className='dark:invert' src={empty_like} />}
        <p className='text-black dark:text-white'>{likeCount}</p>
    </button>

    <button onClick={() => setShareOn(true)}>
        <img src={share_btn}/>
        <p className='text-black dark:text-white'>12</p>
    </button>
    {/* {shareOn ? 
        <Reel_share reel_id={reel_id} setShareOn={setShareOn}/>
        :
        <></>
    } */}

    <button onClick={() => setCmnton(!cmnton)}>
        <img src={comment_btn}/>
        <p className='text-black dark:text-white'>60</p>
    </button>
    {/* {cmnton ? 
        <Reel_comment setCmnton={setCmnton} cmnton={cmnton} />
    : <></>} */}
    

    <button className='threedot-rel' onClick={() => setThreedot(!threedot)}>
        <img src={option}/>
        {threedot ? 
            <div className="settings-three-dot reel-threedot">
                <div>
                    <section>
                        <div>
                            <img src={save_2} />
                        </div>
                        <p>Save</p>
                    </section>
                    <section>
                        <div>
                            <img src={share_icon} />
                        </div>
                        <p>Share</p>
                    </section>
                    <section>
                        <div>
                            <img src={not_int} />
                        </div>
                        <p>Not interest</p>
                    </section>
                </div>
                <div>
                    <section>
                        <div>
                            <img src={download} />
                        </div>
                        <p>Download</p>
                    </section>
                    <section>
                        <div>
                            <img src={report} />
                        </div>
                        <p>Report</p>
                    </section>
                    <section>
                        <div>
                            <img src={why_see} />
                        </div>
                        <p>Why see this?</p>
                    </section>
                </div>

            </div>
        :
            <></>
        }
    </button>
    

    <div>
        <button className="music-btn">
            {/* <img className="music-bg-img" src={Music_bg}/> */}
            <img className="music-bg-img" src={imgSrc}/>
            <img src={Music_btn}/>
        </button>
        <Link to={uid === logData.uid ? '/accounts_page' : `/someones_page/${user}`} state={{ uid: uid, user: user }}>
            <button className="more-music-btn">
                <img src={imgSrc}/>
            </button>
        </Link>
    </div>
    <button><img src={save_btn}/></button>
</div>

{/* Comment */} 
        {/* <button className='action-cross-btn hover:bg-[#6c6c6ca5] p-2 rounded-full'><img src={cross_icon} /></button> */}
    
</>
  )
}
