import React, { useContext, useEffect, useRef, useState } from 'react'
import '../Styles/reels_page.css'
import Reel_Middle_Sec from './Reel_Middle_Sec'
import Reel_Action_Sec from './Reel_Action_Sec'
import Reel_Right_Sec from './Reel_Right_Sec'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../Services/Context'
import { doc, onSnapshot } from 'firebase/firestore'
import { database } from '../Services/firebaseconfig'
import twigyy_logo from "../Assets/Images/Login Page/twigyy-logo_2.png";
import { Reel_comment } from './Reel_comment'
import { Reel_share } from './Reel_share'



export default function Reels_page() {
  const navigate = useNavigate();
  const { allTakes, logData, width } = useContext(AppContext)
  const [scrollTriggered, setScrollTriggered] = useState(false);
  const [take, setTake] = useState();
  const [isLoading, setIsLoading] = useState();
  const [cmnton, setCmnton] = useState(false)
  const [shareOn, setShareOn] = useState(false)
  const scrollContainerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0)

  const [isTouchpad, setIsTouchpad] = useState(false);
  const [lastEventTime, setLastEventTime] = useState(Date.now());

  // let currentIndex = 0

  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollTriggered && allTakes.length > 0) {
        setScrollTriggered(true);
        
      // Newly Added
        const now = Date.now();
        const timeDiff = now - lastEventTime;
        setLastEventTime(now);

        const deltaYAbs = Math.abs(event.deltaY);
      // Newly added

        // Select a random reel from the reels array
        // const randomIndex = Math.floor(Math.random() * allTakes.length);
        // const nextReelId = allTakes[randomIndex].docId;
        // const nextReelUrl = `/reel_page/${nextReelId}`;

        let nextIndex;
        // console.log(currentIndex)

        if (deltaYAbs < 10) {
          // Likely touchpad based on deltaY
          setIsTouchpad(true);
        } else if (timeDiff < 50) {
          // Likely touchpad based on rapid event firing
          setIsTouchpad(true);
        } else {
          // Likely mouse wheel
          setIsTouchpad(false);
            if (event.deltaY > 0) {
              // Scroll forward
              // nextIndex = (currentIndex + 1) % allTakes.length;
              setCurrentIndex((prevIndex)=>(prevIndex+1)%allTakes.length)
              // console.log(currentIndex)
            } else if (event.deltaY < 0) {
              // Scroll backward
              // nextIndex = (currentIndex - 1 + allTakes.length) % allTakes.length;
    
              // console.log(currentIndex)
              
              setCurrentIndex((prevIndex)=>prevIndex === 0 ? allTakes.length-1 : (prevIndex-1)%allTakes.length)
            } else {
              return;
            }
        }

        // console.log('Current Index:', currentIndex);
        // console.log('Next Index:', nextIndex);

        // Ensure nextIndex is within bounds
        nextIndex = (nextIndex + allTakes.length) % allTakes.length;

        
        // console.log(currentIndex)
        const nextReelId = allTakes[currentIndex].docId;
        let nextReelUrl = '';
        {width <= 570 ?
          nextReelUrl = `/reel_page_mob/${nextReelId}`
          :
          nextReelUrl = `/reel_page/${nextReelId}`
        };

        // currentIndex = nextIndex;

        setCmnton(false)
        setShareOn(false)
        navigate(nextReelUrl);

        // console.log(scrollTriggered)

        setTimeout(() => {
          setScrollTriggered(false);
        }, 500); // Delay in milliseconds, adjust as needed
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', handleScroll);

      return () => {
        scrollContainer.removeEventListener('wheel', handleScroll);
      };
    }
  }, [allTakes, navigate, scrollTriggered]);

  useEffect(() => {
    // Reset scrollTriggered after navigation
    if (scrollTriggered) {
      setScrollTriggered(false);
    }
  }, [scrollTriggered]);

  const takeid = decodeURI(window.location.pathname.split("/").slice(-1)[0]);

  useEffect(() => {
    const fetchData = () => {
      const takeid = decodeURI(window.location.pathname.split("/").slice(-1)[0]);
      try {
        // console.log(takeid);
        const docRef = doc(database, "Takes", takeid);
        const unsubscribe = onSnapshot(docRef, (doc) => {
          setTake(doc.data());
          setIsLoading(false); // Update loading state
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false); // Update loading state in case of error
      }
    };

    const randomIndex = Math.floor(Math.random() * allTakes.length);


    if (window.location.pathname === '/reel_page') {
      const nextReelId = allTakes[randomIndex].docId;
      navigate(`/reel_page/${nextReelId}`)
      fetchData()
    }
    if (window.location.pathname === '/reel_page_mob') {
      const nextReelId = allTakes[randomIndex].docId;
      navigate(`/reel_page_mob/${nextReelId}`)
      fetchData()
    }

    fetchData();
  }, [scrollTriggered]); // Call effect when state changes

  if (isLoading) {
    return <img src={twigyy_logo} alt="twigyy logo" />;
  }

  // console.log(take);

  return (
    <>
      <div className="reel_main" ref={scrollContainerRef}>
        {take ? < Reel_Middle_Sec setShareOn={setShareOn} setCmnton={setCmnton} cmnton={cmnton} take={take} reel_id={takeid} /> : <></>}
        {/* {take?
          < Reel_Action_Sec uid={take.uid} reel={take} reel_id={takeid} logData={logData}/>
          :<></>
        } */}
        {/* < Reel_Right_Sec /> */}
      </div>
      {cmnton ?
        <Reel_comment setCmnton={setCmnton} cmnton={cmnton} />
        :
        <></>
      }
      {shareOn ?
        <Reel_share reel_id={takeid} setShareOn={setShareOn} />
        :
        <></>
      }
    </>
  )
}
