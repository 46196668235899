import React, { useCallback, useEffect } from 'react'
import twigyy from "../Assets/Images/Login Page/twigyy-logo.png";
import '../Styles/Type_of_acc.css'
import { Link, useNavigate } from 'react-router-dom';

export default function Type_of_acc() {

    const navigate = useNavigate();

    const enterToNextPg = useCallback(
        (e) => {
          const { key, keyCode } = e;
          if (keyCode === 13) {
            navigate("/login2");
          }
        },
        []
      );
    
      useEffect(
        (e) => {
          window.addEventListener("keydown", enterToNextPg);
          return () => {
            window.removeEventListener("keydown", enterToNextPg);
          };
        },
        [enterToNextPg]
      );
  return (
<>

<div className='acc-type-main dark:text-white'>
    <div className="logo">
        <img className="pg2-logo-twigyy logo-twigyy" src={twigyy} />
    </div>
    <p>Choose the type of your profile</p>

    <div className='select-type-acc'>
        <button>Personal</button>
        <button>Business</button>
    </div>

    <Link to='/login2'>
        <div className='acc-type-continue-btn'>
            <button>Continue</button>
        </div>
    </Link>
</div>

</>
    )
}
