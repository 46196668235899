import React from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import './SideBar.css'

import twigyy from '../Assets/Images/Login Page/twigyy-logo.png'
import home_icon from '../Assets/Nav-Icon/home.svg'
import message_icon from '../Assets/Nav-Icon/message.svg'
import search_icon from '../Assets/Nav-Icon/Search.svg'
import play_icon from '../Assets/Nav-Icon/Play.svg'
import notification_icon from '../Assets/Nav-Icon/Notification.svg'
import settings_icon from '../Assets/Nav-Icon/settings.svg'
import profile_icon from '../Assets/Nav-Icon/profile.svg'

export default function SideBar() {

    const navigate = useNavigate()

    function reload_Twigyy () {
        if(window.location.pathname === '/'){
            window.location.reload()
        }
        navigate('/');
    }
    
    return (
        <>
            <div className="left-side">
                <div onClick={() => reload_Twigyy()}>
                    <img className="twigyy-logo-side-bar" src={twigyy} />
                </div>
                <div className="side-bar">
                    <div>
                        <NavLink to="/" className={({ isActive }) =>
                            isActive ? "side-bar-focus-icon" : "side-bar-normal-icon"
                        }>
                            <img src={home_icon} />
                        </NavLink>
                        <NavLink to='/Message_page' className={({ isActive }) =>
                            isActive ? "side-bar-focus-icon" : "side-bar-normal-icon"
                        }>
                            <img src={message_icon} />
                        </NavLink>
                        <NavLink to='/Search_page' className={({ isActive }) =>
                            isActive ? "side-bar-focus-icon" : "side-bar-normal-icon"
                        }>
                            <img src={search_icon} />
                        </NavLink>
                        <NavLink to="/reel_page" className={({ isActive }) =>
                            isActive ? "side-bar-focus-icon" : "side-bar-normal-icon"
                        }>
                            <img src={play_icon} />
                        </NavLink>
                        <NavLink to="/settings_page" className={({ isActive }) =>
                            isActive ? "side-bar-focus-icon" : "side-bar-normal-icon"
                        }>
                            <img src={settings_icon} />
                        </NavLink>
                        <NavLink to="/accounts_page" className={({ isActive }) =>
                            isActive ? "side-bar-focus-icon" : "side-bar-normal-icon"
                        }>
                            <img src={profile_icon} />
                        </NavLink>
                    </div>
                </div>
            </div>
           
        </>
    )
}
