import { collection, doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { database } from '../Services/firebaseconfig'

export default function Start_shopping() {
    const [deals, setDeals] = useState([])
    useEffect(() => {
        const getDeals = async() => {
            const q = doc(database, "brandDeals", "OmAr24YYN9y5K9wBM781")
            onSnapshot(q, (data) => {
                // setDeals((deals) => [...deals, {...data.data(), id: doc.id}])
                setDeals(data.data())
            } )
        }
        getDeals()
    },[])
  return (
<>

<div className='today-deal'>
    <p className='dark:text-white text-2xl py-7 tracking-[5px]'>Start Shopping</p>

    <div className="img-grid someone-acc-grid">
        {Object.entries(deals)?.map((deal, key) => (
            <div className='deals-post'>
                <p>Rs. {deal[1].dealOffer}</p>
                <img src={deal[1].dealImg}/>
                <button>Buy Now/Contact Seller</button>
            </div>
        ))}
    </div>
</div>


</>
  )
}
