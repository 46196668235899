import React, { useState } from 'react'
import down_arrow from '../Assets/Home page 1/down arrow.svg'
import profile_img from '../Assets/Home page 1/post img.png'
export default function Settings_Blocked() {
    const [seeBlocked, setSeeBlocked] = useState(false)
  return (
<>

<div className='settings-edit-profile settings-blocked'>
    <p className='dark:text-white'>Blocked Accounts</p>
    <p>You Can Unblock Accounts Any Time</p>
    <section>
        <section className='blocked-accs'>
            <div onClick={() => setSeeBlocked(!seeBlocked)}>
                <img src={profile_img}/>
                <p className='dark:text-white'>Tirtha Bhattacharjee</p>
            </div>
            {seeBlocked ? <>
                <div>
                    <p className='dark:text-white'>Blocked</p>
                    <div className="Email-switch">
                        {/* Change the ID and htmlfor for every person blocked */}
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input type="checkbox" value="" className="sr-only peer"/>
                            <div className="mr-4 w-14 h-6 bg-gray-500 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-8 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BDFA3C] after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                        </label>
                    </div>
                </div>
            </> : <></>
            }
        </section>
    </section>
    <p>After un-blocking any account, you can not block the same account before 24 hours</p>
</div>

</>
  )
}
