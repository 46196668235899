import React, { useRef, useState } from 'react'
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'

import scroll_left from "../Assets/Home page 1/scroll left.svg";
import scroll_right from "../Assets/Home page 1/scroll right.svg";

import '../Styles/crop_post.css'

export const Image_Cropper = ({ img, setCaption, croppedImage, setCroppedImage, aspectRatio }) => {

  // console.log(img);
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState([])

  const containerRef = useRef(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {

    setCroppedAreaPixels(croppedAreaPixels)
  }

  // console.log(croppedAreaPixels);

  const showCroppedImage = async (image) => {
    
    try {
      const croppedImg = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )

      // console.log(typeof {croppedImg});
      setCroppedImage(prevCroppedImages => [...prevCroppedImages, croppedImg])
      setCaption(true)
    } catch (e) {
      console.error(e)
    }
  }

  const onClose = () => {
    setCroppedImage(null)
  }

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 100, behavior: "smooth" }); // Adjust the scroll amount as needed
    }
  };

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -100, behavior: "smooth" }); // Adjust the scroll amount as needed
    }
  };


  return (
    <div style={{display:"flex"}}>

      {img.length >1?<>
        <button className=" absolute top-[45%] left-3 scroll-btn" onClick={scrollLeft}>
          <img className="dark:invert" src={scroll_left} />
        </button>
      </>:null}

      <div className='crop-area' style={{ zIndex:1}} ref={containerRef}>
        {img.map((image,key)=>(
        <Cropper
          image={image}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          // aspect={4 / 3}
          aspect={aspectRatio}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          key={key}
        />
        ))}
      </div>
      <div className='crop-continue' style={{zIndex:10}}>
        <button
          onClick={()=>img.forEach(image=>showCroppedImage(image))}
          // onClick={setCaption}
          variant="contained"
          color="primary"
        >
          Show Result
        </button>
      </div>

      {img.length >1?<>
        <button className="absolute top-[45%] right-3 scroll-btn" onClick={scrollRight}>
          <img className="dark:invert" src={scroll_right} />
        </button>
      </>:null}
    </div>
  )
}



