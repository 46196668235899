import './App.css'
import { clarity } from 'react-microsoft-clarity';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LogIn_1 from './Components/Login_page_1'
import LogIn_2 from './Components/Register/login_page_2'
import LogIn_3 from './Components/Register/login_3_confirm_pass'
import LogIn_4 from './Components/Register/login_4_email_verify'
import LogIn_5 from './Components/Register/login_5_build_profile'
import LogIn_6 from './Components/Register/login_6_identification'
import LogIn_7 from './Components/Register/login_7_sub_identification'
import Home_page from './Components/Home Page/home_page'
import Message_page from './Components/Message Page/message_page'
import Reels_page from './Components/Reels Page/Reels_page'
import Search_page from './Components/Search Page/search_page'
import Accounts_page from './Components/Accounts Page/Accounts_page'
import Someones_page from './Components/Someones Account/Someone_account'
import Settings_page from './Components/Settings Page/settings_page'
import Brand_page from './Components/Brand Page/brand_page'
import Owner_brand_page from './Components/Owner Brand Page/owner_brand_page'


import Layout from './Layout';
import Msg_chat_Sec from './Components/Message Page/Msg_chat_Sec';
import MiddleSec from './Components/Message Page/Msg_Middle_Sec';
import Acc_Tales_Post from './Components/Accounts Page/Acc_Tales_Post';
import { auth, database } from './Components/Services/firebaseconfig';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from './Components/Services/Context';
import Settings_edit_profile from './Components/Settings Page/Settings_edit_profile';
import Settings_change_password from './Components/Settings Page/Settings_change_password';
import Settings_Push_Notifi from './Components/Settings Page/Settings_Push_Notifi';
import Settings_Email_Notifi from './Components/Settings Page/Settings_Email_Notifi';
import Settings_Frnd_Interact from './Components/Settings Page/Settings_Frnd_Interact'
import Settings_Language from './Components/Settings Page/Settings_Language';
import Settings_Blocked from './Components/Settings Page/Settings_Blocked';
import Settings_Sensitive_Content from './Components/Settings Page/Settings_Sensitive_Content';
import Settings_Delete_start from './Components/Settings Page/Settings_Delete_start';
import Settings_Why_Delete from './Components/Settings Page/Settings_Why_Delete';
import Settings_Why_Deactivate from './Components/Settings Page/Settings_Why_Deactivate';
import Settings_Deactivate_acc from './Components/Settings Page/Settings_Deactivate_acc';
import Settings_Delete_acc from './Components/Settings Page/Settings_Delete_acc';
import Msg_Friends from './Components/Message Page/Msg_Friends';
import Acc_Mobile_Post from './Components/Accounts Page/Acc_Mobile_Post';
import Acc_Saved_Post from './Components/Accounts Page/Acc_Saved_Post';
import Someone_Mobile_Post from './Components/Someones Account/Someone_Mobile_Post';
import Todays_deal from './Components/Brand Page/Todays_deal';
import Start_shopping from './Components/Brand Page/Start_shopping';
import Type_of_acc from './Components/Register/Type_of_acc';
import Loader from './Components/Register/Loader';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import Home_story_view from './Components/Home Page/Home_story_view';
import { Post_view } from './Components/Post Page/Post_view';
import Story_main from './Components/Story/Story_main';
import TwigCall from './Components/Message Page/Video Call/TwigCall';
import TwigCallPage from './Components/Message Page/Video Call/TwigCallPage';


function App() {
  clarity.init(process.env.REACT_APP_CLARITY_ID);

  const [currentUser, setCurrentUser] = useState()
  // console.log(currentUser);
  const {userData, dark, width, logData} = useContext(AppContext)
  // console.log(userData);

  const idleTimeout = 300000; // 5 minute
  const idleTimeoutRef = useRef(null);
  const isUserOnlineRef = useRef(true); // Initially, user is considered online


  useEffect(()=>{
    setCurrentUser(auth.currentUser)
  },[])

  const ProtectedRoute = ({children}) => {
    // if(userData.length != 0){
    //   return <Navigate to="/login"/>
    // }
    return children
  }

  useEffect(() => {
    const storedTheme = window.localStorage.getItem('THEME');
    // console.log(storedTheme);
    if (storedTheme === "true") {
      // console.log("Hi there 1");
      document.body.classList.add("dark", "body-dark");
    }
    else {
      // console.log("Hi there");
      document.body.classList.remove("dark", "body-dark");
    }
  }, [dark])

  
  useEffect(() => {
    if (logData.length !== 0) {
      const userRef = doc(database, "User Data", logData.uid);
  
      const handleUserActivity = () => {
        if (!isUserOnlineRef.current) {
          // If user was previously marked as offline, update their status to online
          updateDoc(userRef, {
            isOnline: true,
            // lastSeenAt: serverTimestamp(), // Optionally, record the timestamp of the user's last activity
          });
          isUserOnlineRef.current = true;
        }
  
        // Clear idle timeout and reset it
        clearTimeout(idleTimeoutRef.current);
        idleTimeoutRef.current = setTimeout(() => {
          // Set user's online status to false after idleTimeout milliseconds of inactivity
          updateDoc(userRef, {
            isOnline: false,
            // lastSeenAt: serverTimestamp(), // Optionally, record the timestamp of when the user went offline
          });
          isUserOnlineRef.current = false;
        }, idleTimeout);
      };
  
      // Attach event listeners for relevant browser events
      window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("keydown", handleUserActivity);
      window.addEventListener("resize", handleUserActivity);
  
      // Initialize user's online status to true when component mounts
      handleUserActivity();
  
      // Clean up event listeners and idle timeout when component unmounts
      return () => {
        window.removeEventListener("mousemove", handleUserActivity);
        window.removeEventListener("keydown", handleUserActivity);
        window.removeEventListener("resize", handleUserActivity);
        clearTimeout(idleTimeoutRef.current);
  
        // Update user's online status to false when user logs out or closes the app
        if (isUserOnlineRef.current) {
          updateDoc(userRef, {
            isOnline: false,
            // lastSeenAt: serverTimestamp(), // Optionally, record the timestamp of when the user went offline
          });
        }
      };
    }
  }, [logData.uid]);

  // console.log(userData)

  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path='/login' element={userData ? < Home_page /> : <LogIn_1/>} />
            <Route path='/accType' element={< Type_of_acc />} />
            <Route path='/landing' element={< Loader />} />
            <Route path='/login2' element={< LogIn_2 />} />
            <Route path='/login3' element={< LogIn_3 />} />
            <Route path='/login4' element={< LogIn_4 />} />
            <Route path='/login5' element={< LogIn_5 />} />
            <Route path='/login6' element={< LogIn_6 />} />
            <Route path='/login7' element={< LogIn_7 />} />
            {/* <Route path='/home_page' element={userData && userData.length != 0? < Home_page />: <LogIn_1/>} /> */}
            {/* {console.log(userData)} */}
            <Route path='/' element={userData && userData.length != 0? < Layout />: <LogIn_1/>}>
              <Route index element= { <Home_page/>}/>

              <Route path='/message_page' element={< Message_page />}>
                <Route index element={< MiddleSec userData={userData}/>} />
                <Route path='/message_page/chat/:id' element={<Msg_chat_Sec userData={userData}/>} />
                <Route path='/message_page/friends' element={<Msg_Friends />} />
              </Route>
              

              <Route path='/reel_page' element={< Reels_page />}>
                <Route path='/reel_page/:id' element={< Reels_page />}/>
              </Route>

              <Route path='/search_page' element={< Search_page />} />
              <Route path='/accounts_page' element={< Accounts_page />} />
              <Route path='/accounts_page/save_post' element={< Acc_Saved_Post />} />

              <Route path='/someones_page'>
                <Route path='/someones_page/:uid' element={< Someones_page />}/>
              </Route>

              <Route path='/brand_page' element={< Brand_page />} />
              <Route path='/brand_page/todays_deals' element={< Todays_deal />} />
              <Route path='/brand_page/start_shopping' element={< Start_shopping />} />
              <Route path='/owner_brand_page' element={< Owner_brand_page />} />

              <Route path='/settings_page' element={< Settings_page />}>
                <Route index element={< Settings_edit_profile />} />
                <Route path='/settings_page/change_pass' element={< Settings_change_password />} />
                <Route path='/settings_page/push_notifi' element={< Settings_Push_Notifi />} />
                <Route path='/settings_page/email_notifi' element={< Settings_Email_Notifi />} />
                <Route path='/settings_page/frnd_intact' element={< Settings_Frnd_Interact />} />
                <Route path='/settings_page/lang' element={< Settings_Language />} />
                <Route path='/settings_page/blocked' element={< Settings_Blocked />} />
                <Route path='/settings_page/sensitive_content' element={< Settings_Sensitive_Content />} />
                <Route path='/settings_page/delete_start' element={< Settings_Delete_start />} />
                <Route path='/settings_page/why_delete' element={< Settings_Why_Delete />} />
                <Route path='/settings_page/why_deactivate' element={< Settings_Why_Deactivate />} />
                <Route path='/settings_page/deactivate_acc' element={< Settings_Deactivate_acc />} />
                <Route path='/settings_page/delete_acc' element={< Settings_Delete_acc />} />
              </Route>
              {width < 500 ?
              <> 
              <Route path='/setings_edit_pg' element={< Settings_edit_profile />} />
              <Route path='/change_pass' element={< Settings_change_password />} />
              <Route path='/push_notifi' element={< Settings_Push_Notifi />} />
              <Route path='/email_notifi' element={< Settings_Email_Notifi />} />
              <Route path='/frnd_intact' element={< Settings_Frnd_Interact />} />
              <Route path='/lang' element={< Settings_Language />} />
              <Route path='/blocked' element={< Settings_Blocked />} />
              <Route path='/sensitive_content' element={< Settings_Sensitive_Content />} />
              <Route path='/delete_start' element={< Settings_Delete_start />} />
              <Route path='/why_delete' element={< Settings_Why_Delete />} />
              <Route path='/why_deactivate' element={< Settings_Why_Deactivate />} />
              <Route path='/deactivate_acc' element={< Settings_Deactivate_acc />} />
              <Route path='/delete_acc' element={< Settings_Delete_acc />} />
              </>
              : <></>}
            
            </Route>

            <Route path='/videoCall/:callId' element={<TwigCallPage user={logData}/>}/>
            <Route path="/story/:userId/:storyId" element={<Story_main />} />

            <Route path='/story_view' element={< Home_story_view />} />
            <Route path='/reel_page_mob' element={< Reels_page />} >
            <Route path='/reel_page_mob/:id' element={< Reels_page />}/>
            </Route>
            <Route path='/msg/chat/:id' element={<Msg_chat_Sec userData={userData}/>} />
            <Route path='/tales_page' element={< Acc_Tales_Post />} />
            <Route path='/acc_mobile_post/:twigId' element={< Acc_Mobile_Post />} />
            <Route path='/someone_mobile_post/:twigId' element={< Someone_Mobile_Post />} />
            
            <Route path='/twig' >
              <Route path='/twig/post/:id' element={<Post_view />}/>
            </Route>

          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
