import React, { useContext } from 'react'
import './Mobile_nav.css'
import home_icon from '../Assets/Nav-Icon/home.svg'
import message_icon from '../Assets/Nav-Icon/message.svg'
import search_icon from '../Assets/Nav-Icon/Search.svg'
import play_icon from '../Assets/Nav-Icon/Play.svg'
import notification_icon from '../Assets/Home page 1/Notification.svg'
import settings_icon from '../Assets/Nav-Icon/settings.svg'
import profile_icon from '../Assets/Nav-Icon/profile.svg'
import { Link, NavLink } from 'react-router-dom'
import { AppContext } from '../Services/Context'

export default function Mobile_navbar() {
    const {width} = useContext(AppContext)
  return (
<>

<div className="home-nav-bar mobile-main-bar">
    <div className="home-nav-icon">
        <NavLink to='/' className={({ isActive }) =>
                            isActive ? "home-focus-icon" : "home-normal-icon"} >
            <img src={home_icon} />
        </NavLink>
        <NavLink to='/Message_page' className={({ isActive }) =>
                            isActive ? "home-focus-icon" : "home-normal-icon"}>
            <img src={message_icon} />
        </NavLink>
        <NavLink className={({ isActive }) =>
                            isActive ? "home-focus-icon" : "home-normal-icon"} to="/search_page">
            <img src={search_icon} />
        </NavLink>
        {width <=500 ? <NavLink className={({ isActive }) =>
                            isActive ? "home-focus-icon" : "home-normal-icon"} to="/reel_page_mob">
                <img src={play_icon} />
            </NavLink> : 
            <NavLink className={({ isActive }) =>
                                isActive ? "home-focus-icon" : "home-normal-icon"} to="/reel_page">
                <img src={play_icon} />
            </NavLink>
        }
        {/* <NavLink>
            <button className="home-normal-icon"><img src={notification_icon} /></button>
        </NavLink> */}
        <NavLink className={({ isActive }) =>
                            isActive ? "home-focus-icon" : "home-normal-icon"} to="/settings_page">
            <img src={settings_icon} />
        </NavLink>
        <NavLink className={({ isActive }) =>
                            isActive ? "home-focus-icon" : "home-normal-icon"} to="/accounts_page">
            <img src={profile_icon} />
        </NavLink>
    </div>
</div>

</>
  )
}
