import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useNavigate } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { database } from '../../Services/firebaseconfig';
import TwigCall from './TwigCall';

function TwigCallPage({ user }) {
  const { callId } = useParams();

  console.log(callId)
  const navigate = useNavigate();
  const [callStatus, setCallStatus] = useState(null); 

  useEffect(() => {
    const callRef = doc(database, 'calls', callId);
    const unsubscribe = onSnapshot(callRef, (snapshot) => {
      if (snapshot.exists()) {
        const newCallStatus = snapshot.data();
        setCallStatus(newCallStatus);
        if (newCallStatus.status === 'ended') {
          navigate('/message_page');
        }
      } else {
        navigate('/message_page');
      }
    });

    return () => unsubscribe();
  }, [callId, navigate]);

  if (!callStatus) {
    return <div>Loading call...</div>;
  }

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <h1>Call with {callStatus.caller === user.uid ? callStatus.recipientName : callStatus.callerName}</h1>
      <TwigCall callStatus={callStatus} callId={callId} />
    </div>
  );
}

export default TwigCallPage;