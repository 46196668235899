import React, { useEffect, useState } from 'react'
import pata from "../Assets/Images/Loader.svg"
import twigyy_T from "../Assets/Images/Loader T.svg"
import '../Styles/Loader.css'

export default function Loader() {
  return (
<>

<div className='loader-main bg-white dark:bg-black'>
    <div className='loader-grp'>
        <section className='loader-icon-sec'>
            <img src={pata} />
        </section>

            <img className='loader-t' src={twigyy_T} />
    </div>
    <div className='loader-text dark:text-white'>
      <p>Connect</p>
      <p>Create</p>
      <p>Inspire</p>
    </div>
</div>

</>
  )
}
