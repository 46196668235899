import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../Services/Context'

export default function Settings_Why_Delete() {
    const {width} = useContext(AppContext)
  return (
<>

<div className='settings-edit-profile settings-why-delete'>
    <p className='dark:text-white'>Delete Account</p>
    <p>If you want a break from Twigyy, you can temporarily deactivate your account instead of deleting it. Your profile won't appear on Twigyy while you're away.</p>
    <section>
        <p className='dark:text-white'>Why do you want to delete?</p>
        <section>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="why delete" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="why delete" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Created a second account</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="why delete" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="why delete" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Can't find people to follow</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="why delete" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="why delete" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Too busy/too distracting</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="why delete" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="why delete" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Too many ads</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="why delete" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="why delete" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Trouble getting started</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="why delete" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="why delete" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Want to remove something</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="why delete" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="why delete" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Privacy concerns</label>
            </div>
            <div className="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="" name="why delete" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="why delete" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Something else</label>
            </div>
            <textarea placeholder='Can you please explain the reason' />
        </section>
        {width <= 500 ? 
        <Link to="/delete_acc" className='self-center '>
            <button className='hover:bg-gray-300 bg-[#BDFA3C] px-14 py-1 mt-10 rounded-full text-base font-medium w-80'>Continue</button>
        </Link>
        : 
        <Link to="/settings_page/delete_acc" className='self-center '>
            <button className='hover:bg-gray-300 bg-[#BDFA3C] px-14 py-1 mt-10 rounded-full text-base font-medium w-80'>Continue</button>
        </Link>
        }
    </section>
</div>

</>
  )
}
