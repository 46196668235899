import React from 'react'

export default function Settings_Language() {
  return (
<>

<div className='settings-edit-profile settings-language'>
    <p className='dark:text-white'>Language</p>
    <div className="lang-search-bar">
        <input placeholder="Search Any Language To Select"/>
    </div>
    <section>
        <button className='dark:text-white'>English (UK)</button>
        <button className='dark:text-white'>English</button>
        <button className='dark:text-white'>Afrikaans</button>
        <button className='dark:text-white'>Čeština</button>
        <button className='dark:text-white'>Dansk</button>
        <button className='dark:text-white'>Deutsch</button>
        <button className='dark:text-white'>Español (España)</button>
        <button className='dark:text-white'>Español</button>
        <button className='dark:text-white'>Suomi</button>
        <button className='dark:text-white'>Français</button>
        <button className='dark:text-white'>বাংলা</button>
        <button className='dark:text-white'>हिन्दी</button>
    </section>
</div>

</>
  )
}
