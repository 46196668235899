import React, { useState, useRef, useEffect, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import '../Styles/home_page.css'

import twigyy from '../Assets/Images/Login Page/twigyy-logo.png'

import three_dot from "../Assets/Home page 1/three dot.svg"
import save_btn from "../Assets/Home page 1/save icon.svg"
import like_btn from "../Assets/Home page 1/like.png"
import empty_like from "../Assets/Home page 1/empty like.svg"
import love_like from '../Assets/Home page 1/love like.svg'
import comment_btn from "../Assets/Home page 1/comment.svg"
import share_btn from '../Assets/Home page 1/share 2.svg'

import home_icon from '../Assets/Nav-Icon/home.svg'
import message_icon from '../Assets/Nav-Icon/message.svg'
import search_icon from '../Assets/Nav-Icon/Search.svg'
import play_icon from '../Assets/Nav-Icon/Play.svg'
import notification_icon from '../Assets/Home page 1/Notification.svg'
import settings_icon from '../Assets/Nav-Icon/settings.svg'
import profile_icon from '../Assets/Nav-Icon/profile.svg'
import { stories, contents, comment } from './Home_pg_Components'

import save_2 from '../Assets/Home page 1/save 2.svg'
import download from '../Assets/Home page 1/download.svg'
import report from '../Assets/Home page 1/report.svg'
import share_icon from '../Assets/Home page 1/share 2.svg'
import why_see from '../Assets/Home page 1/why see.svg'
import not_int from '../Assets/Home page 1/not interested.svg'
import add_fav from '../Assets/Home page 1/favourites.svg'
import change_int from '../Assets/Home page 1/change interest.svg'
import account from '../Assets/Home page 1/account.svg'

import story_plus from '../Assets/Home page 1/stroy plus.svg'
import scroll_left from '../Assets/Home page 1/scroll left.svg'
import scroll_right from '../Assets/Home page 1/scroll right.svg'
import scroll_up from '../Assets/Home page 1/scroll_up_arr.svg'
import profile_photo from '../Assets/Home page 1/story 1.png'

import comment_profile from '../Assets/Home page 1/story 1.png'
import useComponentVisible from '../Hooks/useComponent'
import { AppContext } from '../Services/Context'
import { arrayRemove, arrayUnion, doc, getDoc, onSnapshot, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore'
import { database } from '../Services/firebaseconfig'
import Home_share from './Home_share'
import Mobile_navbar from '../SideBar/Mobile_navbar'
import Home_notification from './Home_notification'
import Comment from '../Comment/Comment'
import { Bounce, toast, ToastContainer } from 'react-toastify'


const HomeTwigCards = ({ uid, userData, content, isComment, setIsComment, logData, setScrollPosition, scrollPosition, setIsShare, setTwigComment, setTwigId }) => {

    const [user, setUser] = useState()
    const [isOpen, setIsOpen] = useState(true);
    const btnRef = useRef();
    const [allcontents, setAllContents] = useState(contents)
    const [imgSrc, setImgSrc] = useState(null)
    const [twigUser, setTwigUser] = useState()

    const [likeCount, setLikeCount] = useState(content.react.like.length)
    const [liked, setLiked] = useState(false)
    const [index, setIndex] = useState(0)
    const [savedTwig, setSavedTwig] = useState()
    const containerRef = useRef(null);
    // console.log(likeCount);
    const { width } = useContext(AppContext)

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(allcontents.reduce((visible, { id }) => ({ ...visible, [id]: false }), {}))


    async function setUserImage(uid) {
        const docRef = doc(database, "User Data", `${uid}`);
        const docSnap = await getDoc(docRef);
        const profile_img = docSnap.data().img;
        setImgSrc(profile_img);
        return profile_img;
    }

    async function setUserName(uid) {
        const docRef = doc(database, "User Data", `${uid}`);
        const docSnap = await getDoc(docRef);
        const profile_img = docSnap.data().name;
        setTwigUser(profile_img);
        return profile_img;
    }

    async function getUser(uid) {
        const docRef = doc(database, "User Data", `${uid}`);
        const docSnap = await getDoc(docRef);
        const profile_img = docSnap.data().user_name;
        setUser(profile_img);
        return profile_img;
    }

    // setTwigComment(content.comment)

    // try{
        setUserImage(uid)
        setUserName(uid)
        getUser(uid)
    // }
    // catch(e){
    //     console.log("Uid not found", e.message)
    // }


    function Comment_on() {
        setIsComment(true)
        setScrollPosition(window.scrollY)
    }

    function threeOff(id) {
        setIsComponentVisible((currId) => ({
            ...currId, [id]: !currId[id]
        }))
    }

    useEffect(() => {
        if (userData.savedTwigs) {

            if (userData.savedTwigs.includes(content.docId)) {
                setSavedTwig(true)
            }
            else {
                setSavedTwig(false)
            }
        }
    }, [])

    useEffect(() => {
        if (content.react.like) {

            if (content.react.like.includes(logData.uid)) {
                setLiked(true)
            }
            else {
                setLiked(false)
            }
        }
    }, [])

    useEffect(() => {
        const postRef = doc(database, "Twigs", content.docId);

        const unsubscribe = onSnapshot(postRef, (snapshot) => {
            if (snapshot.exists) {
                const postData = snapshot.data();
                const newLikeCount = postData.react.like ? postData.react.like.length : 0;
                setLikeCount(newLikeCount);
            }
        });

        return () => unsubscribe(); // Cleanup the listener when the component unmounts
    }, [content.docId]);

    async function like_update(id) {
        await setDoc(doc(database, "Twigs", id), {
            react: { like: arrayUnion(logData.uid) }
        }, { merge: true })
        const followNotification = { "user": `${userData.name}`, "action": "has liked your post", "image": `${userData.img}`, "postId": `${id}`, "timestamp":serverTimestamp() }
        await setDoc(doc(database, "notifications", `${content.uid}`), {
            notifications: arrayUnion(followNotification),
        }, { merge: true });
        setLiked(true)
    }

    async function unlike_update(id) {
        try {
            // Get the current document
            const docRef = doc(database, "Twigs", id);
            const docSnapshot = await getDoc(docRef);

            // Get the current array
            const currentLikes = docSnapshot.data().react.like;

            // Find the index of logData.uid in the array
            const indexToRemove = currentLikes.indexOf(logData.uid);

            if (indexToRemove !== -1) {
                // If logData.uid is found, remove it from the array
                currentLikes.splice(indexToRemove, 1);

                // Update the document with the modified array
                await updateDoc(docRef, {
                    react: { like: currentLikes }
                });

                // Set the liked state to false
                setLiked(false);
            } else {
                // logData.uid not found in the array
                // console.log("logData.uid not found in the like array");
            }
        } catch (e) {
            // console.log(e);
        }
    }
    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: 550, behavior: "smooth" }); // Adjust the scroll amount as needed
            setIndex(index + 1)
        }
    };

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: -550, behavior: "smooth" }); // Adjust the scroll amount as needed
            setIndex(index - 1)
        }
    };

    const handleScroll = () => {
        // Assuming each slide has the same width
        const slideWidth = containerRef.current.scrollWidth / content.image.length;
        const newActiveIndex = Math.floor(
            (containerRef.current.scrollLeft + slideWidth / 2) / slideWidth
        );
        setIndex(newActiveIndex);
    };

    async function SaveTwig() {
        setSavedTwig(true)
        await updateDoc(doc(database, "User Data", logData.uid), {
            savedTwigs: arrayUnion(content.docId)
        })
    }

    async function UnSaveTwig() {
        setSavedTwig(false)
        await updateDoc(doc(database, "User Data", logData.uid), {
            savedTwigs: arrayRemove(content.docId)
        })
    }


    return (
        <div className="dark:bg-black bg-[#dcdcdc] shadow-homeBox dark:shadow-homeBoxDark box" key={content.id}>
            <div className="content-head">
                {/* {console.log(content.docId)} */}
                <Link to={`/someones_page/${user}`} state={{ uid: uid, user: user, scrollPosition:scrollPosition }}>
                    <img src={imgSrc} className='home-profile-img' />
                </Link>
                <div>
                    <Link to={`/someones_page/${user}`} state={{ uid: uid, user: user, scrollPosition:scrollPosition }}>
                        <p className='text-dlack dark:text-white'>{twigUser}</p>
                    </Link>
                    <p className='dark:text-white'>{content.time}</p>
                </div>

                <section>
                    <button onClick={() => threeOff(content.id)}><img src={three_dot} className='dark:invert' /></button>

                    {isComponentVisible[content.id] ? <>
                        <div className="settings-three-dot">
                            <div>
                                <section>
                                    <div onClick={() => SaveTwig()}>
                                        <img src={savedTwig ? save_2 : save_btn} />
                                    </div>
                                    <p>Save</p>
                                </section>
                                <section onClick={() => setIsShare(true)}>
                                    <div>
                                        <img src={share_icon} />
                                    </div>
                                    <p>Share</p>
                                </section>
                                <section>
                                    <div>
                                        <img src={not_int} />
                                    </div>
                                    <p>Not interest</p>
                                </section>
                            </div>
                            <div>
                                <section>
                                    <div>
                                        <img src={download} />
                                    </div>
                                    <p>Download</p>
                                </section>
                                <section>
                                    <div>
                                        <img src={report} />
                                    </div>
                                    <p>Report</p>
                                </section>
                                <section>
                                    <div>
                                        <img src={why_see} />
                                    </div>
                                    <p>Why see this?</p>
                                </section>
                            </div>

                        </div>
                    </> :
                        <>
                        </>
                    }

                </section>
            </div>
            <div className="content-img" ref={containerRef} onScroll={handleScroll}>
                {content.image.length > 1 &&
                    <>
                        {index !== 0 &&
                            // <></>
                            // :
                            <button onClick={scrollLeft}>
                                <img className="dark:invert" src={scroll_left} />
                            </button>
                        }
                </>}
                    {/* // : <></> */}

                {width <= 500 ? <>
                    {content.image.map((image, key) => (
                        // <div key={key}>
                        <Link
                            to={`/acc_mobile_post/${content.docId}`}
                            state={{ feed: content, twigId: content.docId, scrollPosition:scrollPosition }}
                            key={key}
                        >
                            {image.match(/\.([0-9a-z]+)(?:[?#]|$)/i)[1] == "mp4" ?
                                <video src={image} className='shadow-homeTwigShdo dark:shadow-none' controls></video>
                                :
                                <img src={image} className='shadow-homeTwigShdo dark:shadow-none' />
                            }
                        </Link>
                        // </div>
                    ))}
                </>
                    :
                    <>
                        {content.image?.map((image, key) => (
                            <Link to={`/twig/post/${content.docId}`} state={{ twig: content, scrollPosition:scrollPosition }} key={key}>
                                <>
                                    {image.match(/\.([0-9a-z]+)(?:[?#]|$)/i)[1] == "mp4" ?
                                        <video src={image} className='shadow-homeTwigShdo dark:shadow-none' controls></video>
                                        :
                                        <img src={image} className='shadow-homeTwigShdo dark:shadow-none' loading='lazy'/>
                                    }
                                </>
                            </Link>
                        ))}
                    </>
                }

                {/* <img src={content.image} className='shadow-homeTwigShdo dark:shadow-none'/> */}
                {/* <video src={content.video} className='shadow-homeTwigShdo dark:shadow-none' controls></video> */}



                {content.image.length > 1 &&
                    <>
                        {index !== content.image.length - 1 &&
                            // <></>
                            // :
                            <button onClick={scrollRight}>
                                <img className="dark:invert" src={scroll_right} />
                            </button>
                        }
                    </>}
                    {/* : <></> */}
            </div>
            {content.image.length > 1 &&
                <div className='flex w-[92%] items-center justify-center gap-2'>
                    {content.image.map((content, key) => (
                        <p className={` ${key === index ? 'transition carousel_active' : 'carousel_not_active bg-[#838383] dark:bg-[#838383]'}`} key={key}></p>
                    ))}
                </div>
                // :
                // <></>
            }
            
            {/* <div className='flex w-full justify-center gap-2'>
                {content.image.map((content, key) => (
                    <p className='w-[5px] h-[5px] rounded-full bg-black dark:bg-white' key={key}></p>
                ))}
            </div> */}

            <div className="content-bio">
                <p className='text-black dark:text-white'>{content.caption}</p>
                <button onClick={() => {
                    savedTwig ? UnSaveTwig().then(toast('👋 Twig unsaved', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    })) : SaveTwig().then(toast('🙌 Twig saved!', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    }))
                }}><img src={savedTwig ? save_2 : save_btn} /></button>
            </div>

            <div className="content-footer">
                <button onClick={() => liked ? unlike_update(content.docId) : like_update(content.docId)}>{liked ? <img src={love_like} /> : <img className='dark:invert' src={empty_like} />}</button>
                <p className='text-black dark:text-white'>{likeCount}</p>
                {/* <button ref={btnRef} onClick={() => setIsOpen( prev = !prev )}><img src={content.comment_btn}/></button> */}

                {/* <button onClick={() => { setTwigId(content.docId); setTwigComment(content.comment); Comment_on() }}><img src={comment_btn} /></button> */}

                {/* {content.image.slice(index, index + 1).map((image, key) => ( */}
                {width <= 570 ? 
                    <Link to={`/someone_mobile_post/${content.docId}`} state={{ feed: content, twigId: content.docId, commentOn: true }}>
                        <button><img src={comment_btn} /></button>
                    </Link>
                :
                    <Link to={`/twig/post/${content.docId}`} state={{ twig: content }}>
                        <button><img src={comment_btn} /></button>
                    </Link>
                }
                {/* ))} */}
                <p className='text-black dark:text-white'>{content.comment.length}</p>

                <button onClick={() => { setIsShare(true); setTwigId(content.docId) }}><img src={share_btn} /></button>
                {/* <p className='text-black dark:text-white'>2</p> */}
            </div>
        </div>
    )
}


export default function Home_white_side({ logData, isComment, setIsComment, isShare, setIsShare, userData }) {

    const homeRef = useRef(null)
    const [scrollPosition, setScrollPosition] = useState(0);
    const [mobileNotifi, setMobileNotifi] = useState(false)
    const [twigComment, setTwigComment] = useState()
    const [twigId, setTwigId] = useState()
    const [allstories, setAllStories] = useState(stories)
    const [allcontents, setAllContents] = useState(contents)
    const { allTwigs, allUsers, notificationData } = useContext(AppContext)
    const [isVisible, setIsVisible] = useState(false);
    const [allFollowerTwigs, setAllFollowerTwigs] = useState([])
    const location = useLocation()

    // console.log(allTwigs);

    useEffect(()=>{
        const updatedFollowingTwigs = allTwigs.filter(twig => userData.following.includes(twig.uid) || logData.uid == twig.uid);
        setAllFollowerTwigs(updatedFollowingTwigs)
    },[logData])

    const containerRef = useRef(null);

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: 50, behavior: 'smooth' }); // Adjust the scroll amount as needed
        }
    };

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: -50, behavior: 'smooth' }) // Adjust the scroll amount as needed
        }
    }

    function noscroll() {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        if (isComment) {
            document.body.style.overflow = 'hidden';
            // Scroll the content to the original position
            homeRef.current.scrollTo(0, scrollPosition);
        } else {
            // Enable scrolling on the body when the modal is closed
            document.body.style.overflow = 'auto';
        }
    }, [isComment, scrollPosition]);

    const handleScroll = (e) => {
        if (homeRef.current) {
            if (homeRef.current.scrollTop > homeRef.current.clientHeight) {
              setIsVisible(true);
            } else {
              setIsVisible(false);
            }
          }
          setScrollPosition(e.target.scrollTop)
        };
    
      const scrollToTop = () => {
        if (homeRef.current) {
          homeRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
      };
      
    
      useEffect(() => {
        const container = homeRef.current;
        if (container) {
          container.addEventListener('scroll', handleScroll);
          return () => {
            container.removeEventListener('scroll', handleScroll);
          };
        }
      }, []);

      useEffect(()=> {
        if(location.state){
          const {scrollPosition} = location.state;  
          console.log(scrollPosition)
          setTimeout(() => {
            homeRef.current.scrollTop = scrollPosition
          }, (10));
        }
      },[])
    // console.log(location.state.scrollPosition)
    return (
        <>
            <div className='cmnt_main'>
                {
                    isComment ? <Comment onClickOutside={() => { setIsComment(false) }} setIsComment={setIsComment} twigComment={twigComment} twigId={twigId} /> : <></>
                }
                {
                    isShare ? <Home_share twigId={twigId} setIsShare={setIsShare} /> : <></>
                }
            </div>

            <div className="white-side" id='home_main' ref={homeRef} onScroll={handleScroll}>
                {/* <div className="home-nav-bar">
                    <div className="home-nav-icon">
                        <Link>
                            <button className="home-focus-icon"><img src={home_icon} /></button>
                        </Link>
                        <Link to="/Message_page">
                            <button className="home-normal-icon"><img src={message_icon} /></button>
                        </Link>
                        <Link to="/search_page">
                            <button className="home-normal-icon"><img src={search_icon} /></button>
                        </Link>
                        <Link to="/reel_page">
                            <button className="home-normal-icon"><img src={play_icon} /></button>
                        </Link>
                    
                        <Link to="/settings_page">
                            <button className="home-normal-icon"><img src={settings_icon} /></button>
                        </Link>
                        <Link to="/accounts_page">
                            <button className="home-normal-icon"><img src={profile_icon} /></button>
                        </Link>
                    </div>
                </div> */}
                {/* <Mobile_navbar /> */}
                <section className='home-top-logo'>
                    <div className='mt-3'>
                        <img src={twigyy} />
                        <button onClick={() => setMobileNotifi(!mobileNotifi)}><img src={notification_icon} /></button>
                        {mobileNotifi ?
                            <div className='mobile-notifi'>
                                <Home_notification setMobileNotifi={setMobileNotifi} mobileNotifi={mobileNotifi} notificationData={notificationData} />
                            </div>
                            : <></>}
                    </div>
                </section>
                <div className="home-story-sec">
                    <button onClick={scrollLeft}><img className="dark:invert" src={scroll_left} /></button>
                    <Link to="/tales_page">
                        <section>
                            <button><img src={userData.img} /></button>
                            <img src={story_plus} />
                        </section>
                    </Link>
                    <div ref={containerRef}>
                        {allstories.map((story, key) => (
                            <Link to="/story_view" key={key}>
                                <button key={key}><img src={story.src} alt={story.id} /></button>
                            </Link>
                        ))}
                    </div>
                    <button onClick={scrollRight}><img className="dark:invert" src={scroll_right} /></button>
                </div>
                <hr className="home-hr" />

                <p className="para dark:text-white">Todays Twig's</p>

                <div className="content-box">
                    {allFollowerTwigs.map((content, key) => (
                        <HomeTwigCards key={key} userData={userData} content={content} uid={content.uid} isComment={isComment} logData={logData} setIsComment={setIsComment} scrollPosition={scrollPosition} setScrollPosition={setScrollPosition} isShare={isShare} setIsShare={setIsShare} setTwigComment={setTwigComment} setTwigId={setTwigId} />
                    ))}
                </div>

                {isVisible ? 
                <section onClick={() => scrollToTop()} className='scroll_to_top'>
                    <img className='w-5' src={scroll_up}/>
                </section>
                :
                <></>
                }
            </div>

        </>
    )
}
