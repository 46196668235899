import React, { useState, useContext, useEffect, useCallback } from "react";
import "../Styles/Style_2.css";
import twigyy from "../Assets/Images/Login Page/twigyy-logo.png";
import google from "../Assets/Images/Login Page/google-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { users } from "./user";
import { AppContext } from "../Services/Context";

export default function Login_page_2() {
  const { userInfo } = useContext(AppContext);
  const [user, setUser] = useState({ email: "" });
  const [err, setErr] = useState("");

  const navigate = useNavigate();
  // console.log(userInfo);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const isValidEmail = emailRegex.test(user.email);
  console.log(err);

  function Continue() {
    // console.log(isValidEmail);
    if (isValidEmail === true) {
      userInfo.email = user.email;
    } else {
      setErr("Give valid email address")
    }
  }

  const enterToNextPg = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        if(isValidEmail) {
          navigate("/login3");
        }
        else {
          navigate("#");
        }
        Continue()
      }
    },
    [user]
  );

  useEffect(
    (e) => {
      window.addEventListener("keydown", enterToNextPg);
      return () => {
        window.removeEventListener("keydown", enterToNextPg);
      };
    },
    [enterToNextPg]
  );

  // onChange={(e) => {userInfo.user.emaile.target.value}}

  return (
    <>
      <div className="login-header">
        <div className="logo">
          <img className="pg2-logo-twigyy logo-twigyy" src={twigyy} />
        </div>
        <p className="dark:text-white">Sign up to continue</p>
        <div className="pg2-user-input">
          <input
            onChange={(e) => {
              setUser({ ...user, email: e.target.value });
            }}
            className="email-input dark:text-white"
            placeholder="enter email/phone"
          />
          {err!="" ? <p className="dark:text-white">{err}</p> : null}
        </div>

        <div className="login-with-ht">
          <div className="down-login-with">
            <hr className="border-1 border-black dark:border-white" />
            <p className="login-with dark:text-white">or login with</p>
            <hr className="border-1 border-black dark:border-white" />
          </div>
          <div className="logo">
            <button>
              <img src={google} alt="Google-logo" />
            </button>
          </div>
          <div className="continue-button continue-btn-rltive">
            <Link to={isValidEmail ? "/login3" : "#"}>
              <button onClick={()=>Continue()} className="continue-btn">
                Continue
              </button>
            </Link>
            {/* <p>Take a demo</p> */}
          </div>
        </div>
      </div>
    </>
  );
}
