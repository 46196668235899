import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import Stories from 'react-insta-stories';
import profile_img from '../Assets/Accouonts Page/profile pic.png'
import profile_img1 from '../Assets/Accouonts Page/friend 1.png'
import profile_img2 from '../Assets/Accouonts Page/friend 2.png'
import video from '../Assets/Reels assets/video.mp4'
import cross_icon from '../Assets/Home page 1/cross icon.svg'
import { AppContext } from '../Services/Context';

export default function Home_story_view() {

  const { allStories } = useContext(AppContext)
  const [focusedIndex, setFocusedIndex] = useState(0);
  const containerRef = useRef(null);
  // console.log(allStories);

  const stories_img2 = allStories.map(item => {
    return item.content?.map(contentItem => ({

      url: contentItem?.url,
      duration: 5000,
      header: {
        heading: contentItem?.header.heading,
        subheading: 'Posted 30m ago', // Adjust as needed
        profileImage: contentItem?.header.profileImage
      }
    }));
  });

  // console.log(stories_img2)



  const nextItem = () => {
    setFocusedIndex((prevIndex) => (prevIndex + 1) % stories_img2.length);

  };

  const prevItem = () => {
    setFocusedIndex((prevIndex) => (prevIndex - 1 + stories_img2.length) % stories_img2.length);

  };

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const itemWidth = containerRef.current.children[focusedIndex].offsetWidth;
      const scrollPosition = containerRef.current.children[focusedIndex].offsetLeft - (containerWidth - itemWidth) / 2;
      containerRef.current.scrollTo({ left: scrollPosition, behavior: 'smooth' });
    }
  }, [focusedIndex]);

  const stories_img = [

    [
      {
        url: profile_img,
        duration: 5000,
        // type: 'video',
        header: {
          heading: 'Mohit Karekar',
          subheading: 'Posted 30m ago',
          profileImage: profile_img,
        }
      },
      {
        url: profile_img1,
        duration: 5000,
        // type: 'video',
        header: {
          heading: 'Mohit Karekar',
          subheading: 'Posted 30m ago',
          profileImage: profile_img,
        }
      },

    ],
    [{
      url: profile_img2,
      duration: 5000,
      // type: 'video',
      header: {
        heading: 'Mohit Karekar',
        subheading: 'Posted 30m ago',
        profileImage: profile_img,
      }
    },
    {
      url: profile_img1,
      duration: 5000,
      // type: 'video',
      header: {
        heading: 'Mohit Karekar',
        subheading: 'Posted 30m ago',
        profileImage: profile_img,
      }
    },
    ],
    [{
      url: profile_img2,
      duration: 5000,
      // type: 'video',
      header: {
        heading: 'Mohit Karekar',
        subheading: 'Posted 30m ago',
        profileImage: profile_img,
      }
    }],
    [{
      url: profile_img2,
      duration: 5000,
      // type: 'video',
      header: {
        heading: 'Mohit Karekar',
        subheading: 'Posted 30m ago',
        profileImage: profile_img,
      }
    }],
    [{
      url: profile_img2,
      duration: 5000,
      // type: 'video',
      header: {
        heading: 'Mohit Karekar',
        subheading: 'Posted 30m ago',
        profileImage: profile_img,
      }
    }],
  ]

  // console.log(containerRef.current);
  return (
    <>

      <div className='story_view_main'>
        <Link to="/"><img src={cross_icon} /></Link>
        <button className='text-white' onClick={prevItem}>Previous</button>
        {stories_img2.length!==0 ?
          <section ref={containerRef}>
            <>
              {stories_img2?.map((story, index) => (
                <CarouselItem key={index} story={story} isFocused={index === focusedIndex} nextItem={nextItem} />
              ))}
            </>
          </section>
          : <></>
        }

        <button className='text-white' onClick={nextItem}>Next</button>
      </div>

    </>
  )
}

const CarouselItem = ({ story, isFocused, key, nextItem }) => {
  return (
    <div className={`h-full min-w-fit ${isFocused ? 'story_focus' : 'story_blur'}`} key={key}>
      <Stories
        isPaused={!isFocused}
        stories={story}
        defaultInterval={3000}
        keyboardNavigation={true}
        width={432}
        height={'100%'}
        onStoryEnd={nextItem}
      />
    </div>
  );
};





{/* <div className='story_content_view'>
  <div>
    <img src={profile_img}/>
    <p>Name</p>
  </div>
    <img src={profile_img} />
</div> */}