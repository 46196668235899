import React from 'react'

export default function Settings_Sensitive_Content() {
  return (
<>

<div className='settings-edit-profile settings-sensi-content'>
    <p className='dark:text-white'>Sensitivity Content</p>
    <p>This affects search results as well as recommended contents, takes and search pages</p>
    <section>
        <div className="flex items-center mb-4">
            <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
            <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                <p>Less</p>
                <p>You might see less sensitive contents.</p>
            </label>
        </div>
        <div className="flex items-center mb-4">
            <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
            <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                <p>Standard</p>
                <p>You might see some sensitive contents.</p>
            </label>
        </div>
        <div className="flex items-center mb-4">
            <input id="default-radio-1" type="radio" value="" name="Sensitivity Content" className="w-4 h-4 text-[#BDFA3C] bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-transparent focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
            <label htmlFor="Sensitivity Content" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                <p>More</p>
                <p>You might see more sensitive contents.</p>
            </label>
        </div>
    </section>
    <p>Sensitive contents does not go against our community guidelines, but refers to topics some people do not want to see <a>Learn more</a>.</p>

</div>

</>
  )
}
