import React, { useState } from 'react'
import link_icon from "../Assets/Brand assets/link.svg"
import { suggestions } from '../Someones Account/someone_acc_components'
import plus_btn_icon from '../Assets/Someone Acc assets/plus_icon.svg'

export default function Brand_broadcast({accSugest}) {
  const [allsuggestions, setAllSuggestions] = useState(suggestions)
  return (
<>

<div className='broadcast-main'>
    <section className='brand-broadcast'>
      <p className='dark:text-white'>Join Broadcast to stay update</p>
      <button className='bg-[#BDFA3C] py-1 w-[85%] rounded-full mb-3'>BroadCast Id</button>
      <a href='https://goodlooks.com/' className='dark:text-white'>
        <img src={link_icon} />
        GoodLooks.com
      </a>
    </section>
  {accSugest ? 
  <div className="someone-suggestion">

      {allsuggestions.map((suggestion, key) => (
        <div key={key}>
          <img src={suggestion.src} alt={suggestion.id}/>
          <button><img src={plus_btn_icon} /></button>
          <div>
            <p className='dark:text-white'>{suggestion.name}</p>
          </div>
      </div>
      ))}
  </div>
:
<></>
}
</div>

</>
    )
}
