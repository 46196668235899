import React from 'react'
import '../Styles/Owner_brand_page.css' 
import Owner_brand_profile from './Owner_brand_profile'
import Owner_brand_abouts from './Owner_brand_abouts'
import Owner_brand_posts from './Owner_brand_posts'
import Owner_brand_action from './Owner_brand_action'

export default function owner_brand_page() {
  return (
<>
<div className="accounts-right-sec">

    < Owner_brand_profile />
    <div style={{display:'flex'}}>
    < Owner_brand_abouts />
    < Owner_brand_action />
    </div>
    < Owner_brand_posts />

</div>
</>
  )
}
